import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';

import { ElementProps, withProps } from '../../entity/components';
import { getColor } from '../../theme/theme';
import { useLayout } from '../../contexts/LayoutContext';

export type ButtonProps = ElementProps & MuiButtonProps;

export const Button: React.FC<ButtonProps> = (props) => {
  const theme = useTheme();
  const { isMobile } = useLayout();

  let h = isMobile ? '30px' : '40px';
  switch (props.size) {
    case 'small':
      h = '30px';
      break;
    case 'medium':
      h = '40px';
      break;
    case 'large':
      h = '60px';
      break;
  }

  const sx = {
    backgroundColor: getColor(props.color ?? ('accent.main' as any)),
    '&:hover': {
      backgroundColor: getColor(props.color ?? ('accent.dark' as any)),
    },
    color: getColor('text.white' as any),
    textTransform: 'none',
    borderRadius: 2,
    height: h,
    fontSize: theme.typography.button.fontSize,
    paddingX: 2,
    paddingY: 1,
    ...props.sx,
  };
  return <MuiButton {...withProps({ ...props, sx, h })} />;
};
