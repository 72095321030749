import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import { getTradingViewPairIFrameSrc } from '../../services';
import { IconButton } from '../controls/IconButton';
import { FlexBox } from '../containers/FlexBox';

export type MarketChartProps = {
  market: string;
  onRemove: any;
};

export const MarketChart: React.FC<MarketChartProps> = ({
  market,
  onRemove,
}) => (
  <FlexBox column sx={{ position: 'relative', mb: 2 }}>
    <iframe
      src={getTradingViewPairIFrameSrc(market)}
      width="100%"
      height="500"
      allowFullScreen
      style={{ border: 'none' }}
    />
    <IconButton
      onClick={onRemove}
      disableTouchRipple
      disableRipple
      disableFocusRipple
    >
      <DeleteIcon />
    </IconButton>
  </FlexBox>
);
