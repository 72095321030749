import React from 'react';
import { Box } from '@mui/material';

import { Asset } from '../../entity';
import { OptionalImg } from './OptionalImg';
import { Text } from './Text';

export const renderAsset = (asset: Asset | null) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <OptionalImg imgSize="sm" src={asset?.iconUrl} />
    {(asset?.name || asset?.symbol) && (
      <Text sx={{ ml: 1 }}>{asset?.desc()}</Text>
    )}
  </Box>
);
