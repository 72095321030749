import React, { useMemo } from 'react';

import { FlexBox } from '../containers/FlexBox';
import { Text } from '../display/Text';
import { BalanceChart } from './BalanceChart';
import { useLayout } from '../../contexts/LayoutContext';
import {
  formatBalanceDataForRange,
  formatProfitDataForRange,
  TimeRangeType,
  UnitBalance,
} from '../../entity';
import { AccountsProfit, AssetProfit, EarnProfit } from '../../entity/profit';
import { NoData } from '../display/NoData';

export type HoldingChartsProps = {
  range: TimeRangeType;
  balance?: UnitBalance;
  profit?: AccountsProfit | EarnProfit | AssetProfit;
};

export const HoldingCharts: React.FC<HoldingChartsProps> = ({
  range,
  balance,
  profit,
}) => {
  const { isMobile } = useLayout();

  const chartMarginLeft = isMobile ? 0 : '-16px';

  const profitRange = useMemo(() => {
    return range === TimeRangeType.DAY ? TimeRangeType.WEEK : range;
  }, [range]);

  const balanceData = useMemo(() => {
    return balance ? formatBalanceDataForRange(balance, range).data : [];
  }, [balance]);

  const profitData = useMemo(() => {
    const profitData = profit?.profit()?.profits ?? [];
    return formatProfitDataForRange(profitData, profitRange);
  }, [profit]);

  const showBalanceChart = !!balanceData.length;
  const showProfitChart = !!profitData.length;
  const bothDataNotAvailable = !showBalanceChart && !showProfitChart;

  const noDataContent = bothDataNotAvailable
    ? 'Balance and Profit'
    : !showBalanceChart
      ? 'Balance'
      : 'Profit';
  const noDataToDisplayText = `No ${noDataContent} Data to Display`;

  return (
    <>
      {!showBalanceChart ? null : (
        <FlexBox column mt={isMobile ? 1 : 2} mb={isMobile ? 1 : 2}>
          <Text variant="body1" bold mt={isMobile ? 1 : 2}>
            Balance Change
          </Text>
          <BalanceChart
            height={300}
            ml={chartMarginLeft}
            range={range}
            data={balanceData}
          />
        </FlexBox>
      )}

      {!showProfitChart ? null : (
        <FlexBox column mb={isMobile ? 1 : 2} mt={isMobile ? 1 : 2}>
          <Text variant="body1" bold>
            Profit Change
          </Text>
          <BalanceChart
            data={profitData}
            ml={chartMarginLeft}
            height={296}
            range={profitRange}
            yDataKey="profit"
          />
        </FlexBox>
      )}

      {(!showBalanceChart || !showProfitChart) && (
        <FlexBox center>
          <NoData fullWidth text={noDataToDisplayText} />
        </FlexBox>
      )}
    </>
  );
};
