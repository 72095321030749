import React from 'react';
import 'ag-charts-enterprise';

import { ElementProps } from '../../entity/components';
import { FlexBox } from '../containers/FlexBox';
import { Text } from '../display/Text';
import Tiles from '../containers/Tiles';
import { amountWithSuffix } from '../../utils/amount';

export type DonutChartLegendProps = ElementProps & {
  data: any[];
  columnLegend?: boolean;
  fills: string[];
  prefix?: string;
};

export const DonutChartLegend: React.FC<DonutChartLegendProps> = ({
  data,
  fills,
  columnLegend,
  prefix = '',
  ...props
}) => {
  return (
    <Tiles
      spacing={1}
      column
      {...{ ...props, mw: 136, w: '100%', mxw: 'none' }}
    >
      <FlexBox justifyContent={columnLegend ? 'space-between' : 'start'}>
        <FlexBox column mr={columnLegend ? 0 : 2}>
          {data.map((d, idx) => (
            <FlexBox key={d.name} alignItems="center">
              <FlexBox
                sx={{ backgroundColor: fills[idx] }}
                round
                mw={8}
                w={8}
                h={8}
                mr="4px"
              />
              <Text variant="cell">{d.name}:</Text>
            </FlexBox>
          ))}
        </FlexBox>
        <FlexBox column>
          {data.map((d, idx) => (
            <Text variant="cell">{amountWithSuffix(d.value, prefix)}</Text>
          ))}
        </FlexBox>
      </FlexBox>
    </Tiles>
  );
};
