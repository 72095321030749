import React from 'react';
import AddIcon from '@mui/icons-material/Add';

import { Button, ButtonProps } from './Button';

type ActionButtonProps = ButtonProps & {
  text?: string;
  isMobile?: boolean;
  entityName?: string;
  icon?: React.ReactNode;
  sx?: any;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const ActionButton: React.FC<ActionButtonProps> = ({
  entityName,
  isMobile,
  icon,
  text,
  onClick,
  ...props
}) => (
  <Button
    size="small"
    bgc="accent.dark"
    variant="contained"
    startIcon={icon || <AddIcon />}
    onClick={onClick}
    {...props}
  >
    {text ? text : `${isMobile ? '' : 'Add'} ${entityName ? entityName : ''}`}
  </Button>
);
