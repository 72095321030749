import React from 'react';

import { ElementProps, withProps } from '../../entity/components';
import { FlexBox } from '../containers/FlexBox';
import { InfoIcon } from '../icons';
import { Text } from './Text';
import { ColorString } from '../../theme/theme';

export type NoDataProps = ElementProps & {
  text: string;
  iconColor?: ColorString;
};

export const NoData = ({ text, iconColor, ...props }: NoDataProps) => (
  <FlexBox alignItems="center">
    <InfoIcon
      color={iconColor ?? 'warning.main'}
      imgSize="sm"
      mr={1}
      mb="-12px"
      {...withProps(props)}
    />
    <Text variant="body2" mt={4} mb={2}>
      {text}
    </Text>
  </FlexBox>
);
