import React, { useEffect, useState } from 'react';

import { Container } from '../../components/nav/Container';
import { Wallet } from '../../entity';
import { getDeFiTrackerWallet } from '../../services';
import { WalletCard } from '../../components/cards/WalletCard';
import { DeFiTrackerIcon, TableIcon } from '../../components/icons';
import { usePage } from '../../contexts/PageContext';
import { DeFiTrackerTxTable } from '../../components/tables/DeFiTrackerTxTable';
import Tiles from '../../components/containers/Tiles';

export const DeFiTrackerPage: React.FC<void> = () => {
  const { navigate, showToast, showLoader, hideLoader } = usePage();

  const [wallet, setWallet] = useState<Wallet | null>(null);

  useEffect(() => {
    showLoader();

    getDeFiTrackerWallet()
      .then((res) => setWallet(res))
      .catch((err) =>
        showToast(`failed to load DT wallet: ${err.message}`, 'error')
      )
      .finally(hideLoader);
  }, []);

  return (
    <Container navigate={navigate}>
      <Tiles>
        {wallet && (
          <WalletCard
            defiTracker
            walletId={wallet.id}
            title="Wallet"
            icon={<DeFiTrackerIcon imgSize="md" />}
          />
        )}
        <DeFiTrackerTxTable
          p={2}
          Icon={TableIcon}
          withPagination={true}
          title="Transactions"
        />
      </Tiles>
    </Container>
  );
};
