import React, { useMemo } from 'react';

import { HideIcon, LinkIcon, PlusIcon } from '../icons';
import { OverlayButtonsCard } from './OverlayButtonsCard';

interface HidableCardProps {
  id: string;
  onViewClick?: (id: string) => void;
  onAddClick?: (id: string) => void;
  onHideClick: (id: string) => void;
  url?: string;
  width?: number;
  children?: React.ReactNode;
}

export const HidableCard: React.FC<HidableCardProps> = ({
  onViewClick,
  onHideClick,
  onAddClick,
  id,
  url,
  width,
  children,
}) => {
  const handleViewClick = (event: any) => {
    if (url) {
      window.open(url, '_blank');
    } else if (onViewClick) {
      onViewClick(id);
    }
  };

  const handleHideClick = (event: any) => onHideClick(id);
  const handleAddClick = (event: any) => onAddClick?.(id);

  const items = useMemo(() => {
    const res = [
      { id: `${id}-view`, onClick: handleViewClick, url, Icon: LinkIcon },
      { id: `${id}-hide`, onClick: handleHideClick, Icon: HideIcon },
    ];
    if (onAddClick) {
      res.unshift({
        id: `${id}-add`,
        onClick: handleAddClick,
        url,
        Icon: PlusIcon,
      });
    }
    return res;
  }, [url, handleHideClick, handleViewClick, onAddClick]);

  return (
    <OverlayButtonsCard width={width ?? 250} items={items}>
      {children}
    </OverlayButtonsCard>
  );
};
