export const normalizeValue = (
  amount: number,
  maxDecimals: number = 6,
  minDecimals: number = 2,
  cmp: number = 0.01
) => {
  if (amount === 0) return 0;
  return amount.toFixed(amount > cmp ? minDecimals : maxDecimals);
};

export const normalizeValueNum = (
  amount: number,
  maxDecimals: number = 6,
  minDecimals: number = 2,
  cmp: number = 0.01
) => {
  return +normalizeValue(amount, maxDecimals, minDecimals, cmp);
};

export const valueColor = (v: any) => {
  if (typeof v !== 'string' && typeof v !== 'number') {
    return 'inherit';
  }

  if (typeof v !== 'string') {
    return v === 0 ? 'inherit' : v < 0 ? 'red' : 'green';
  }

  if (!(~v.indexOf('$') || ~v.indexOf('%'))) {
    return 'inherit';
  }

  if (~v.indexOf('0') && !~v.indexOf('.')) {
    return 'inherit';
  }

  return v[0] === '-' ? 'red' : 'green';
};

export const plusSign = (v: any) => {
  if (typeof v !== 'string') {
    return v > 0 ? '+' : null;
  }

  if (~v.indexOf('0') && !~v.indexOf('.')) {
    return '';
  }

  return ~v.indexOf('$') || ~v.indexOf('%') ? (v[0] === '-' ? '' : '+') : '';
};

export const numberWithCommas = (x: any) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const amountWithSuffix = (
  value: number,
  prefix: string = ''
): string => {
  if (value < 1000) return `${prefix}${value.toFixed(2)}`;

  value = value ?? 0;
  return value < 1_000_000
    ? `${prefix}${(value / 1_000).toFixed(2)}K`
    : value < 1_000_000_000
      ? `${prefix}${(value / 1_000_000).toFixed(2)}M`
      : value < 1_000_000_000_000
        ? `${prefix}${(value / 1_000_000_000).toFixed(2)}B`
        : `${prefix}${(value / 1_000_000_000_000).toFixed(2)}T`;
};
