import { Entity } from './entity';

export class Media extends Entity<Media> {
  id = '';
  url = '';
  name = '';
  category = '';
  language = '';

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => ((this as any)[key] = obj[key]));
  }

  clone(patch?: Partial<Media>): Media {
    return new Media({ ...this, ...patch });
  }

  req(): Media {
    return this;
  }
}
