import { Entity } from './entity';

export class TokenUnlock extends Entity<TokenUnlock> {
  imageSrc: string = ''; // Image source URL
  name: string = ''; // Token name
  symbol: string = ''; // Token symbol
  price: number = 0; // Price as a float (e.g., 0.000521)
  marketCap: number = 0; // Market cap as a number (e.g., 301390)
  circulationSupply: number = 0; // Circulation supply (e.g., 578000000)
  percentLocked: number = 0; // Percent locked as a float (e.g., 41.7)
  percentUnlocked: number = 0; // Percent unlocked as a float (e.g., 28.3)
  unlockPercent: number = 0; // Unlock percent as a float (e.g., 2.86)
  unlockDate: number = 0; // Unlock date as a Unix timestamp

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      (this as any)[key] = obj[key];
    });
  }

  clone(patch?: Partial<TokenUnlock>): TokenUnlock {
    return new TokenUnlock({ ...this, ...patch });
  }

  req(): TokenUnlock {
    return this.clone({});
  }
}

export class TokenUnlockList extends Entity<TokenUnlockList> {
  data: TokenUnlock[] = [];
  total = 0;

  constructor(obj: any) {
    super();
    this.data = obj.data?.map((f: any) => TokenUnlock.from(f)) ?? [];
    this.total = obj.total;
  }

  clone(patch?: Partial<TokenUnlockList>): TokenUnlockList {
    return new TokenUnlockList({ ...this, ...patch });
  }

  req(): TokenUnlockList {
    const data = this.data.map((f) => TokenUnlock.from(f)) ?? [];
    return { ...this, data };
  }
}
