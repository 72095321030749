import React, { useEffect, useState } from 'react';

import { NewToken } from '../../entity';
import { Tape } from './Tape';
import { listNewTokens, hideNewToken } from '../../services';
import { useLoader } from '../../contexts/LoaderContext';
import { useToast } from '../../contexts/ToastContext';
import { NewTokenCard } from '../cards/NewTokenCard';

export type NewTokensProps = {
  h?: number;
};

export const NewTokens = (props: NewTokensProps) => {
  const { showLoader, hideLoader } = useLoader();
  const { showToast } = useToast();
  const [newTokens, setNewTokens] = useState<NewToken[]>([]);

  useEffect(() => {
    showLoader();

    listNewTokens()
      .then((res) => setNewTokens(res))
      .catch((err) =>
        showToast(`Failed to load new tokens: ${err.message}`, 'error')
      )
      .finally(hideLoader);
  }, []);

  const handleHideNewToken = (id: string) => {
    hideNewToken(id)
      .then(() => setNewTokens(newTokens.filter((a) => id !== a!.id)))
      .catch((err: any) =>
        showToast(`Failed to hide new token: ${err.message}`, 'error')
      );
  };

  return (
    <Tape h={props.h ?? 120}>
      {newTokens.slice(0, 20).map((token, idx) => (
        <NewTokenCard
          key={token.id}
          token={token}
          onHideClick={handleHideNewToken}
        />
      ))}
    </Tape>
  );
};
