import { Entity } from './entity';

export enum CEXName {
  BINANCE = 'binance',
  OKX = 'okx',
}

export class Trade extends Entity<Trade> {
  id = '';
  cex = CEXName.BINANCE;
  type = CEXName.BINANCE;
  price = '';
  symbol = '';
  size = '';
  side = '';
  status = '';
  timestamp = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'timestamp':
          this.timestamp = val < 1000000000000 ? val * 1000 : val;
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Trade>): Trade {
    return new Trade({ ...this, ...patch });
  }

  req(): Trade {
    this.timestamp = Math.round(this.timestamp / 1000);
    return this;
  }
}

export class TradeList extends Entity<TradeList> {
  trades: Trade[] = [];
  total = 0;

  constructor(obj: any) {
    super();
    this.trades = obj.data?.map((t: any) => Trade.from(t)) ?? [];
    this.total = obj.total;
  }

  clone(patch?: Partial<TradeList>): TradeList {
    return new TradeList({ ...this, ...patch });
  }

  req(): TradeList {
    return this;
  }
}

export class Order extends Entity<Order> {
  id = '';
  cex = CEXName.BINANCE;
  type = CEXName.BINANCE;
  symbol = '';
  price = '';
  size = '';
  side = '';
  state = '';
  status = '';
  timestamp = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'timestamp':
          this.timestamp = val < 1000000000000 ? val * 1000 : val;
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Order>): Order {
    return new Order({ ...this, ...patch });
  }

  req(): Order {
    this.timestamp = Math.round(this.timestamp / 1000);
    return this;
  }
}

export class OrderList extends Entity<OrderList> {
  orders: Order[] = [];
  total = 0;

  constructor(obj: any) {
    super();
    this.orders = obj.data?.map((o: any) => Order.from(o)) ?? [];
    this.total = obj.total;
  }

  clone(patch?: Partial<OrderList>): OrderList {
    return new OrderList({ ...this, ...patch });
  }

  req(): OrderList {
    return this;
  }
}
