import React, { useEffect } from 'react';
import { Skeleton as MUISkeleton } from '@mui/material';

import { ElementProps } from '../../entity/components';

export type SkeletonProps = ElementProps & {
  wait: any;
  children: any;
  fullHeight?: boolean;
  delay?: number;
};

export const Skeleton: React.FC<SkeletonProps> = ({
  wait,
  children,
  delay,
  fullHeight,
  ...props
}) => {
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    if (!wait) return;

    if (delay) {
      setTimeout(() => setShow(true), delay);
    } else {
      setShow(true);
    }
  }, [wait]);

  const height = props.h ?? 'auto';
  const skeletonHeight = Math.round(+(props.h ?? 100) * 0.8);

  const sx = fullHeight
    ? {
        '&.MuiSkeleton-text': {
          marginTop: 2,
          marginBottom: 2,
          minHeight: skeletonHeight,
          height: skeletonHeight,
          display: 'block',
          transform: 'none',
          webkitTransform: 'none',
        },
      }
    : ({} as any);

  return show ? (
    children
  ) : (
    <MUISkeleton
      height={height}
      width={props.w ?? '100%'}
      animation="wave"
      sx={sx}
    />
  );
};
