import React from 'react';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select as MUISelect,
  SelectProps as MUISelectProps,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ElementProps, withProps } from '../../entity/components';
import { useLayout } from '../../contexts/LayoutContext';

export type SelectOption = {
  value: string | number;
  label: string;
};

export type SelectGroup = {
  label?: string; // Optional label for the subheader
  options: SelectOption[];
};

export type SelectStyles = {
  form?: any;
  select?: any;
  label?: any;
  item?: any;
  listHeader?: any;
};

export type SelectProps = Omit<MUISelectProps, 'renderValue' | 'style'> &
  ElementProps & {
    label?: string;
    styles?: SelectStyles;
    variant?: 'filled' | 'outlined' | 'standard';
    displayEmpty?: boolean;
    allOption?: string;
    value: any;
    options: Array<SelectOption | SelectGroup>;
    renderValue?: (
      value: string | number | (string | number)[]
    ) => React.ReactNode;
    renderItem?: (option: SelectOption, checked: boolean) => React.ReactNode;
    disabledOptions?: (string | number)[];
  };

export const Select: React.FC<SelectProps & ElementProps> = ({
  label,
  styles = {},
  variant = 'standard',
  displayEmpty,
  value,
  allOption,
  onChange,
  options,
  renderValue,
  renderItem,
  disabledOptions = [],
  ...selectProps
}) => {
  const { isMobile } = useLayout();
  const theme = useTheme();
  const {
    form: formStyles = {},
    select: outerSelectStyles = {},
    item: itemStyles = {},
    label: labelStyles = {},
    listHeader: listHeaderStyles = {},
  } = styles;

  if (!itemStyles.fontSize)
    itemStyles.fontSize = theme.typography.subtitle2.fontSize;

  const selectStyles = {
    '& .MuiSelect-select': {
      fontSize: theme.typography.caption.fontSize,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'grey.500',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'grey.500',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'grey.500',
    },
    ...outerSelectStyles,
  };

  // Default renderValue if not provided
  if (!renderValue) {
    renderValue = (selected) => {
      if (Array.isArray(selected)) {
        const selectedLabels = selected.map(
          (val) =>
            options
              .flatMap((o) => ('options' in o ? o.options : [o]))
              .find((o) => `${o.value}` === `${val}`)?.label
        );
        return selectedLabels.join(', ');
      }
      const selectedOption = options
        .flatMap((o) => ('options' in o ? o.options : [o]))
        .find((o) => `${o.value}` === `${selected}`)?.label;
      return selectedOption ?? allOption ?? 'Select...';
    };
  }

  // Check if an item is selected for rendering
  const isItemSelected = (itemValue: string | number) => {
    return selectProps.multiple && Array.isArray(value)
      ? value.includes(itemValue)
      : value === itemValue;
  };

  const inputLabelSx = {
    marginLeft: '16px',
    marginTop: '-10px',
  };

  return (
    <FormControl
      fullWidth={selectProps.fullWidth}
      variant={variant}
      {...withProps({ ...selectProps, sx: formStyles })}
    >
      {label && (
        <InputLabel component={Typography} id={label} sx={inputLabelSx}>
          {label}
        </InputLabel>
      )}
      <MUISelect
        displayEmpty={displayEmpty}
        value={value as any}
        label={label ?? null}
        labelId={label ?? null}
        onChange={onChange}
        renderValue={renderValue}
        variant="outlined"
        size={selectProps.size ?? 'small'}
        {...withProps({ ...selectProps, sx: selectStyles })}
      >
        {allOption && (
          <MenuItem key="all" value="all" style={itemStyles}>
            All
          </MenuItem>
        )}
        {options.flatMap((groupOrOption) =>
          'options' in groupOrOption ? (
            [
              groupOrOption.label && (
                <ListSubheader key={groupOrOption.label} sx={listHeaderStyles}>
                  {groupOrOption.label}
                </ListSubheader>
              ),
              ...groupOrOption.options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={disabledOptions.includes(option.value)}
                  style={itemStyles}
                >
                  {renderItem
                    ? renderItem(option, isItemSelected(option.value))
                    : option.label}
                </MenuItem>
              )),
            ]
          ) : (
            <MenuItem
              key={groupOrOption.value}
              value={groupOrOption.value}
              disabled={disabledOptions.includes(groupOrOption.value)}
              style={itemStyles}
            >
              {renderItem
                ? renderItem(groupOrOption, isItemSelected(groupOrOption.value))
                : groupOrOption.label}
            </MenuItem>
          )
        )}
      </MUISelect>
    </FormControl>
  );
};
