import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { Text } from '../display/Text';
import { Button } from '../controls/Button';
import { Input } from '../controls/Input';

export type ChangeEarnBalanceDialogProps = {
  open: boolean;
  onChange: (change: number) => void;
  onClose: () => void;
};

export const ChangeEarnBalanceDialog: React.FC<
  ChangeEarnBalanceDialogProps
> = ({ open, onChange, onClose }) => {
  const [change, setChange] = useState<string>('');
  const [err, setErr] = useState<string>('');

  const handleOnChangeClick = () => {
    const res = Number(change);
    if (Number.isNaN(res)) {
      setErr('Wrong numeric value');
      return;
    }
    onChange(res);
  };

  return (
    <Dialog open={open} onClose={onClose as any}>
      <DialogTitle id="change-dialog-title">Change Balance</DialogTitle>
      <DialogContent>
        <Text id="change-dialog-description">
          Enter balance change (can be negative for withdrawal)
        </Text>
        <Input
          mt={2}
          onChange={(e) => {
            setErr('');
            setChange(e.target.value);
          }}
          value={change}
        />
        {!!err && (
          <Text color="error" variant="caption">
            {err}
          </Text>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose as any}>Cancel</Button>
        <Button onClick={handleOnChangeClick as any}>Change</Button>
      </DialogActions>
    </Dialog>
  );
};
