import React from 'react';

import { Container } from '../../components/nav/Container';
import { useData } from '../../contexts/DataContext';
import { XCheckButton } from '../../components/controls/XCheckButton';
import { usePage } from '../../contexts/PageContext';
import Tiles from '../../components/containers/Tiles';
import { MarketMapCard } from '../../components/cards/MarketMapCard';
import { Card } from '../../components/cards/Card';
import { NewTokens } from '../../components/containers/NewTokens';
import { EthereumIcon } from '../../components/icons/chains';
import { BalanceProfit } from '../../components/containers/BalanceProfit';
import PortfolioTable from '../../components/tables/PortfolioTable';
import {
  BarChartIcon,
  PieChartIcon,
  PinIcon,
  StarHalfIcon,
  UnlockIcon,
} from '../../components/icons';
import { PriceTable } from '../../components/tables/PriceTable';
import { PortfolioDistribution } from '../../components/containers/PortfolioDistribution';
import TokenUnlockTable from '../../components/tables/UnlocksTable';
import { NewActivities } from '../../components/containers/NewActivities';
import TxTable from '../../components/tables/TxTable';
import RWATable from '../../components/tables/RWATable';
import MemeTable from '../../components/tables/MemeTable';
import NFTTable from '../../components/tables/NFTTable';
import { MarketState } from '../../components/containers/MarketState';

export const DashboardPage: React.FC<void> = () => {
  const { navigate, isMobile } = usePage();
  const { marketState, wallets, earns, prices, pricePins } = useData();

  return (
    <Container
      navigate={navigate}
      actionComponent={<XCheckButton isMobile={isMobile} />}
    >
      <Tiles>
        <MarketMapCard height={611} />

        <MarketState wide data={marketState} mb={-1} />

        <Card fullWidth column Icon={EthereumIcon} title="New Tokens">
          <NewTokens h={120} />
        </Card>

        <Tiles>
          <BalanceProfit tileLG={7} />
          <PortfolioTable
            tileLG={5}
            Icon={PieChartIcon}
            wallets={wallets}
            earns={earns}
            p={2}
          />
        </Tiles>

        <Tiles>
          <PriceTable
            tileLG={7}
            Icon={PinIcon}
            title="Pinned Tokens"
            p={2}
            prices={prices}
            pricePins={pricePins}
            pinnedOnly
          />
          <PortfolioDistribution tileLG={5} />
        </Tiles>

        <Card fullWidth column Icon={StarHalfIcon} title="New Activities">
          <NewActivities h={160} />
        </Card>

        <TokenUnlockTable
          Icon={UnlockIcon}
          perPage={10}
          p={2}
          withPagination={false}
          limit={10}
        />

        <Card title="Gainers" Icon={BarChartIcon} flex column p={2}>
          <NFTTable
            p={0}
            mb={4}
            perPage={10}
            withPagination={false}
            titleInFilters
            limit={10}
            card={false}
          />
          <MemeTable
            p={0}
            mb={4}
            perPage={10}
            withPagination={false}
            titleInFilters
            limit={10}
            card={false}
          />
          <RWATable
            p={0}
            mb={4}
            perPage={10}
            withPagination={false}
            titleInFilters
            limit={10}
            card={false}
          />
        </Card>

        <TxTable
          title="Recent Transactions"
          withPagination={false}
          limit={20}
          hideSearch
          hideFilters
          hideExternalFilters
          p={2}
        />
      </Tiles>
    </Container>
  );
};
