import React from 'react';

import { Node } from '../../entity';
import { Text } from './Text';
import { FlexBox } from '../containers/FlexBox';
import { nodeIcon } from '../icons/chains';
import { capitalize } from '../../utils/text';

export const renderChain = (n: Node) => (
  <FlexBox center>
    {nodeIcon(n, 'sm')}
    <Text ml={1}>{capitalize(n)}</Text>
  </FlexBox>
);
