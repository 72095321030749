import React from 'react';

import { FlexBox } from '../containers/FlexBox';
import { Card } from './Card';
import { ElementProps, withProps } from '../../entity/components';

export type OverlayButtonProps = {
  id: string;
  Icon: any;
  onClick?: (id: string) => void;
  url?: string;
};

export type OverlayButtonsCardProps = ElementProps & {
  items: OverlayButtonProps[];
  width?: number;
  children?: React.ReactNode;
};

export const OverlayButtonsCard: React.FC<OverlayButtonsCardProps> = ({
  items,
  width,
  children,
  ...props
}) => {
  const sx = {
    width: width ?? 250,
    position: 'relative', // Establishes a positioning context for the overlay
    '&:hover .overlay': {
      opacity: 1, // Makes the overlay fully visible on hover
      pointerEvents: 'auto', // Enables interaction with the overlay when visible
    },
    ...(props.sx ?? {}),
  };

  const overlaySx = {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'opacity 0.3s ease',
    pointerEvents: 'none',
    zIndex: 1,
  };

  const itemSx = {
    opacity: 0.8,
    '&:hover': { opacity: 1 },
    backgroundColor: 'rgba(255, 255, 255)',
    borderRight: '1px solid #eee',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'opacity 0.3s ease',
  };

  const itemWidth = Math.round(100 / (items.length ?? 1));

  return (
    <Card {...withProps({ ...props, sx })}>
      <FlexBox p={1}>{children}</FlexBox>

      <FlexBox className="overlay" w="100%" h="100%" sx={overlaySx}>
        {items.map((item) => (
          <FlexBox
            w={`${itemWidth}%`}
            h="100%"
            key={item.id}
            sx={itemSx}
            onClick={() => item?.onClick?.(item.id)}
          >
            <item.Icon imgSize="md" color="text.primary" />
          </FlexBox>
        ))}
      </FlexBox>
    </Card>
  );
};
