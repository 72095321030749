import React from 'react';

import { ElementProps, withProps } from '../../entity/components';
import { Card } from './Card';
import { Text } from '../display/Text';
import { BarChart, BarChartProps } from '../charts/BarChart';

export type BarChartCardProps = BarChartProps &
  ElementProps & {
    title?: string;
  };

export const BarChartCard: React.FC<BarChartCardProps> = ({
  title,
  ...props
}) => {
  const sx = {
    overflow: 'hidden',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...props.sx,
  };
  return (
    <Card column flex {...withProps({ ...props, sx })} fullWidth>
      {title && (
        <Text variant="subtitle2" bold mr={1}>
          {title}
        </Text>
      )}
      <BarChart {...props} h={(props.h ?? 300) - 60} />
    </Card>
  );
};
