import { Alert } from '../entity';
import { del, get, list, post, put } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/alerts`;

export const storeAlert = (data: Alert) => post<Alert>(apiUrl, data);
export const listAlerts = () => list<Alert>(apiUrl, Alert);
export const findAlert = (id: string) => get<Alert>(`${apiUrl}/${id}`, Alert);
export const updateAlert = (id: string, data: Alert) =>
  put<Alert>(`${apiUrl}/${id}`, data);
export const deleteAlert = (id: string) => del(`${apiUrl}/${id}`);
