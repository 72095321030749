import React from 'react';

import { AssetWithAccountId } from '../../entity';
import { Modal } from './Modal';
import { FlexBox } from '../containers/FlexBox';
import { OptionalImg } from '../display/OptionalImg';
import { Text } from '../display/Text';
import { Button } from '../controls/Button';

type ImportAssetsItemProps = {
  asset: AssetWithAccountId;
  onImport: (assetId: string) => void;
  onIgnore: (accId: string, assetId: string) => void;
};

const ImportAssetsItem: React.FC<ImportAssetsItemProps> = (props) => {
  const { asset, onImport, onIgnore } = props;

  const handleImportClick = () => onImport(asset.id);
  const handleIgnoreClick = () => onIgnore(asset.accountId, asset.id);

  return (
    <FlexBox alignItems="center" justifyContent="space-between" mb={2}>
      <FlexBox>
        <OptionalImg src={asset.iconUrl} imgSize="sm" mr={1} />
        <Text variant="subtitle2" bold>
          {asset.symbol}
        </Text>
      </FlexBox>
      <FlexBox>
        <Button size="small" onClick={handleImportClick} mr={1}>
          Import
        </Button>
        <Button size="small" onClick={handleIgnoreClick} color="warning">
          Ignore
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

type ImportAssetsModalProps = {
  open: boolean;
  data: AssetWithAccountId[];
  onImport: (assetId: string) => void;
  onIgnore: (accId: string, assetId: string) => void;
  onClose: () => void;
};

export const ImportAssetsModal: React.FC<ImportAssetsModalProps> = (props) => {
  const { open, data, onImport, onIgnore, onClose } = props;

  return (
    <Modal
      maxWidth="xs"
      open={open}
      title="Import Assets"
      onClose={onClose}
      showCloseIcon
    >
      <FlexBox column mxh={400}>
        {data.map((asset) => (
          <ImportAssetsItem
            key={asset.id}
            asset={asset}
            onImport={onImport}
            onIgnore={onIgnore}
          />
        ))}
      </FlexBox>
    </Modal>
  );
};
