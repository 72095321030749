import React, { useEffect, useMemo, useState } from 'react';

import { ElementProps, withProps } from '../../entity/components';
import { useLayout } from '../../contexts/LayoutContext';
import { Card } from '../cards/Card';
import { LineChartIcon } from '../icons';
import { FlexBox } from './FlexBox';
import { Select } from '../controls/Select';
import { InfoList, InfoListEntry } from './InfoList';
import { useData } from '../../contexts/DataContext';
import { PortfolioSelect } from '../controls/PortfolioSelect';
import {
  assetsCountFromQuery,
  BalanceQuery,
  getAssetFromQuery,
  HoldingType,
  TimeRange,
  timeRanges,
  UnitBalance,
} from '../../entity';
import { profitsFromQuery, TotalProfit } from '../../entity/profit';
import { queryBalances } from '../../services/balances';
import { getProfit } from '../../services';
import { normalizeValue } from '../../utils/amount';
import { HoldingCharts } from '../charts/HoldingCharts';
import { renderAsset } from '../display/Asset';

export const BalanceProfit: React.FC<ElementProps> = (props) => {
  const { isMobile, isMacbook16, isWidescreen } = useLayout();
  const { wallets, earns, prices } = useData();

  const [totalProfit, setTotalProfit] = useState<TotalProfit>(
    TotalProfit.from({})
  );
  const [balance, setBalance] = useState<UnitBalance>(UnitBalance.from({}));
  const [timeRange, setTimeRange] = useState<TimeRange>(timeRanges[1]);
  const [query, setQuery] = useState<BalanceQuery>({
    holding: HoldingType.ALL,
  });

  useEffect(() => {
    queryBalances({ ...query, timeRange: timeRange.label })
      .then((res) => setBalance(res))
      .catch((err) =>
        console.error(`failed to fetch balances: ${err.message}`)
      );
  }, [timeRange, query]);

  useEffect(() => {
    getProfit(timeRange.label)
      .then((res) => setTotalProfit(res))
      .catch((err) => console.error(`failed to fetch profit: ${err.message}`));
  }, [timeRange]);

  const { final: profit, data: profitData } = useMemo(() => {
    return profitsFromQuery(totalProfit, query);
  }, [totalProfit, query]);

  const assetsCount = useMemo(() => {
    return assetsCountFromQuery(wallets, earns, query);
  }, [query, wallets, earns]);

  const balanceProfitList: InfoListEntry[] = useMemo(() => {
    const list: InfoListEntry[] = [
      {
        key: 'Balance',
        value: `${normalizeValue(balance.final.value)} $`,
        color: false,
      },
      {
        key: 'Profit',
        value: `${normalizeValue(profit?.profit || 0)} $`,
      },
      {
        key: 'PNL',
        value: `${normalizeValue(profit?.dailyProfit || 0)} $`,
      },
    ];

    if (assetsCount === 1) {
      const asset = getAssetFromQuery(query, wallets, earns);
      if (asset) {
        list.push({
          key: 'Assets Count',
          value: renderAsset(asset),
        });
      }

      const assetPrice = asset?.findPrice(prices);
      if (assetPrice) {
        list.push({
          key: 'Assets Price',
          value: `${normalizeValue(assetPrice.current_price)} $`,
        });
      }
    } else {
      list.push({
        key: 'Assets Count',
        value: `${assetsCount}`,
      });

      list.push({
        key: 'Avg Asset Price',
        value: `${normalizeValue(balance.final.price)} $`,
      });
    }

    return list;
  }, [query, wallets, earns, prices]);

  const handleRangeChange = (event: any) => {
    setTimeRange(
      timeRanges.find((tr) => tr.value === (event.target.value as number))!
    );
  };

  props = {
    w: '100%',
    h: isMacbook16 || isWidescreen ? 1134 : 'auto',
    p: 2,
    ...props,
  };

  return (
    <Card
      title="Balance and Profit"
      Icon={LineChartIcon}
      column
      flex
      {...withProps(props)}
    >
      <FlexBox column={isMobile}>
        <Select
          label="Time Range"
          value={timeRange.value}
          w={isMobile ? '100%' : 120}
          mr={1}
          mb={isMobile ? 1 : 0}
          size="small"
          onChange={handleRangeChange}
          options={timeRanges}
          sx={{ overflow: 'visible' }}
        />
        <PortfolioSelect wallets={wallets} earns={earns} onChange={setQuery} />
      </FlexBox>

      <InfoList
        data={balanceProfitList}
        mt={isMobile ? 1 : 2}
        mb={isMobile ? 1 : 2}
      />

      <HoldingCharts
        range={timeRange.label}
        balance={balance}
        profit={profitData}
      />
    </Card>
  );
};
