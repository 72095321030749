export const ZeroAddress = '0x0000000000000000000000000000000000000000';

export enum Node {
  ETHEREUM = 'ethereum',
  BINANCE = 'binance',
  ARBITRUM = 'arbitrum',
  POLYGON = 'polygon',
  BASE = 'base',
  BLAST = 'blast',
  OPTIMISM = 'optimism',
  BITCOIN = 'bitcoin',
  DOGECOIN = 'dogecoin',
  BCASH = 'bcash',
  LITECOIN = 'litecoin',
  SOLANA = 'solana',
  TRON = 'tron',
  FANTOM = 'fantom',
  NEAR = 'near',
  TON = 'ton',
  POLKADOT = 'polkadot',
  COSMOS = 'cosmos',
  RIPPLE = 'ripple',
}

export const nodeUrls: { [key in Node]: string } = {
  [Node.ETHEREUM]:
    'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  [Node.BINANCE]:
    'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
  [Node.ARBITRUM]:
    'https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png',
  [Node.POLYGON]:
    'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
  [Node.BASE]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/27716.png',
  [Node.BLAST]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/28480.png',
  [Node.FANTOM]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png',
  [Node.OPTIMISM]:
    'https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png',
  [Node.BITCOIN]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png',
  [Node.DOGECOIN]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/74.png',
  [Node.BCASH]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1831.png',
  [Node.LITECOIN]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2.png',
  [Node.SOLANA]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png',
  [Node.TRON]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png',
  [Node.NEAR]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6535.png',
  [Node.TON]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11419.png',
  [Node.POLKADOT]:
    'https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png',
  [Node.COSMOS]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png',
  [Node.RIPPLE]: 'https://s2.coinmarketcap.com/static/img/coins/64x64/52.png',
};

export const tokenUrls: { [key: string]: string } = {
  ETH: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  BNB: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
  USDC: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
  USDT: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
  ARB: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png',
  POL: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
  MATIC: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
  BASE: 'https://s2.coinmarketcap.com/static/img/coins/64x64/27716.png',
  BLAST: 'https://s2.coinmarketcap.com/static/img/coins/64x64/28480.png',
  OP: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png',
  BTC: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png',
  DOGE: 'https://s2.coinmarketcap.com/static/img/coins/64x64/74.png',
  BCASH: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1831.png',
  LTC: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2.png',
  SOL: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png',
  TRX: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png',
  NEAR: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6535.png',
  TON: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11419.png',
  DOT: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png',
  ATOM: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png',
  PEPE: 'https://s2.coinmarketcap.com/static/img/coins/64x64/24478.png',
  PENDLE: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9481.png',
  XRP: 'https://assets.coingecko.com/coins/images/44/standard/xrp-symbol-white-128.png',
};

export enum CEX {
  BINANCE = 'binance',
  OKX = 'okx',
}

export enum Wallets {
  LEDGER = 'ledger',
  METAMASK = 'metamask',
  PHANTOM = 'phantom',
  SOLFLARE = 'solflare',
  TRONLINK = 'tronlink',
  RABBY = 'rabby',
  COINBASE = 'coinbase',
  TRUST = 'trust',
}
