import React from 'react';

import { plusSign, valueColor } from '../../../utils/amount';
import { AmountCell } from './AmountCell';
import { BaseCellProps } from './Base';

export type ValueChangeCellProps = BaseCellProps & {
  suffix?: string | null;
};

export const ValueChangeCell = (props: ValueChangeCellProps) => {
  const { data, value, suffix } = props;
  const prefix = plusSign(value ?? 0);
  const style = { color: valueColor(value ?? 0), ...(props.style ?? {}) };

  return (
    <AmountCell
      data={data}
      value={value}
      suffix={suffix}
      prefix={prefix}
      style={style}
    />
  );
};
