import { Entity } from './entity';

export type FinalBalanceData = {
  [accountId: string]: {
    [assetId: string]: Balance;
  };
};

export class FinalBalance extends Entity<FinalBalance> {
  data: FinalBalanceData;

  constructor(obj: any) {
    super();

    const res: FinalBalanceData = {};
    for (const [accId, accAssets] of Object.entries(obj)) {
      for (const [assetId, balance] of Object.entries(accAssets as any)) {
        if (!res[accId]) res[accId] = {} as any;

        res[accId][assetId] = Balance.from(balance as Balance);
      }
    }

    this.data = res;
  }

  clone(patch?: Partial<FinalBalance>): FinalBalance {
    return new FinalBalance({ ...this, ...patch });
  }

  req(): FinalBalance {
    return this;
  }
}

export class UnitBalance extends Entity<UnitBalance> {
  final: Balance = Balance.from({});
  data: Balance[] = [];

  constructor(obj: any) {
    super();
    this.final = Balance.from(obj.final ?? {});
    this.data = (obj.data ?? []).map((d: any) => Balance.from(d));
  }

  clone(patch?: Partial<UnitBalance>): UnitBalance {
    const res = new UnitBalance({ ...this, ...patch });
    res.final = Balance.from(patch?.final ?? {});
    res.data = patch?.data?.map((d: any) => Balance.from(d)) ?? [];
    return res;
  }

  req(): UnitBalance {
    const final = this.final.req();
    const data = this.data.map((d: any) => d.req()) ?? [];
    return { final, data } as any;
  }
}

export class Balance extends Entity<Balance> {
  accountId = '';
  assetId = '';
  time = 0;
  amount = 0;
  price = 0;
  value = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'time':
          this.time = obj.time < 1000000000000 ? obj.time * 1000 : obj.time;
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Balance>): Balance {
    return new Balance({ ...this, ...patch });
  }

  req(): Balance {
    this.time = Math.round(this.time / 1000);
    return this;
  }
}
