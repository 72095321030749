import React, { useMemo, useState } from 'react';
import { PushPin as PinIcon } from '@mui/icons-material';
import { ColDef, SortDirection } from 'ag-grid-community';

import { Toggle } from '../controls/Toggle';
import BaseTable, { BaseTableProps } from './BaseTable';
import { Price, PricePin } from '../../entity';
import { useData } from '../../contexts/DataContext';

type PriceTableProps = BaseTableProps & {
  prices: Price[];
  pricePins: PricePin[];
  pinCoin?: (id: string) => void;
  unpinCoin?: (id: string) => void;
  pinnedOnly?: boolean;
};

export const PriceTable: React.FC<PriceTableProps> = ({
  prices,
  pricePins,
  pinCoin,
  unpinCoin,
  pinnedOnly = false,
  ...restProps
}: PriceTableProps) => {
  const { assets } = useData();
  const [pinFilter, setPinFilter] = useState('all');

  const rows = useMemo(
    () =>
      prices.map((p) => {
        const pinned = !!pricePins.find((pc) => pc.symbol === p.symbol);
        const pinColor = pinned ? 'primary' : 'default';
        const marketCap = p.market_cap ?? 0;
        const asset = assets.find((a) => a.symbol === p.symbol);
        const link = asset?.url;
        return {
          ...p,
          marketCap,
          pinned,
          priceImageSrc: p.image,
          pinColor,
          pinIcon: PinIcon,
          link,
        };
      }),
    [assets, prices, pricePins]
  );

  const columnDefs: ColDef[] = [
    {
      headerName: 'Coin',
      field: 'symbol',
      cellRenderer: 'imageText',
      cellRendererParams: {
        linkFieldName: 'link',
        srcFieldName: 'priceImageSrc',
      },
      minWidth: 130,
      maxWidth: 200,
    },
    {
      headerName: 'Price',
      field: 'current_price',
      cellRenderer: 'amount',
      cellRendererParams: {
        suffix: '$',
      },
      minWidth: 130,
      maxWidth: 250,
    },
    {
      headerName: 'Δ 24h',
      field: 'price_change_24h',
      cellRenderer: 'valueChange',
      cellRendererParams: {
        suffix: '$',
      },
      minWidth: 130,
      maxWidth: 250,
    },
    {
      headerName: 'Market Cap',
      field: 'market_cap',
      cellRenderer: 'amount',
      cellRendererParams: {
        prefix: '$',
      },
      initialSort: 'desc' as SortDirection,
      minWidth: 160,
    },
    {
      headerName: 'ATH',
      field: 'ath',
      cellRenderer: 'amount',
      cellRendererParams: {
        prefix: '$',
      },
    },
    {
      headerName: 'FDV',
      field: 'fully_diluted_valuation',
      cellRenderer: 'amount',
      cellRendererParams: {
        prefix: '$',
      },
    },
    {
      headerName: 'Total Sup',
      field: 'total_supply',
      cellRenderer: 'amount',
      minWidth: 130,
    },
    {
      headerName: 'Circulating Sup',
      field: 'circulating_supply',
      cellRenderer: 'amount',
      minWidth: 160,
    },
  ].map((col) => ({ ...col, minWidth: col.minWidth ?? 100 }));

  if (!!pinCoin && !!unpinCoin) {
    columnDefs.push({
      headerName: 'Pin',
      field: 'pinned',
      type: 'boolean',
      filter: true,
      cellRenderer: 'icon',
      maxWidth: 160,
      cellStyle: { textAlign: 'center' },
      cellRendererParams: {
        iconFieldName: 'pinIcon',
        colorFieldName: 'pinColor',
        onClick: (obj: any) => {
          (obj.pinned ? unpinCoin : pinCoin)(obj.symbol);
        },
      },
    });
  }

  if (pinnedOnly) {
    const colDefs = columnDefs.map((c) => ({ ...c, filter: false }));
    const pinnedRows = rows.filter((r) => r.pinned);
    return (
      <BaseTable
        rowData={pinnedRows}
        columnDefs={colDefs}
        {...restProps}
        title={restProps.title ?? 'Prices'}
      />
    );
  }

  const toggleOptions = [
    { value: 'all', label: 'All' },
    { value: 'true', label: 'Pinned' },
  ];

  const filterComponents = [
    <Toggle
      options={toggleOptions}
      value={pinFilter}
      onChange={(newValue) => setPinFilter(newValue)}
      exclusive
      sx={{ marginBottom: '-4px' }}
      toggleGroupProps={{ sx: { marginLeft: 2 } }}
    />,
  ];

  const filterDefs = [
    {
      colId: 'pinned',
      comparatorName: 'values',
      filterType: 'set',
    },
  ];

  const filterValues = { pinned: pinFilter !== 'all' ? [pinFilter] : null };

  return (
    <BaseTable
      rowData={rows}
      columnDefs={columnDefs}
      searchFields={['symbol']}
      filterDefs={filterDefs}
      filterComponents={filterComponents}
      filterValues={filterValues}
      {...restProps}
      title={restProps.title ?? 'Prices'}
    />
  );
};
