import React from 'react';
import { Card as MuiCard, CardProps as MuiCardProps } from '@mui/material';

import { ElementProps, withProps } from '../../entity/components';
import { SectionHeader, SectionHeaderProps } from '../display/SectionHeader';
import { useLayout } from '../../contexts/LayoutContext';

export type CardProps = MuiCardProps & ElementProps & SectionHeaderProps;

export const Card: React.FC<CardProps> = ({
  br = 2,
  boxShadow = 3,
  title,
  imgSize,
  titleVariant = 'subtitle2',
  Icon,
  ...props
}) => {
  const { isMobile } = useLayout();

  const padding = isMobile ? 1 : 2;
  const sx = { overflow: 'visible', ...props.sx, p: padding, br, boxShadow };
  const hasHeader = !!title || !!Icon;

  return (
    <MuiCard {...withProps({ ...props, sx, p: padding })}>
      {hasHeader && (
        <SectionHeader
          mb={isMobile ? 2 : 4}
          title={title ?? ''}
          imgSize={imgSize ?? 'sm'}
          Icon={Icon}
          titleVariant={titleVariant}
          bold
          {...props}
        />
      )}
      {props.children}
    </MuiCard>
  );
};
