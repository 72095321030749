import { Entity } from './entity';
import { Asset, ChainInfo } from './asset';

export class Account extends Entity<Account> {
  id = '';
  name = '';
  chain?: string;
  address?: string;
  assets: Asset[] = [];

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'assets':
          this.assets =
            obj.assets?.map((asset: any) => Asset.from(asset)) ?? [];
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Account>): Account {
    return new Account({ ...this, ...patch });
  }

  req(): Account {
    return this;
  }
}

export const accsAssets = (accs: Account[]): Asset[] => {
  const all = accs.flatMap((account) => account.assets);
  const map: { [key: string]: Asset } = {};
  for (const item of all) {
    const { symbol } = item;
    if (!map.hasOwnProperty(symbol)) {
      map[symbol] = item;
      continue;
    }

    const allCI = [...(item.chainInfo || []), ...(map[symbol].chainInfo || [])];
    const ciMap: { [key: string]: ChainInfo } = {};
    for (const ci of allCI) {
      if (!ciMap.hasOwnProperty(ci.chain!)) {
        ciMap[ci.chain!] = ci;
      }
    }
    map[symbol].chainInfo = Object.values(ciMap).map((ci) =>
      ChainInfo.from(ci)
    );

    if (!map[symbol].name && item.name) {
      map[symbol].name = item.name;
    }
    if (!map[symbol].url && item.url) {
      map[symbol].url = item.url;
    }
    if (!map[symbol].iconUrl && item.iconUrl) {
      map[symbol].iconUrl = item.iconUrl;
    }
  }

  return Object.values(map);
};
