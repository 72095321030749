import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { Text } from '../display/Text';
import { Button } from '../controls/Button';
import { Input } from '../controls/Input';
import { FlexBox } from '../containers/FlexBox';

export type CompleteActivityDialogProps = {
  open: boolean;
  id: string;
  name: string;
  onComplete: (id: string, usdSpent: number, usdGained: number) => void;
  onClose: () => void;
};

export const CompleteActivityDialog: React.FC<CompleteActivityDialogProps> = ({
  open,
  id,
  name,
  onComplete,
  onClose,
}) => {
  const [usdSpent, setUsdSpent] = useState<string>('');
  const [usdGained, setUsdGained] = useState<string>('');
  const [err, setErr] = useState<string>('');

  const handleOnCompleteClick = () => {
    const resSpent = Number(usdSpent);
    const resGained = Number(usdGained);
    if (Number.isNaN(resSpent)) {
      setErr('Wrong numeric value');
      return;
    }
    if (Number.isNaN(resGained)) {
      setErr('Wrong numeric value');
      return;
    }
    onComplete(id, resSpent, resGained);
  };

  return (
    <Dialog open={open} onClose={onClose as any}>
      <DialogTitle id="complete-dialog-title">Complete {name}</DialogTitle>
      <DialogContent>
        <FlexBox column>
          <Text id="complete-dialog-description">
            Enter USD values that were spent and gained.
          </Text>
          <Input
            mt={2}
            onChange={(e) => {
              setErr('');
              setUsdSpent(e.target.value);
            }}
            label="USD Spent"
            value={usdSpent}
          />
          <Input
            mt={2}
            onChange={(e) => {
              setErr('');
              setUsdGained(e.target.value);
            }}
            label="USD Gained"
            value={usdGained}
          />
          {!!err && (
            <Text color="error" variant="caption">
              {err}
            </Text>
          )}
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose as any}>Cancel</Button>
        <Button onClick={handleOnCompleteClick as any}>Complete</Button>
      </DialogActions>
    </Dialog>
  );
};
