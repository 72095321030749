import { Asset } from '../entity';
import { axiosInstance, del, list, post, put } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/assets`;

export const storeAsset = (data: Asset) => post<Asset>(apiUrl, data);
export const updateAsset = (id: string, data: Asset) =>
  put<Asset>(`${apiUrl}/${id}`, data);
export const listAssets = () => list<Asset>(apiUrl, Asset);
export const markAssetAsBalanceTrackable = (id: string) => {
  const body = { trackable: true };
  return axiosInstance.put(`${apiUrl}/${id}/balance-trackable`, body);
};
export const markAssetAsScam = (id: string) => {
  return axiosInstance.put(`${apiUrl}/${id}/scam`);
};
export const updateAssetName = (id: string, name: string) => {
  return axiosInstance.put(`${apiUrl}/${id}/name`, { name });
};
export const updateAssetPriceId = (id: string, priceId: string) => {
  return axiosInstance.put(`${apiUrl}/${id}/price-id`, { priceId });
};
export const deleteAsset = (id: string) => del(`${apiUrl}/${id}`);
