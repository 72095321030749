import React from 'react';

import { NewToken } from '../../entity';
import { OptionalImg } from '../display/OptionalImg';
import { Text } from '../display/Text';
import { FlexBox } from '../containers/FlexBox';
import { HidableCard } from './HidableCard';

interface NewTokenCardProps {
  token: NewToken;
  onHideClick: (id: string) => void;
}

const addNewToken = (id: string) => {
  window.location.href = `/settings?new=true&new-token-id=${id}`;
};

export const NewTokenCard: React.FC<NewTokenCardProps> = ({
  token,
  onHideClick,
}) => (
  <HidableCard
    id={token.id}
    url={token.url}
    onAddClick={addNewToken}
    onHideClick={onHideClick}
  >
    <>
      <OptionalImg mr={1} imgSize="lg" round src={token.iconUrl} />
      <FlexBox column alignItems="flex-start" sx={{ overflowX: 'hidden' }}>
        {!!token.name && (
          <Text bold variant="caption" ellipsis>
            {token.name}
          </Text>
        )}
        <Text variant="caption">{token.symbol}</Text>
      </FlexBox>
    </>
  </HidableCard>
);
