import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

import { FlexBox } from '../containers/FlexBox';
import { Text } from './Text';
import { ElementProps, withProps } from '../../entity/components';
import { AlertIcon, InfoIcon } from '../icons';

export type NotificationBannerProps = ElementProps & {
  onClick?: () => void;
  type?: 'info' | 'warning' | 'success' | 'error';
  description: string;
};

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  onClick,
  type = 'info',
  description,
  ...props
}) => {
  const typeStyles = {
    info: {
      backgroundColor: '#eaf3fb',
      borderColor: '#007bff',
      color: '#007bff',
      Icon: InfoIcon,
    },
    warning: {
      backgroundColor: '#fff4e5',
      borderColor: '#ffa000',
      color: '#ffa000',
      Icon: WarningIcon,
    },
    success: {
      backgroundColor: '#e8f5e9',
      borderColor: '#4caf50',
      color: '#4caf50',
      Icon: AlertIcon,
    },
    error: {
      backgroundColor: '#ffebee',
      borderColor: '#f44336',
      color: '#f44336',
      Icon: ErrorIcon,
    },
  };

  const { backgroundColor, borderColor, color, Icon } =
    typeStyles[type] || typeStyles.info;
  const sx = {
    ...props.sx,
    alignItems: 'center',
    backgroundColor: backgroundColor,
    borderRadius: '4px',
    borderLeft: `4px solid ${borderColor}`,
  };
  props = { ...props, h: '38px', p: 0, pl: 1, pr: 2, sx };

  return (
    <FlexBox {...withProps(props)} onClick={onClick}>
      <Icon imgSize="xs" sx={{ color: color, mr: '4px' }} />
      <Text variant="cell">{description}</Text>
    </FlexBox>
  );
};

export default NotificationBanner;
