import React, { useEffect, useMemo } from 'react';
import YooptaEditor, { createYooptaEditor } from '@yoopta/editor';
import Paragraph from '@yoopta/paragraph';
import Blockquote from '@yoopta/blockquote';
import Embed from '@yoopta/embed';
import Link from '@yoopta/link';
import Callout from '@yoopta/callout';
import { BulletedList, NumberedList, TodoList } from '@yoopta/lists';
import { HeadingOne, HeadingThree, HeadingTwo } from '@yoopta/headings';
import Code from '@yoopta/code';
import ActionMenuList, {
  DefaultActionMenuRender,
} from '@yoopta/action-menu-list';
import Toolbar, { DefaultToolbarRender } from '@yoopta/toolbar';
import LinkTool, { DefaultLinkToolRender } from '@yoopta/link-tool';
import {
  Bold,
  CodeMark,
  Highlight,
  Italic,
  Strike,
  Underline,
} from '@yoopta/marks';

const yooptaPlugins = [
  Paragraph,
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  Blockquote,
  Callout,
  NumberedList,
  BulletedList,
  TodoList,
  Code,
  Link,
  Embed,
];

const yooptaTools = {
  ActionMenu: {
    render: DefaultActionMenuRender,
    tool: ActionMenuList,
  },
  Toolbar: {
    render: DefaultToolbarRender,
    tool: Toolbar,
  },
  LinkTool: {
    render: DefaultLinkToolRender,
    tool: LinkTool,
  },
};

const yooptaMarks = [Bold, Italic, CodeMark, Underline, Strike, Highlight];

type EditorProps = {
  id: string;
  onChange: (id: string, value: any) => void;
  value: any;
};

export const Editor: React.FC<EditorProps> = ({ id, onChange, value }) => {
  const editor = useMemo(() => createYooptaEditor(), []);

  useEffect(() => {
    const handleChange = (value: any) => onChange(id, value);
    editor.on('change', handleChange);
    return () => {
      editor.off('change', handleChange);
    };
  }, [editor]);

  return (
    <YooptaEditor
      id={id}
      editor={editor}
      plugins={yooptaPlugins as any}
      tools={yooptaTools}
      marks={yooptaMarks}
      value={value}
    />
  );
};
