import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';

import { Price } from '../../entity';
import { Select } from '../controls/Select';
import { FlexBox } from '../containers/FlexBox';
import { Input } from '../controls/Input';
import { Modal } from '../modals/Modal';
import { Text } from '../display/Text';
import { OptionalImg } from '../display/OptionalImg';

type AssetPriceIDFormProps = {
  prices: Price[];
  updateFromPrice: (price: Price | { id: string }) => void;
  markAsNoPriceId: () => void;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
};

export const AssetPriceIDForm: React.FC<AssetPriceIDFormProps> = ({
  prices,
  open,
  updateFromPrice,
  markAsNoPriceId,
  onClose,
  onSave,
}) => {
  const [selectedPriceId, setSelectedPriceId] = useState<string>('');
  const [isCustom, setIsCustom] = useState(false); // To toggle between Select and TextField
  const [customPriceId, setCustomPriceId] = useState<string>(''); // For storing custom input

  useEffect(() => {
    setSelectedPriceId('');
    setIsCustom(false);
    setCustomPriceId('');
  }, [open]);

  // Handler for selecting a price from dropdown
  const handlePriceChange = (event: any) => {
    const priceId = event.target.value as string;
    setSelectedPriceId(priceId);
    setIsCustom(false); // Switch to Select mode
    const selectedPrice = prices.find((price) => price.id === priceId);
    if (selectedPrice) {
      updateFromPrice(selectedPrice); // Call the handler when price is selected
    }
  };

  // Handler for custom input
  const handleCustomInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const priceId = event.target.value;
    setCustomPriceId(priceId);
    setSelectedPriceId(''); // Clear the selectedPriceId when typing custom input
    setIsCustom(true); // Switch to custom input mode
    updateFromPrice({ id: priceId }); // Update parent with custom input
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Select or Enter Price ID"
      actions={
        <>
          <Button onClick={markAsNoPriceId}>No CoinGecko</Button>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSave} variant="contained" color="primary">
            Save
          </Button>
        </>
      }
      dialogContentProps={{ sx: { width: '400px' } }}
    >
      <FlexBox center column mt={2} mb={2} pl={2} w="100%">
        <Select
          fullWidth
          label="CoinGecko Price ID"
          value={isCustom ? '' : selectedPriceId}
          options={[
            { value: '', label: 'Select a price or enter custom' },
            ...prices.map((price) => ({ value: price.id, label: price.id })),
          ]}
          onChange={handlePriceChange}
          renderItem={(item) => {
            const iconUrl = prices.find((p) => p.id === item.value)?.image;
            return (
              <FlexBox>
                <OptionalImg src={iconUrl} mr={1} imgSize="sm" />
                <Text variant="body2">{item.label}</Text>
              </FlexBox>
            );
          }}
        />

        <Input
          fullWidth
          mt={2}
          label="Custom Price ID"
          value={customPriceId}
          onChange={handleCustomInput}
          placeholder="Enter custom price ID"
        />
      </FlexBox>
    </Modal>
  );
};
