import React, { useMemo, useState } from 'react';

import { Node, ScamToken } from '../../entity';
import { AutocompleteChain } from '../controls/autocomplete/AutocompleteChain';
import { Modal } from '../modals/Modal';
import { Input } from '../controls/Input';
import { Button } from '../controls/Button';
import { FlexBox } from '../containers/FlexBox';
import { useLayout } from '../../contexts/LayoutContext';

type ScamTokenFormProps = {
  open: boolean;
  onClose: () => void;
  onSave: (token: ScamToken) => void;
};

export const ScamTokenForm: React.FC<ScamTokenFormProps> = (props) => {
  const { isMobile } = useLayout();
  const { open, onClose, onSave } = props;
  const chains = useMemo(() => Object.values(Node), []);
  const [data, setData] = useState<ScamToken>(
    ScamToken.from({ createdAt: Date.now(), checked: true })
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add New Scam Token"
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={() => onSave(data)}>Save</Button>
        </>
      }
    >
      <FlexBox mt={isMobile ? 0 : 2} mb={isMobile ? 0 : 2} pl={2} w="100%">
        <FlexBox display="flex" column w="100%">
          <Input
            fullWidth
            required
            label="Address"
            value={data.address || ''}
            mb={isMobile ? 0 : 2}
            mt={0}
            onChange={(e) => setData(data.clone({ address: e.target.value }))}
          />
          <AutocompleteChain
            items={chains}
            data={(data.chain as Node) ?? null}
            mb={0}
            mt={0}
            onChange={(n) => setData(data.clone({ chain: n as Node }))}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};
