import React, { useEffect } from 'react';

const loadScript = (src: string) => {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.defer = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
    document.body.appendChild(script);
  });
};

export const Marquee = () => {
  useEffect(() => {
    const scriptSrc = 'https://cryptorank.io/widget/marquee.js';

    const addWidget = async () => {
      try {
        await loadScript(scriptSrc);
      } catch (error) {
        console.error('Error loading Live Coin Watch script:', error);
      }
    };

    addWidget();
  }, []);

  return (
    <div
      id="cr-widget-marquee"
      style={{ backgroundColor: '#fff', minHeight: '68px' }}
      data-coins="bitcoin,ethereum,tether,ripple,cardano"
      data-theme="light"
      data-show-symbol="true"
      data-show-icon="true"
      data-show-period-change="true"
      data-period-change="24H"
      data-api-url="https://api.cryptorank.io/v0"
    />
  );
};
