import React, { useMemo } from 'react';

import { Autocomplete, AutocompleteProps } from './Autocomplete';
import { Asset, AssetWithPrice, Price } from '../../../entity';
import { SelectedItem } from './SelectedItem';
import { ItemCard } from './ItemCard';
import { withProps } from '../../../entity/components';
import { RenderItemParams } from './ListBox';
import { OptionalImg } from '../../display/OptionalImg';
import { useLayout } from '../../../contexts/LayoutContext';

type AutocompleteAssetProps = Partial<AutocompleteProps<Asset>> & {
  items: Asset[];
  data: Asset | Asset[] | null;
  onChange: (val: Asset | Asset[] | null) => void;
  multiselect?: boolean;
  prices: Price[];
};

export const AutocompleteAsset: React.FC<AutocompleteAssetProps> = ({
  items = [],
  data,
  onChange,
  multiselect = false,
  prices = [],
  ...props
}) => {
  const { isMobile } = useLayout();
  const priceMap = useMemo(
    () => new Map(prices.map((p) => [p.symbol, p])),
    [prices]
  );

  const renderSelectedAsset = (asset: AssetWithPrice) => {
    const icon =
      asset !== null && !!asset?.symbol ? (
        <OptionalImg src={asset.iconUrl} imgSize="sm" />
      ) : undefined;
    return (
      <SelectedItem
        label={asset?.symbol ?? ''}
        value={asset?.symbol ?? ''}
        Icon={icon}
      />
    );
  };

  const renderAsset = (
    asset: AssetWithPrice,
    params: RenderItemParams<AssetWithPrice>
  ) => {
    return (
      <ItemCard<AssetWithPrice>
        item={asset}
        iconUrl={asset?.iconUrl}
        mainText={asset.symbol}
        subText={asset?.price}
        multiselect={multiselect}
        {...params}
      />
    );
  };

  const assets: AssetWithPrice[] = useMemo(
    () =>
      items.map((asset) => {
        const price = priceMap.get(asset.symbol);
        return new AssetWithPrice(asset, price?.current_price);
      }),
    [items, prices]
  );
  const sortedAssets = useMemo(() => AssetWithPrice.sort(assets), [assets]);

  return (
    <Autocomplete<AssetWithPrice>
      size={props.size ?? (isMobile ? 'small' : 'medium')}
      {...withProps(props)}
      multiselect={multiselect}
      label={'Asset'}
      renderSelected={renderSelectedAsset}
      renderItem={renderAsset}
      items={sortedAssets}
      onChange={onChange}
      itemKeyName="symbol"
      data={data}
      filterItems={(inputValue, item: any) =>
        item.symbol.toLowerCase().includes(inputValue.toLowerCase())
      }
    />
  );
};
