import React, { useCallback, useMemo } from 'react';

import { Autocomplete, AutocompleteProps } from './Autocomplete';
import { Node } from '../../../entity';
import { ItemCard } from './ItemCard';
import { SelectedItem } from './SelectedItem';
import { withProps } from '../../../entity/components';
import { nodeIcon } from '../../icons/chains';
import { capitalize } from '../../../utils/text';
import { RenderItemParams } from './ListBox';
import { useLayout } from '../../../contexts/LayoutContext';

type AutocompleteChainProps = Partial<AutocompleteProps<Node>> & {
  items: Node[];
  data: Node | Node[] | null;
  onChange: (val: Node | Node[] | null) => void;
  multiselect?: boolean;
};

export const AutocompleteChain = React.memo(
  ({
    items: chains,
    data,
    onChange,
    multiselect = false,
    ...props
  }: AutocompleteChainProps) => {
    const { isMobile } = useLayout();

    // Stabilize data with a fallback to an empty array for multiselect or null for single-select
    const stableData = useMemo(() => {
      return (
        (data && (Array.isArray(data) ? data : [data])) ||
        (multiselect ? [] : null)
      );
    }, [data, multiselect]);

    // Wrap onChange in a stable useCallback to prevent unnecessary re-renders
    const stableOnChange = useCallback(
      (val: Node | Node[] | null) => {
        onChange(val);
      },
      [onChange]
    );

    return (
      <Autocomplete<Node>
        {...withProps(props)}
        items={chains}
        data={stableData}
        size={props.size ?? (isMobile ? 'small' : 'medium')}
        onChange={stableOnChange}
        multiselect={multiselect}
        label={'Chain'}
        renderSelected={(chain) => (
          <SelectedItem
            Icon={nodeIcon(chain, 'sm')}
            label={capitalize(chain)}
            value={capitalize(chain)}
          />
        )}
        renderItem={(chain: Node, params: RenderItemParams<Node>) => (
          <ItemCard
            item={chain}
            Icon={nodeIcon(chain, 'sm', 1)}
            mainText={capitalize(chain)}
            multiselect={multiselect}
            {...params}
          />
        )}
        filterItems={(inputValue, item) =>
          item.toLowerCase().includes(inputValue.toLowerCase())
        }
      />
    );
  }
);
