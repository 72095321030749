import React, { useEffect, useMemo, useState } from 'react';

import { ellipsis } from '../../utils/text';
import { InfoList, InfoListEntry } from '../containers/InfoList';
import { EarnProfit } from '../../entity/profit';
import { earnIcon } from '../../utils/icons';
import { ActionButtons } from '../controls/ActionButtons';
import { Card } from './Card';
import { Text } from '../display/Text';
import { Link } from '../display/Link';
import { FlexBox } from '../containers/FlexBox';
import { ElementProps, withProps } from '../../entity/components';
import { useLayout } from '../../contexts/LayoutContext';
import {
  Earn,
  Node,
  Price,
  TimeRange,
  timeRanges,
  UnitBalance,
} from '../../entity';
import { getEarnBalances } from '../../services/balances';
import { useData } from '../../contexts/DataContext';
import { normalizeValue } from '../../utils/amount';
import { HoldingCharts } from '../charts/HoldingCharts';
import { Select } from '../controls/Select';
import { renderAsset } from '../display/Asset';
import { renderChain } from '../display/Chain';
import { getProfit } from '../../services';
import NotificationBanner from '../display/NotificationBanner';
import { timestampToDateTimeStr } from '../../utils/date';

const minDaysBeforeUnlockBanner = 3;

const getInfoListData = (
  data: Earn,
  balance: UnitBalance,
  prices: Price[],
  isMobile: boolean,
  profitData?: EarnProfit
) => {
  const asset = data.account.assets.length ? data.account.assets[0] : null;
  const finalBalance = balance.final;
  const price = finalBalance?.price ?? asset?.findPrice(prices);
  const assetAddr = asset?.address(data.chain as any);

  const totalAmount = finalBalance.amount;
  const currentBalance = data.currentBalance() * (price ?? 0);
  const rewards = finalBalance?.value
    ? finalBalance.value - currentBalance
    : undefined;

  const profitArr = profitData?.profit()?.profits;
  const profit = profitArr?.length
    ? profitArr[profitArr.length - 1]
    : undefined;
  const profitInTokens = profit?.endAmount;

  const list: InfoListEntry[] = [];

  if (currentBalance) {
    list.push({
      key: 'Balance',
      value: `${normalizeValue(currentBalance)} $`,
      color: false,
    });
  }

  if (rewards) {
    list.push({
      key: 'Rewards',
      value: `${normalizeValue(rewards)} $`,
    });
  }

  if (totalAmount) {
    list.push({
      key: 'Tokens Count',
      value: `${normalizeValue(totalAmount)} ${asset?.symbol ?? ''}`,
    });
  }

  list.push({
    key: 'Asset',
    value: renderAsset(asset),
  });

  if (data.chain) {
    list.push({
      key: 'Network',
      value: renderChain(data.chain as Node),
    });
  }

  if (assetAddr) {
    list.push({
      key: 'Asset Address',
      value: isMobile ? ellipsis(assetAddr) : assetAddr,
    });
  }

  if (price) {
    list.push({
      key: 'Asset USD Price',
      value: `${normalizeValue(price)} $`,
      color: false,
    });
  }

  if (profit) {
    list.push({
      key: 'Profit',
      value: `${normalizeValue(profit.profit)} $`,
    });
  }

  if (profitInTokens) {
    list.push({
      key: 'Profit in Tokens',
      value: `${normalizeValue(profitInTokens)} ${asset?.symbol ?? ''}`,
    });
  }

  if (data.apr) {
    list.push({
      key: 'APR',
      value: `${normalizeValue(data.apr)} %`,
      color: false,
    });
  }

  return list;
};

export type EarnCardProps = ElementProps & {
  data: Earn;
  onChangeBalance: (data: Earn) => void;
  onEdit: (data: Earn) => void;
  onDelete: (data: Earn) => void;
  onComplete: (data: Earn) => void;
  onBalance?: (id: string, balance: UnitBalance) => void;
};

export const EarnCard: React.FC<EarnCardProps> = ({
  data,
  onChangeBalance,
  onEdit,
  onDelete,
  onComplete,
  onBalance,
  ...props
}) => {
  const { isMobile } = useLayout();
  const { prices } = useData();
  const [range, setRange] = useState<TimeRange>(timeRanges[1]);
  const [balance, setBalance] = useState<UnitBalance>(UnitBalance.from({}));
  const [profit, setProfit] = useState<EarnProfit>(EarnProfit.from({}));

  useEffect(() => {
    if (onBalance) onBalance(data.id, balance);
  }, [balance]);

  useEffect(() => {
    getEarnBalances(range.label, data.id)
      .then((res) => setBalance(res))
      .catch((err) => console.error(`failed to fetch balance: ${err.message}`));

    getProfit(range.label)
      .then((res) => {
        setProfit(res.earns.earns.get(data.id) ?? EarnProfit.from({}));
      })
      .catch((err) => console.error(`failed to fetch profit: ${err.message}`));
  }, [range, data]);

  const daysBeforeUnlock = useMemo(() => data.daysBeforeUnlock(), [data]);
  const infoList = useMemo(() => {
    return getInfoListData(data, balance, prices, isMobile ?? false, profit);
  }, [data, balance, profit, prices]);

  const handleRangeChange = (e: any) =>
    setRange(timeRanges.find((tr) => tr.value === +e.target.value)!);

  const DefaultIcon = useMemo(() => {
    return earnIcon(data.platformName);
  }, [data]);

  const minHeight = isMobile ? 'auto' : 1200;
  const sx = {
    minHeight,
    ...(props.sx ?? {}),
  };

  const Title = (
    <Text
      variant="subtitle1"
      sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      {data.platformUrl ? (
        <Link
          underline="none"
          variant="subtitle1"
          href={data.platformUrl}
          target="_blank"
        >
          {data.name}
        </Link>
      ) : (
        data.name
      )}
    </Text>
  );

  return (
    <Card flex column {...withProps({ ...props, sx })}>
      <FlexBox alignItems="center" mb={isMobile ? 1 : 2}>
        <DefaultIcon imgSize="md" mr={1} />
        {Title}
        <Select
          ml="auto"
          mt={1}
          size="small"
          options={timeRanges}
          value={`${range.value}`}
          w={100}
          onChange={handleRangeChange}
        />
      </FlexBox>

      <InfoList h={isMobile ? 'auto' : 250} data={infoList} />
      {!!daysBeforeUnlock && daysBeforeUnlock <= minDaysBeforeUnlockBanner && (
        <NotificationBanner
          mxw={400}
          type="warning"
          description={`Will be unlocked soon: ${timestampToDateTimeStr(daysBeforeUnlock)}`}
        />
      )}

      <HoldingCharts range={range.label} balance={balance} profit={profit} />

      <ActionButtons
        mt="auto"
        onChange={onChangeBalance}
        onComplete={onComplete}
        onEdit={onEdit}
        onDelete={onDelete}
        data={data}
      />
    </Card>
  );
};
