import { Entity } from './entity';

export class NewToken extends Entity<NewToken> {
  id = '';
  name = '';
  symbol = '';
  url?: string;
  iconUrl?: string;
  hidden?: boolean;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      (this as any)[key] = obj[key];
    });
  }

  clone(patch?: Partial<NewToken>): NewToken {
    return new NewToken({ ...this, ...patch });
  }

  req(): NewToken {
    return this;
  }
}
