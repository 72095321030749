import React from 'react';
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  ToggleButtonProps,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ElementProps, withProps } from '../../entity/components';
import { useLayout } from '../../contexts/LayoutContext';

interface ToggleOption {
  value: string;
  label: string;
}

export type ToggleProps = ElementProps & {
  options: ToggleOption[];
  value: string;
  onChange: (value: string) => void;
  exclusive?: boolean;
  toggleGroupProps?: Omit<
    ToggleButtonGroupProps,
    'value' | 'exclusive' | 'onChange'
  >;
  toggleButtonProps?: Omit<
    ToggleButtonProps,
    'value' | 'exclusive' | 'onChange'
  >;
};

export const Toggle: React.FC<ToggleProps> = ({
  options,
  value,
  onChange,
  exclusive = true,
  toggleGroupProps,
  toggleButtonProps,
  ...props
}) => {
  const theme = useTheme();
  const { isMobile } = useLayout();

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive={exclusive}
      onChange={handleChange}
      {...toggleGroupProps}
      {...withProps(props)}
    >
      {options.map((option) => (
        <ToggleButton
          size="small"
          key={option.value}
          value={option.value}
          sx={{
            height: isMobile ? '32px' : '40px',
            fontSize: theme.typography.button.fontSize,
            '&.Mui-selected': {
              backgroundColor: 'primary.main',
              color: 'white',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            },
          }}
          {...toggleButtonProps}
        >
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
