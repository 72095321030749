import { TimeRangeType } from './range';
import { Asset } from './asset';
import { Wallet } from './wallet';
import { Earn } from './earn';

export enum HoldingType {
  ALL = 'all',
  WALLETS = 'wallets',
  EARNS = 'earns',
}

export type BalanceQuery = {
  timeRange?: TimeRangeType;
  holding?: HoldingType;
  walletId?: string;
  earnId?: string;
  accountId?: string;
  assetId?: string;
};

export const generateBalanceQueryString = (query: BalanceQuery): string => {
  let res = '?';

  if (query.timeRange) {
    res += `range=${query.timeRange}`;
  } else {
    res += `range=${TimeRangeType.WEEK}`;
  }

  if (query.accountId && query.assetId) {
    return (res += `&account_id=${query.accountId}&asset_id=${query.assetId}`);
  }
  if (query.accountId) {
    return (res += `&account_id=${query.accountId}`);
  }

  if (query.walletId) {
    return (res += `&wallet_id=${query.walletId}&holding=wallets`);
  }
  if (query.earnId) {
    return (res += `&earn_id=${query.earnId}&holding=earns`);
  }

  return (res += `&holding=${query.holding}`);
};

export const getBalanceQueryKey = (q: BalanceQuery): string => {
  return `${q.timeRange}:${q.holding}:${q.assetId}:${q.accountId}:${q.walletId}:${q.earnId}`;
};

export const getAssetFromQuery = (
  query: BalanceQuery,
  wallets: Wallet[],
  earns: Earn[]
): Asset | undefined => {
  if (query.holding === HoldingType.WALLETS) {
    return getAssetFromWallets(query, wallets);
  } else if (query.holding === HoldingType.EARNS) {
    return getAssetFromWallets(query, wallets);
  }

  const res = getAssetFromEarns(query, earns);
  if (res) return res;

  return getAssetFromWallets(query, wallets);
};

const getAssetFromWallets = (
  query: BalanceQuery,
  wallets: Wallet[]
): Asset | undefined => {
  const wallet = wallets.find((w) => w.id === query.walletId);
  const account = wallet?.accounts.find((acc) => acc.id === query.accountId);
  return account?.assets.find((a) => a.id === query.assetId);
};

const getAssetFromEarns = (
  query: BalanceQuery,
  earns: Earn[]
): Asset | undefined => {
  const earn = earns.find((e) => e.id === query.earnId);
  return earn?.account.assets.length ? earn?.account.assets[0] : undefined;
};
