import { Node } from '../entity';

const nativeTokens = [
  'SOL',
  'BTC',
  'ETH',
  'MATIC',
  'BNB',
  'ARB',
  'BASE',
  'OPT',
  'DOGE',
  'LTC',
  'BCH',
  'TON',
  'NEAR',
  'TRX',
];

export const explorerTx = (network: string, hash: string): string => {
  switch (network) {
    case Node.ETHEREUM:
      return `https://etherscan.io/tx/${hash}`;
    case Node.BINANCE:
      return `https://bscscan.com/tx/${hash}`;
    case Node.ARBITRUM:
      return `https://arbiscan.io/tx/${hash}`;
    case Node.POLYGON:
      return `https://polygonscan.com/tx/${hash}`;
    case Node.BASE:
      return `https://basescan.org/tx/${hash}`;
    case Node.BLAST:
      return `https://blastscan.io/tx/${hash}`;
    case Node.OPTIMISM:
      return `https://optimistic.etherscan.io/tx/${hash}`;
    case Node.SOLANA:
      return `https://solscan.io/tx/${hash}`;
    case Node.TRON:
      return `https://tronscan.org/#/tx/${hash}`;
    case Node.FANTOM:
      return `https://ftmscan.com/tx/${hash}`;
    case Node.DOGECOIN:
      return `https://dogechain.info/tx/${hash}`;
    case Node.LITECOIN:
      return `https://blockcypher.com/ltc/tx/${hash}`;
    case Node.BCASH:
      return `https://live.blockcypher.com/bch/tx/${hash}`;
    case Node.TON:
      return `https://tonscan.org/tx/${hash}`;
    case Node.NEAR:
      return `https://nearblocks.io/tx/${hash}`;
    default:
      return `https://etherscan.io/tx/${hash}`;
  }
};

export const explorerAddr = (
  txLink: string,
  address: string
): string | undefined => {
  if (txLink.includes('etherscan.io')) {
    return `https://etherscan.io/address/${address}`;
  } else if (txLink.includes('bscscan.com')) {
    return `https://bscscan.com/address/${address}`;
  } else if (txLink.includes('arbiscan.io')) {
    return `https://arbiscan.io/address/${address}`;
  } else if (txLink.includes('polygonscan.com')) {
    return `https://polygonscan.com/address/${address}`;
  } else if (txLink.includes('base.blockscout.com')) {
    return `https://base.blockscout.com/address/${address}`;
  } else if (txLink.includes('blastscan.io')) {
    return `https://blastscan.io/address/${address}`;
  } else if (txLink.includes('optimistic.etherscan.io')) {
    return `https://optimistic.etherscan.io/address/${address}`;
  } else if (txLink.includes('solscan.io')) {
    return `https://solscan.io/account/${address}`;
  } else if (txLink.includes('tronscan.org')) {
    return `https://tronscan.org/#/address/${address}`;
  } else if (txLink.includes('dogechain.info')) {
    return `https://dogechain.info/address/${address}`;
  } else if (txLink.includes('blockcypher.com/ltc')) {
    return `https://blockcypher.com/ltc/address/${address}`;
  } else if (txLink.includes('live.blockcypher.com/bch')) {
    return `https://live.blockcypher.com/bch/address/${address}`;
  } else if (txLink.includes('tonscan.org')) {
    return `https://tonscan.org/address/${address}`;
  } else if (txLink.includes('nearblocks.io')) {
    return `https://nearblocks.io/address/${address}`;
  } else {
    return undefined;
  }
};

export const explorerToken = (network: string, address: string): string => {
  switch (network) {
    case Node.ETHEREUM:
      return `https://etherscan.io/token/${address}`;
    case Node.BINANCE:
      return `https://bscscan.com/token/${address}`;
    case Node.ARBITRUM:
      return `https://arbiscan.io/token/${address}`;
    case Node.POLYGON:
      return `https://polygonscan.com/token/${address}`;
    case Node.BASE:
      return `https://basescan.org/token/${address}`;
    case Node.BLAST:
      return `https://blastscan.io/token/${address}`;
    case Node.OPTIMISM:
      return `https://optimistic.etherscan.io/token/${address}`;
    case Node.SOLANA:
      return `https://solscan.io/token/${address}`;
    case Node.TRON:
      return `https://tronscan.org/#/token/${address}`;
    case Node.FANTOM:
      return `https://ftmscan.com/token/${address}`;
    case Node.DOGECOIN:
      return `https://dogechain.info/token/${address}`;
    case Node.LITECOIN:
      return `https://blockcypher.com/ltc/token/${address}`;
    case Node.BCASH:
      return `https://live.blockcypher.com/bch/token/${address}`;
    case Node.TON:
      return `https://tonscan.org/token/${address}`;
    case Node.NEAR:
      return `https://nearblocks.io/token/${address}`;
    default:
      return `https://etherscan.io/token/${address}`;
  }
};

export const explorerTokenFromTx = (
  txLink: string,
  symbol: string,
  address: string
): string | undefined => {
  if (!symbol || !address || !txLink) return undefined;
  if (nativeTokens.includes(symbol)) {
    return undefined;
  }

  // Generate the token URL based on the explorer URL pattern
  if (txLink.includes('etherscan.io')) {
    return `https://etherscan.io/token/${address}`;
  } else if (txLink.includes('bscscan.com')) {
    return `https://bscscan.com/token/${address}`;
  } else if (txLink.includes('arbiscan.io')) {
    return `https://arbiscan.io/token/${address}`;
  } else if (txLink.includes('polygonscan.com')) {
    return `https://polygonscan.com/token/${address}`;
  } else if (txLink.includes('base.blockscout.com')) {
    return `https://base.blockscout.com/token/${address}`;
  } else if (txLink.includes('blastscan.io')) {
    return `https://blastscan.io/token/${address}`;
  } else if (txLink.includes('optimistic.etherscan.io')) {
    return `https://optimistic.etherscan.io/token/${address}`;
  } else if (txLink.includes('ftmscan.com')) {
    return `https://ftmscan.com/token/${address}`;
  } else if (txLink.includes('solscan.io')) {
    return `https://solscan.io/token/${address}`;
  } else if (txLink.includes('tronscan.org')) {
    return `https://tronscan.org/#/token/${address}`;
  } else if (txLink.includes('dogechain.info')) {
    return `https://dogechain.info/token/${address}`;
  } else if (txLink.includes('blockcypher.com/ltc')) {
    return `https://blockcypher.com/ltc/token/${address}`;
  } else if (txLink.includes('live.blockcypher.com/bch')) {
    return `https://live.blockcypher.com/bch/token/${address}`;
  } else if (txLink.includes('tonscan.org')) {
    return `https://tonscan.org/token/${address}`;
  } else if (txLink.includes('nearblocks.io')) {
    return `https://nearblocks.io/token/${address}`;
  } else {
    return undefined;
  }
};
