import React, { useEffect, useMemo, useState } from 'react';

import BaseTable, { BaseTableProps } from './BaseTable';
import { getDeFiTrackerTransactions } from '../../services';
import { DeFiTrackerTx } from '../../entity';
import {
  explorerAddr,
  explorerTokenFromTx,
  explorerTx,
} from '../../utils/chain';
import { timestampToDateStr, timestampToTimeStr } from '../../utils/date';
import { capitalize, ellipsis } from '../../utils/text';
import { AmountCell } from './cells/AmountCell';
import { LinkCell } from './cells/LinkCell';
import { tokenIcon } from '../icons';

export type DeFiTrackerTxTableProps = BaseTableProps;

export const DeFiTrackerTxTable: React.FC<DeFiTrackerTxTableProps> = (
  props
) => {
  const [txs, setTxs] = useState<DeFiTrackerTx[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams();
      params.append('size', '500');
      params.append('page', '1');
      const data = await getDeFiTrackerTransactions(params.toString());
      setTxs(data.txs);
    };

    fetchData();
  }, []);

  const rows = useMemo(
    () =>
      txs.map((tx) => {
        const txUrl = explorerTx(tx.networkBuy.toLowerCase(), tx.txHash);
        const fromUrl = explorerAddr(tx.networkBuy.toLowerCase(), tx.sender);
        const toUrl = tx.contractAddress
          ? explorerAddr(tx.networkBuy.toLowerCase(), tx.contractAddress)
          : '';
        const tokenUrl = tx.tokenAddress
          ? explorerTokenFromTx(txUrl, tx.tokenName, tx.tokenAddress)
          : '';
        const statusColor = tx.success ? '#5da78b' : '#b00b1e';
        const txTokenIcon = tokenIcon(tx.tokenName);

        return {
          ...tx,
          date: timestampToDateStr(tx.timeSent),
          timeSent: timestampToTimeStr(tx.timeSent),
          timeCreated: tx.timeCreated
            ? timestampToTimeStr(tx.timeCreated)
            : '--',
          timeConfirmed: tx.timeConfirmed
            ? timestampToTimeStr(tx.timeConfirmed)
            : '--',
          txHash: ellipsis(tx.txHash),
          statusColor,
          contractAddress: ellipsis(tx.contractAddress ?? ''),
          txUrl,
          from: ellipsis(tx.sender),
          fromUrl,
          toUrl,
          networkBuy: capitalize(tx.networkBuy),
          networkSell: tx.networkSell ? capitalize(tx.networkSell) : '--',
          tokenName: tx.tokenName || '--',
          tokenIcon: txTokenIcon,
          tokenAddress: ellipsis(tx.tokenAddress),
          tokenUrl,
          status: tx.success ? 'success' : 'failed',
        };
      }),
    [txs]
  );

  const columnDefs = [
    { headerName: 'Date', field: 'date' },
    { headerName: 'Sent', field: 'timeSent' },
    { headerName: 'Created', field: 'timeCreated' },
    { headerName: 'Confirmed', field: 'timeConfirmed' },
    {
      headerName: 'Hash',
      field: 'txHash',
      cellRenderer: 'link',
      cellRendererParams: { urlFieldName: 'txUrl' },
      minWidth: 300,
    },
    {
      headerName: 'From',
      field: 'from',
      cellRenderer: 'link',
      cellRendererParams: { urlFieldName: 'fromUrl' },
      minWidth: 300,
    },
    {
      headerName: 'Contract',
      field: 'contractAddress',
      cellRenderer: 'link',
      cellRendererParams: { urlFieldName: 'toUrl' },
      minWidth: 300,
    },
    { headerName: 'Network Buy', field: 'networkBuy' },
    { headerName: 'Network Sell', field: 'networkSell' },
    {
      headerName: 'Token Name',
      field: 'tokenName',
      cellRenderer: 'imageText',
      cellRendererParams: { srcFieldName: 'tokenIcon' },
    },
    {
      headerName: 'Token Address',
      field: 'tokenAddress',
      cellRenderer: 'link',
      cellRendererParams: { urlFieldName: 'tokenUrl' },
      minWidth: 300,
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'status',
      cellRendererParams: { colorFieldName: 'statusColor' },
    },
    { headerName: 'Value', field: 'value', cellRenderer: 'amount' },
    {
      headerName: 'Expected Profit',
      field: 'expectedProfit',
      cellRenderer: 'amount',
      minWidth: 170,
    },
    { headerName: 'Fee', field: 'fee', cellRenderer: 'amount' },
    { headerName: 'Type', field: 'txType' },
    { headerName: 'DEX', field: 'dexName' },
    { headerName: 'Bridge', field: 'bridgeName' },
    { headerName: 'Block Submitted', field: 'blockSubmitted', minWidth: 170 },
    { headerName: 'Block Confirmed', field: 'blockConfirmed', minWidth: 170 },
    { headerName: 'Block Pos', field: 'blockPos' },
    {
      headerName: 'Dst Token Amount',
      field: 'dstTokenAmount',
      cellRenderer: 'amount',
      minWidth: 180,
    },
    {
      headerName: 'Src Token Amount',
      field: 'srcTokenAmount',
      cellRenderer: 'amount',
      minWidth: 180,
    },
  ].map((col) => ({ ...col, minWidth: col.minWidth ?? 150 }));

  return (
    <BaseTable
      rowData={rows}
      columnDefs={columnDefs}
      components={{ amount: AmountCell, link: LinkCell }}
      searchFields={[
        'date',
        'txHash',
        'sender',
        'contractAddress',
        'networkBuy',
        'networkSell',
        'tokenName',
        'tokenAddress',
        'status',
        'txType',
        'dexName',
        'bridgeName',
      ]}
      {...props}
      title={props.title ?? 'DeFi Tracker Transactions'}
    />
  );
};

export default DeFiTrackerTxTable;
