import { Entity } from './entity';
import { Account, accsAssets } from './account';

export enum WalletPurpose {
  DEPOSIT = 'deposit',
  SELL = 'sell',
  DROP = 'drop',
  HD = 'hd',
  HOT = 'hot',
  CEX = 'cex',
  DEX = 'dex',
  HODL = 'hodl',
  SPOT = 'spot',
  FUTURES = 'futures',
  STAKING = 'staking',
  ARBITRAGE = 'arbitrage',
  DT = 'defi-tracker',
}

export class Wallet extends Entity<Wallet> {
  id = '';
  name = '';
  platform = '';
  purposes: WalletPurpose[] = [];
  accounts: Account[] = [];
  createdAt = 0;
  updatedAt = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'createdAt':
          this.createdAt = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'updatedAt':
          this.updatedAt = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'accounts':
          this.accounts =
            obj.accounts?.map((acc: any) => Account.from(acc)) ?? [];
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Wallet>): Wallet {
    return new Wallet({ ...this, ...patch });
  }

  req(): Wallet {
    this.createdAt = Math.round(this.createdAt / 1000);
    this.updatedAt = Math.round(this.updatedAt / 1000);
    return this;
  }

  acc(accId: string) {
    return this.accounts.find((acc) => acc.id === accId);
  }

  asset(accId: string, assetId: string) {
    return this.accounts
      .find((acc) => acc.id === accId)
      ?.assets.find((asset) => asset.id === assetId);
  }

  assets() {
    return accsAssets(this.accounts);
  }

  assetCount() {
    return this.accounts.reduce((all, acc) => all + acc.assets.length, 0);
  }

  chains() {
    const res: Set<string> = new Set();
    for (const acc of this.accounts) {
      if (acc.chain) res.add(acc.chain);
    }
    return Array.from(res);
  }
}
