import {
  Account,
  Activity,
  ActivityType,
  Alert,
  AlertType,
  Asset,
  AssetType,
  Balance,
  ChainInfo,
  Earn,
  FearGreed,
  Media,
  PriceAlertThresholds,
  Wallet,
} from '../entity';

export const defaultEditorValue = {
  '73c26636-26a1-45ce-b1ce-c827b2eb8514': {
    id: '73c26636-26a1-45ce-b1ce-c827b2eb8514',
    value: [
      {
        id: 'd71469e6-17e6-439d-ae7e-15da85bae085',
        type: 'paragraph',
        children: [{ text: 'New Note...' }],
        props: { nodeType: 'block' },
      },
    ],
    type: 'Paragraph',
    meta: { order: 0, depth: 0 },
  },
};

export const defaultActivity: Activity = Activity.from({
  id: '',
  name: '',
  chain: '',
  platform: '',
  type: 'drop' as ActivityType,
  todoList: new Uint8Array(),
  startDate: Date.now(),
  endDate: 0,
  tokenAmount: 0,
  tokenName: '',
  claimStatus: false,
  blockchainAddress: '',
  url: '',
  iconUrl: '',
  isNew: false,
  completed: false,
});

export const defaultPriceAlertThresholds: PriceAlertThresholds = {
  min: 0,
  max: 0,
};

export const defaultAssetChainInfo = ChainInfo.from({
  address: '',
  chain: '',
  decimals: 0,
  type: 'coin' as AssetType,
});

export const defaultAsset = Asset.from({
  id: '',
  name: '',
  symbol: '',
  url: '',
  iconUrl: '',
  chainInfo: [defaultAssetChainInfo],
});

export const defaultAlert = Alert.from({
  id: '',
  name: '',
  chain: '',
  type: 'price' as AlertType,
  alertSent: false,
  currentPrice: 0,
  symbol: '',
  thresholds: defaultPriceAlertThresholds,
  time: Date.now(),
  description: '',
  createdAt: Date.now(),
  updatedAt: Date.now(),
});

export const defaultFearGreed = FearGreed.from({
  value: 0,
});

export const defaultMedia = Media.from({
  id: '',
  url: '',
  name: '',
  category: '',
  language: '',
});

export const defaultTimeAmount = Balance.from({
  time: Date.now(),
  amount: 0,
  price: 0,
  value: 0,
});

export const defaultAccount = Account.from({
  id: '',
  name: '',
  chain: '',
  address: '',
  assets: [],
});

export const defaultWallet = Wallet.from({
  id: '',
  name: '',
  platform: '',
  purposes: [],
  accounts: [],
  createdAt: Date.now(),
  updatedAt: Date.now(),
});

export const defaultEarn = Earn.from({
  id: '',
  name: '',
  chain: '',
  account: { id: '', name: '', chain: '', address: '', assets: [defaultAsset] },
  platformName: '',
  platformUrl: '',
  blockchainAddress: '',
  balance: 0,
  ytd: 0,
  apr: 0,
  duration: 0,
  rewardsEarned: 0,
  locked: false,
  completed: false,
  unlockDate: 0,
  createdAt: Date.now(),
  updatedAt: Date.now(),
});

export const defaultTableQuery = {
  size: '200',
  page: '1',
};
