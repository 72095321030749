import React, { ReactNode } from 'react';
import { ThemeProvider } from '@mui/material';

import { theme } from '../theme/theme';
import { ToastProvider } from './ToastContext';
import { LoaderProvider } from './LoaderContext';
import { DataProvider } from './DataContext';
import { PageProvider } from './PageContext';
import { LayoutProvider } from './LayoutContext';

type AppContextProps = { children: ReactNode };

export const AppContext: React.FC<AppContextProps> = ({
  children,
}: AppContextProps) => (
  <ThemeProvider theme={theme}>
    <LoaderProvider>
      <ToastProvider>
        <LayoutProvider>
          <PageProvider>
            <DataProvider>{children}</DataProvider>
          </PageProvider>
        </LayoutProvider>
      </ToastProvider>
    </LoaderProvider>
  </ThemeProvider>
);
