import React from 'react';
import 'ag-charts-enterprise';

import { Gauge, GaugeProps } from './Gauge';

export type FearGreenIndexGaugeProps = Omit<
  GaugeProps,
  'segments' | 'colors' | 'labels'
>;

export const FearGreedIndexGauge: React.FC<FearGreenIndexGaugeProps> = (
  props: FearGreenIndexGaugeProps
) => {
  const segments = [13, 36, 63, 85];
  const colors = [
    { color: '#FF4B4B', stop: 18 },
    { color: '#FF8C42', stop: 40 },
    { color: '#32CD32', stop: 65 },
    { color: '#228B22', stop: 100 },
  ];
  const labels = [
    { label: 'Extreme Fear', stop: 24 },
    { label: 'Fear', stop: 49 },
    { label: 'Greed', stop: 74 },
    { label: 'Extreme Greed', stop: 100 },
  ];

  return (
    <Gauge segments={segments} colors={colors} labels={labels} {...props} />
  );
};
