import React from 'react';
import { Button, Typography } from '@mui/material';

import { Modal } from './Modal';

interface ConfirmSyncDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  serviceName?: string;
}

export const ConfirmSyncDialog: React.FC<ConfirmSyncDialogProps> = ({
  open,
  onClose,
  onConfirm,
  serviceName,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Confirm Force Sync"
      actions={
        <>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="error" autoFocus>
            Sync
          </Button>
        </>
      }
    >
      <Typography id="sync-dialog-description">
        Are you sure you want to force sync{' '}
        {serviceName ? `the service "${serviceName}"` : 'the API'}?
      </Typography>
    </Modal>
  );
};
