import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { Text } from '../display/Text';
import { Button } from '../controls/Button';

export type CompletePromptDialogProps = {
  open: boolean;
  entityName: string;
  title?: string;
  description?: string;
  completeButton?: string;
  onClose: () => void;
  onComplete: () => void;
};

export const CompletePromptDialog: React.FC<CompletePromptDialogProps> = ({
  open,
  entityName,
  title,
  description,
  completeButton,
  onClose,
  onComplete,
}) => (
  <Dialog open={open} onClose={onClose as any}>
    <DialogTitle id="delete-dialog-title">
      {title ?? 'Confirm Delete'}
    </DialogTitle>
    <DialogContent>
      <Text id="complete-dialog-description">
        {description ??
          `Are you sure you want to mark ${entityName} as completed?`}
      </Text>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose as any}>Cancel</Button>
      <Button onClick={onComplete as any} autoFocus>
        {completeButton ?? 'Complete'}
      </Button>
    </DialogActions>
  </Dialog>
);
