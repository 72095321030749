import React, { useMemo } from 'react';
import { AgChartOptions } from 'ag-charts-community';
import { Box } from '@mui/material';
import { AgCharts } from 'ag-charts-react';

import {
  getTimeRangeLabelFormatter,
  TimeRangeType,
  valueLabelFormatter,
} from '../../entity';
import { ElementProps, withProps } from '../../entity/components';

export type BalanceChartProps = ElementProps & {
  data: any[];
  range: TimeRangeType;
  xDataKey?: string;
  yDataKey?: string;
  xTickFormatter?: (time: number) => string;
  yTickFormatter?: (value: number) => string;
  lineColor?: string;
  height?: number;
};

export const BalanceChart: React.FC<BalanceChartProps> = ({
  data,
  xDataKey = 'time',
  yDataKey = 'value',
  range,
  xTickFormatter,
  yTickFormatter,
  lineColor = '#8884d8',
  height = 200,
  ...props
}) => {
  const _xTickFormatter = xTickFormatter ?? getTimeRangeLabelFormatter(range);
  const _yTickFormatter = yTickFormatter ?? valueLabelFormatter;

  const options: AgChartOptions = useMemo(() => {
    return {
      type: 'cartesian',
      data,
      series: [
        {
          type: 'line',
          xKey: xDataKey,
          yKey: yDataKey,
          stroke: lineColor,
          marker: {
            enabled: false,
          },
          tooltip: {
            renderer: (params: any) => {
              const time = _xTickFormatter(params.datum[xDataKey]);
              const value = _yTickFormatter(params.datum[yDataKey]);
              return {
                content: `<b>${time}</b>: ${value}`,
              };
            },
          },
        },
      ],
      axes: [
        {
          type: 'time',
          position: 'bottom',
          label: {
            formatter: (params: any) => _xTickFormatter(params.value),
          },
        },
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: (params: any) => _yTickFormatter(params.value),
          },
        },
      ],
      legend: {
        enabled: false,
      },
    };
  }, [data, xDataKey, yDataKey, xTickFormatter, yTickFormatter, lineColor]);

  const sx = {
    ...props.sx,
    width: '100%',
    height,
    p: 0,
  };

  return (
    <Box {...withProps({ ...props, sx })}>
      <AgCharts options={options} />
    </Box>
  );
};
