import React, { useEffect, useMemo, useState } from 'react';

import { RWA } from '../../entity';
import { listRWA } from '../../services';
import BaseTable, { BaseTableProps } from './BaseTable';
import { ImageTextCell } from './cells/ImageTextCell';
import { AmountCell } from './cells/AmountCell';
import { defaultTableQuery } from '../../domain/default';
import { useData } from '../../contexts/DataContext';

export const RWATable: React.FC<BaseTableProps> = (props) => {
  const { assets } = useData();
  const [data, setData] = useState<RWA[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams();
      params.append('size', defaultTableQuery.size);
      params.append('page', defaultTableQuery.page);
      const data = await listRWA(params.toString());
      setData(data.data);
    };

    fetchData();
  }, []);

  const rows = useMemo(
    () =>
      data.map((item) => {
        const asset = assets.find((a) => a.symbol === item.symbol);
        const link = asset?.url;
        return { ...item, link };
      }),
    [data]
  );

  const columnDefs = [
    {
      headerName: 'Price',
      field: 'price',
      cellRenderer: 'amount',
      cellRendererParams: {
        suffix: '$',
      },
    },
    {
      headerName: 'Rank',
      field: 'rank',
      minWidth: 100,
    },
    {
      headerName: 'Name',
      field: 'name',
      cellRenderer: 'imageText',
      cellRendererParams: {
        linkFieldName: 'link',
        srcFieldName: 'icon_url',
      },
      minWidth: 250,
    },
    {
      headerName: 'Symbol',
      field: 'symbol',
    },
    {
      headerName: 'Δ 24H',
      field: 'change24h',
      cellRenderer: 'valueChange',
      cellRendererParams: {
        suffix: '%',
      },
    },
    {
      headerName: 'Δ 7D',
      field: 'change7d',
      cellRenderer: 'valueChange',
      cellRendererParams: {
        suffix: '%',
      },
    },
    {
      headerName: 'Δ 30D',
      field: 'change30d',
      cellRenderer: 'valueChange',
      cellRendererParams: {
        suffix: '%',
      },
    },
    {
      headerName: 'Market Cap',
      field: 'market_cap',
      cellRenderer: 'amount',
      cellRendererParams: {
        prefix: '$',
      },
      minWidth: 150,
    },
    {
      headerName: 'Volume',
      field: 'volume',
      cellRenderer: 'amount',
      cellRendererParams: {
        prefix: '$',
      },
      minWidth: 150,
    },
    {
      headerName: 'ATH',
      field: 'ath',
      cellRenderer: 'amount',
      cellRendererParams: {
        suffix: '$',
      },
    },
    {
      headerName: 'Pct ATH',
      field: 'pct_ath',
      cellRenderer: 'amount',
      cellRendererParams: {
        suffix: '%',
      },
    },
  ].map((col) => ({ ...col, minWidth: col.minWidth ?? 130 }));

  return (
    <BaseTable
      rowData={rows}
      columnDefs={columnDefs}
      searchFields={['name', 'symbol']}
      components={{ imageText: ImageTextCell, amount: AmountCell }}
      gridOptions={{ autoSizeStrategy: { type: 'fitGridWidth' } }}
      {...props}
      title={props.title ?? 'RWA'}
    />
  );
};

export default RWATable;
