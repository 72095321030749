import React, { useEffect, useMemo, useState } from 'react';

import { NFT } from '../../entity';
import { listNFT } from '../../services';
import BaseTable, { BaseTableProps } from './BaseTable';
import { ImageTextCell } from './cells/ImageTextCell';
import { AmountCell } from './cells/AmountCell';
import { defaultTableQuery } from '../../domain/default';
import { useData } from '../../contexts/DataContext';

export const NFTTable: React.FC<BaseTableProps> = (props) => {
  const { assets } = useData();
  const [data, setData] = useState<NFT[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams();
      params.append('size', defaultTableQuery.size);
      params.append('page', defaultTableQuery.page);
      const data = await listNFT(params.toString());
      setData(data.data);
    };

    fetchData();
  }, []);

  const rows = useMemo(
    () =>
      data.map((item) => {
        const asset = assets.find((a) => a.name === item.contractName);
        const link = asset?.url;
        return { ...item, link };
      }),
    [data]
  );

  const columnDefs = [
    {
      headerName: 'Rank',
      field: 'rank',
      maxWidth: 100,
    },
    {
      headerName: 'Token',
      field: 'contractName',
      cellRenderer: 'imageText',
      cellRendererParams: {
        linkFieldName: 'link',
        srcFieldName: 'iconUrl',
      },
      minWidth: 300,
    },
    {
      headerName: 'Platform Name',
      field: 'platformName',
      minWidth: 200,
    },
    {
      headerName: 'Value',
      field: 'valueUSD',
      cellRenderer: 'amount',
      cellRendererParams: {
        prefix: '$',
      },
      minWidth: 150,
    },
    {
      headerName: 'Buyers',
      field: 'buyers',
    },
    {
      headerName: 'Sellers',
      field: 'sellers',
    },
    {
      headerName: 'Owners',
      field: 'owners',
    },
    {
      headerName: 'Txs',
      field: 'transactions',
    },
    {
      headerName: 'Δ Value',
      field: 'changeInValueUSD',
      cellRenderer: 'valueChange',
      cellRendererParams: {
        suffix: '%',
      },
    },
    {
      headerName: 'Previous Value',
      field: 'previousValueUSD',
      cellRenderer: 'amount',
      cellRendererParams: {
        prefix: '$',
      },
      minWidth: 160,
    },
    {
      headerName: 'Δ Buyers',
      field: 'changeInBuyers',
    },
    {
      headerName: 'Δ Sellers',
      field: 'changeInSellers',
    },
    {
      headerName: 'Δ Txs',
      field: 'changeInTransactions',
    },
  ].map((col) => ({ ...col, minWidth: col.minWidth ?? 130 }));

  return (
    <BaseTable
      rowData={rows}
      columnDefs={columnDefs}
      searchFields={['contractName', 'platformName']}
      components={{ imageText: ImageTextCell, amount: AmountCell }}
      gridOptions={{ autoSizeStrategy: { type: 'fitGridWidth' } }}
      {...props}
      title={props.title ?? 'NFT'}
    />
  );
};

export default NFTTable;
