import React, { useEffect, useMemo, useState } from 'react';

import BaseTable, { BaseTableProps } from './BaseTable';
import { FundIcon, QuestionCircleIcon } from '../icons';
import { listFundraising } from '../../services';
import { Fundraising } from '../../entity';
import { timestampToDateStr } from '../../utils/date';
import { capitalize } from '../../utils/text';
import { InvestorsCell } from './cells/InvestorsCell';

export type FundraisingTableProps = BaseTableProps;

export const FundraisingTable: React.FC<FundraisingTableProps> = (props) => {
  const [data, setData] = useState<Fundraising[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams();
      params.append('size', '100');
      params.append('page', '1');
      const fundraisingList = await listFundraising(params.toString());
      setData(fundraisingList.data);
    };

    fetchData();
  }, []);

  const rows = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        funddate: timestampToDateStr(item.funddate),
        projectIconUrl: item.icon() ?? QuestionCircleIcon,
        investors: item.investorlist.map((i) => ({
          iconUrl: i.icon(),
          name: i.investorname,
          url: i.url(),
        })),
        investorCount: item.investorcount,
        category: item.categorylist.map((c) => capitalize(c.name)).join(', '),
      })),
    [data]
  );

  const columnDefs = [
    { headerName: 'Fund Date', field: 'funddate', minWidth: 170 },
    {
      headerName: 'Project',
      field: 'projectname',
      cellRenderer: 'imageText',
      cellRendererParams: { srcFieldName: 'projectIconUrl' },
      minWidth: 200,
    },
    { headerName: 'Category', field: 'category' },
    { headerName: 'Funding Round', field: 'fundstagename', minWidth: 200 },
    { headerName: 'Amount', field: 'fundamount' },
    {
      headerName: 'Investors',
      field: 'investors',
      cellRenderer: 'investorsCell',
      minWidth: 600,
    },
    { headerName: 'Investors Count', field: 'investorCount', minWidth: 170 },
    { headerName: 'Valuation', field: 'valulation' },
  ].map((col) => ({ ...col, minWidth: col.minWidth ?? 150 }));

  return (
    <BaseTable
      rowData={rows}
      columnDefs={columnDefs}
      title={props.title ?? 'Fundraising'}
      Icon={FundIcon}
      components={{ investorsCell: InvestorsCell }}
      searchFields={[
        'funddate',
        'projectname',
        'category',
        'fundstagename',
        'fundamount',
        'investorCount',
        'valulation',
      ]}
      {...props}
    />
  );
};

export default FundraisingTable;
