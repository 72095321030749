import React from 'react';
import QuestionCircleIcon from '@mui/icons-material/HelpOutline';

import { Img, ImgProps } from './Img';
import { RoundImg } from './RoundImg';
import { withProps } from '../../entity/components';

export const OptionalImg: React.FC<
  Partial<ImgProps> & { round?: boolean; color?: any }
> = ({ round = true, ...props }) => {
  const Image = round ? RoundImg : Img;

  return props.src ? (
    <Image {...withProps(props)} />
  ) : (
    <QuestionCircleIcon color={props.color ?? 'info'} {...withProps(props)} />
  );
};
