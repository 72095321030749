import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

import { ElementProps, withProps } from '../../entity/components';

export type TextProps = TypographyProps & ElementProps;

export const Text: React.FC<TextProps> = ({ children, ...props }) => {
  const sx = {
    color: 'text.primary',
    fontSize: '1rem',
    ...props,
    ...(props.sx ?? {}),
  };
  return <Typography {...withProps({ ...props, sx })}>{children}</Typography>;
};
