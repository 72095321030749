import React, { useMemo } from 'react';

import { Alert, AlertType, AlertWithIcon } from '../../entity';
import { timestampToDateTimeStr } from '../../utils/date';
import { InfoList, InfoListEntry } from '../containers/InfoList';
import { ProgressClockIcon } from '../icons';
import { ActionButtons } from '../controls/ActionButtons';
import { Card } from './Card';
import { Text } from '../display/Text';
import { OptionalImg } from '../display/OptionalImg';
import { FlexBox } from '../containers/FlexBox';
import { ElementProps } from '../../entity/components';
import { normalizeValue } from '../../utils/amount';

export type AlertCardProps = ElementProps & {
  data: AlertWithIcon;
  onEdit: (alert: Alert) => void;
  onDelete: (alert: Alert) => void;
};

export const AlertCard: React.FC<AlertCardProps> = ({
  data,
  onEdit,
  onDelete,
  ...props
}) => {
  const infoList: InfoListEntry[] = [];

  if (data.type === AlertType.PRICE) {
    infoList.push({ key: 'Pair', value: `${data!.symbol} / USDT` });
    infoList.push({
      key: 'Current Price',
      value: `${normalizeValue(data!.currentPrice)} $`,
    });
    infoList.push({
      key: 'Thresholds',
      value: `${normalizeValue(data!.thresholds!.min)} $ - ${normalizeValue(data!.thresholds!.max)} $`,
    });
  } else {
    infoList.push({ key: 'Time', value: timestampToDateTimeStr(data!.time!) });
    infoList.push({ key: 'Description', value: data!.description });
  }

  const iconStyles = { w: 32, h: 32, mr: 1 };
  const sx = useMemo(() => {
    const base = { position: 'relative', ...props.sx };
    const backgroundColor = data?.color();
    return backgroundColor ? { ...base, backgroundColor } : base;
  }, [data, props]);

  return (
    <Card {...props} sx={sx}>
      <FlexBox alignItems="center" mb={4}>
        {data.type === AlertType.TIME ? (
          <ProgressClockIcon sx={iconStyles} color="info" />
        ) : (
          <OptionalImg src={data.iconUrl} sx={iconStyles} color="info" />
        )}
        <Text variant="subtitle2" bold>
          {data.name}
        </Text>
      </FlexBox>
      <FlexBox>
        <InfoList data={infoList} valueColor={false} p={0} />
      </FlexBox>
      <ActionButtons
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
        mt={4}
        p={0}
      />
    </Card>
  );
};
