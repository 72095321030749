import React, { useEffect, useMemo, useState } from 'react';

import { BalanceQuery, Earn, HoldingType, Wallet } from '../../entity';
import {
  MultiSelectTransformer,
  MultiSelectTransformerProps,
  Option,
} from './MultiSelectTransformer';

export type PortfolioSelectProps = Omit<
  MultiSelectTransformerProps,
  'onChange' | 'value' | 'options'
> & {
  wallets: Wallet[];
  earns: Earn[];
  onChange: (data: BalanceQuery) => void;
};

export const PortfolioSelect: React.FC<PortfolioSelectProps> = ({
  onChange,
  wallets,
  earns,
  ...props
}) => {
  // State variables for each filter level
  const [selectedHolding, setSelectedHolding] = useState<string | number>(
    'all'
  );
  const [selectedWallet, setSelectedWallet] = useState<string | number>(
    'all-wallets'
  );
  const [selectedEarn, setSelectedEarn] = useState<string | number>(
    'all-earns'
  );
  const [selectedAccount, setSelectedAccount] = useState<string | number>(
    'all-accounts'
  );
  const [selectedAsset, setSelectedAsset] = useState<string | number>(
    'all-assets'
  );

  // Handler functions to reset dependent selections
  const handleSetSelectedHolding = (newValue: string | number) => {
    setSelectedHolding(newValue);
    setSelectedWallet('all-wallets');
    setSelectedEarn('all-earns');
    setSelectedAccount('all-accounts');
    setSelectedAsset('all-assets');
  };

  const handleSetSelectedWallet = (newValue: string | number) => {
    setSelectedWallet(newValue);
    setSelectedAccount('all-accounts');
    setSelectedAsset('all-assets');
  };

  const handleSetSelectedEarn = (newValue: string | number) => {
    setSelectedEarn(newValue);
    setSelectedAsset('all-assets');
  };

  const handleSetSelectedAccount = (newValue: string | number) => {
    setSelectedAccount(newValue);
    setSelectedAsset('all-assets');
  };

  const handleSetSelectedAsset = (newValue: string | number) => {
    setSelectedAsset(newValue);
  };

  // Build the nested MultiSelectTransformerProps tree
  const topLevelFilter: MultiSelectTransformerProps = useMemo(() => {
    const topLevelOptions: Option[] = [
      { value: 'all', label: 'All Holdings' },
      { value: 'wallets', label: 'Wallets' },
      { value: 'earns', label: 'Earns' },
    ];

    const filter: MultiSelectTransformerProps = {
      value: selectedHolding,
      label: 'Holding',
      onChange: handleSetSelectedHolding,
      options: topLevelOptions,
      inner: [],
    };

    if (selectedHolding === 'wallets') {
      const walletOptions = wallets.map((w) => ({
        value: w.id,
        label: w.name,
      }));
      const walletFilter: MultiSelectTransformerProps = {
        value: selectedWallet,
        label: 'Wallet',
        onChange: handleSetSelectedWallet,
        options: [
          { value: 'all-wallets', label: 'All Wallets' },
          ...walletOptions,
        ],
        inner: [],
      };

      if (selectedWallet !== 'all-wallets') {
        const selectedWalletObject = wallets.find(
          (w) => w.id === selectedWallet
        );
        const accountOptions =
          selectedWalletObject?.accounts.map((acc) => ({
            value: acc.id,
            label: `${acc.name}${acc.chain ? ` (${acc.chain})` : ''}`,
          })) || [];

        const accountFilter: MultiSelectTransformerProps = {
          value: selectedAccount,
          label: 'Account',
          onChange: handleSetSelectedAccount,
          options: [
            { value: 'all-accounts', label: 'All Accounts' },
            ...accountOptions,
          ],
          inner: [],
        };

        if (selectedAccount !== 'all-accounts') {
          const selectedAccountObject = selectedWalletObject?.accounts.find(
            (acc) => acc.id === selectedAccount
          );
          const assetOptions =
            selectedAccountObject?.assets
              .filter((a) => a.balanceTrackable)
              .map((asset) => ({
                value: asset.id,
                label: `${asset.name} (${asset.symbol})`,
              })) || [];

          const assetFilter: MultiSelectTransformerProps = {
            value: selectedAsset,
            label: 'Asset',
            onChange: handleSetSelectedAsset,
            options: [
              { value: 'all-assets', label: 'All Assets' },
              ...assetOptions,
            ],
          };

          accountFilter.inner = [assetFilter];
        }

        walletFilter.inner = [accountFilter];
      }

      filter.inner = [walletFilter];
    } else if (selectedHolding === 'earns') {
      const earnOptions = earns.map((e) => ({ value: e.id, label: e.name }));
      const earnFilter: MultiSelectTransformerProps = {
        value: selectedEarn,
        label: 'Earn',
        onChange: handleSetSelectedEarn,
        options: [{ value: 'all-earns', label: 'All Earns' }, ...earnOptions],
        inner: [],
      };
      filter.inner = [earnFilter];
    }

    return { ...props, ...filter };
  }, [
    selectedHolding,
    selectedWallet,
    selectedEarn,
    selectedAccount,
    selectedAsset,
    wallets,
    earns,
  ]);

  useEffect(
    () => onChange(handleChange()),
    [
      selectedHolding,
      selectedWallet,
      selectedEarn,
      selectedAccount,
      selectedAsset,
      wallets,
      earns,
    ]
  );

  const handleChange = (): BalanceQuery => {
    if (selectedHolding === HoldingType.ALL) {
      return {
        holding: HoldingType.ALL,
      };
    }

    if (selectedHolding === 'wallets') {
      if (selectedWallet === 'all-wallets') {
        return {
          holding: HoldingType.WALLETS,
        };
      }

      const selectedWalletObject = wallets.find((w) => w.id === selectedWallet);
      if (!selectedWalletObject) {
        return {
          holding: HoldingType.WALLETS,
        };
      }

      if (selectedAccount === 'all-accounts') {
        return {
          holding: HoldingType.WALLETS,
          walletId: selectedWalletObject.id,
        };
      }

      const selectedAccountObject = selectedWalletObject.accounts.find(
        (acc) => acc.id === selectedAccount
      );
      if (!selectedAccountObject) {
        return {
          holding: HoldingType.WALLETS,
          walletId: selectedWalletObject.id,
        };
      }

      if (selectedAsset === 'all-assets') {
        return {
          holding: HoldingType.WALLETS,
          walletId: selectedWalletObject.id,
          accountId: selectedAccountObject.id,
        };
      }

      const selectedAssetObject = selectedAccountObject.assets.find(
        (asset) => asset.id === selectedAsset
      );
      return {
        holding: HoldingType.WALLETS,
        walletId: selectedWalletObject.id,
        accountId: selectedAccountObject.id,
        assetId: selectedAssetObject?.id,
      };
    }

    if (selectedHolding === 'earns') {
      if (selectedEarn === 'all-earns') {
        return {
          holding: HoldingType.EARNS,
        };
      }

      const selectedEarnObject = earns.find((e) => e.id === selectedEarn);
      if (!selectedEarnObject) {
        return {
          holding: HoldingType.EARNS,
        };
      }

      if (selectedAsset === 'all-assets') {
        return {
          holding: HoldingType.EARNS,
          earnId: selectedEarnObject.id,
          accountId: selectedEarnObject.account.id,
        };
      }

      const selectedEarnAsset = selectedEarnObject.account.assets.find(
        (asset) => asset.id === selectedAsset
      );
      return {
        holding: HoldingType.EARNS,
        earnId: selectedEarnObject.id,
        accountId: selectedEarnObject.account.id,
        assetId: selectedEarnAsset?.id,
      };
    }

    return {
      holding: HoldingType.ALL,
    };
  };

  return <MultiSelectTransformer key="multiSelect" {...topLevelFilter} />;
};
