import React from 'react';
import { Box, BoxProps } from '@mui/material';

import { ElementProps, withProps } from '../../entity/components';

export type FlexBoxProps = BoxProps &
  ElementProps & {
    whiteSpace?: React.CSSProperties['whiteSpace'];
    flexWrap?: React.CSSProperties['flexWrap'];
    justifyContent?: React.CSSProperties['justifyContent'];
    alignItems?: React.CSSProperties['alignItems'];
    alignContent?: React.CSSProperties['alignContent'];
    gap?: React.CSSProperties['gap'];
  };

export const FlexBox: React.FC<FlexBoxProps> = ({
  children,
  display = 'flex',
  whiteSpace = 'nowrap',
  flexWrap,
  justifyContent,
  gap,
  alignItems,
  alignContent,
  ...props
}) => {
  return (
    <Box
      display={display}
      whiteSpace={whiteSpace}
      flexWrap={flexWrap}
      justifyContent={props.center ? 'center' : justifyContent}
      alignItems={props.center ? 'center' : alignItems}
      alignContent={alignContent}
      gap={gap}
      {...withProps({ ...props })}
    >
      {children}
    </Box>
  );
};
