import React from 'react';

import { HeatMap } from '../charts/HeatMap';
import { Marquee } from '../charts/Marquee';
import { ElementProps, withProps } from '../../entity/components';
import { FlexBox } from '../containers/FlexBox';
import { Card } from './Card';

export type MarketMapCardProps = ElementProps & {
  height?: number;
};

export const MarketMapCard: React.FC<MarketMapCardProps> = (props) => {
  const sx = { overflowX: 'hidden', ...props.sx };
  return (
    <Card column fullWidth {...withProps({ ...props, sx })}>
      <FlexBox sx={{ height: props.height ?? '500px' }}>
        <HeatMap />
      </FlexBox>
      <FlexBox mt={4} sx={{ overflowX: 'hidden' }}>
        <Marquee />
      </FlexBox>
    </Card>
  );
};
