import React from 'react';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { CSSObject, styled, Theme } from '@mui/material/styles';

import { routes } from '../../domain/Routes';
import { usefulLinks } from '../../domain/UsefulLinks';
import { FlexBox } from '../containers/FlexBox';
import { DRAWER_WIDTH } from '../../theme/theme';
import { Text } from '../display/Text';
import { Button } from '../controls/Button';
import { Tooltip } from '../display/Tooltip';
import { useLayout } from '../../contexts/LayoutContext';

const UsefulLinksSection: React.FC = () => {
  const [expanded, setExpanded] = React.useState<{ [key: string]: boolean }>(
    {}
  );

  return (
    <FlexBox
      column
      w={DRAWER_WIDTH}
      sx={{ backgroundColor: '#1976d2', borderTop: '2px solid #334F9C' }}
    >
      {usefulLinks.map((category) => (
        <React.Fragment key={category.name}>
          <Text p={2} pb={1} variant="subtitle2" color="text.white" noWrap>
            {category.name}
          </Text>

          <FlexBox
            p={2}
            pt={0}
            display="grid"
            sx={{
              gap: 1,
              gridTemplateColumns: !expanded[category.name]
                ? 'repeat(3, 1fr)' // 3 items per row when collapsed
                : '1fr', // 1 item per row when expanded
            }}
          >
            {category.links.map(({ name, url, description, Icon }) => (
              <Tooltip key={name} title={`${name}: ${description}`}>
                <Button
                  square
                  color="accent"
                  onClick={() => window.open(url, '_blank')}
                >
                  <Icon w={24} h={24} round size={24} />
                </Button>
              </Tooltip>
            ))}
          </FlexBox>
        </React.Fragment>
      ))}
    </FlexBox>
  );
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  backgroundColor: '#1976d2',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#1976d2',
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('lg')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  backgroundColor: '#1976d2',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => {
  const { isMobile, isTablet } = useLayout();
  const overlayDrawer = isMobile || isTablet;

  return {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    boxSizing: 'border-box',
    ...(overlayDrawer
      ? {
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            backgroundColor: '#1976d2',
            position: 'fixed', // Ensure it overlays the content on small screens
            zIndex: theme.zIndex.drawer + 1, // Ensure it's above the content
          },
        }
      : {
          ...(open
            ? {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
              }
            : {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
              }),
        }),
  };
});

export type DrawerContentProps = {
  open: boolean;
  theme: Theme;
  isMobile: boolean;
  handleDrawerClose: () => void;
  handleItemClick: (path: string) => void;
};

export const DrawerContent = ({
  open,
  theme,
  isMobile,
  handleDrawerClose,
  handleItemClick,
}: DrawerContentProps) => {
  return (
    <>
      {!isMobile && (
        <DrawerHeader sx={{ backgroundColor: '#1976d2', boxShadow: 'none' }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon sx={{ color: '#fff' }} />
            ) : (
              <ChevronLeftIcon sx={{ color: '#fff' }} />
            )}
          </IconButton>
        </DrawerHeader>
      )}
      {!isMobile && <Divider />}
      <List sx={{ backgroundColor: '#1976d2' }}>
        {routes
          .filter((r) => !r.path.includes('login'))
          .map(({ name, path, Icon }) => {
            const iconColor =
              location.pathname === path ? 'accent' : 'accent.contrastText';
            const textColor =
              location.pathname === path ? 'text.accent' : 'text.white';
            return (
              <ListItem key={name} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 36,
                    justifyContent: open ? 'initial' : 'center',
                  }}
                  title={name}
                  onClick={() => handleItemClick(path)}
                >
                  <Icon imgSize="sm" mr={open ? 1 : 'auto'} color={iconColor} />
                  <Text
                    w={open ? 'auto' : 0}
                    sx={{ opacity: open ? 1 : 0 }}
                    variant="subtitle1"
                    color={textColor}
                  >
                    {name}
                  </Text>
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>
      {open && <UsefulLinksSection />}
    </>
  );
};
