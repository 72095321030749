import React, { createContext, useContext, useEffect, useState } from 'react';

interface ContainerContextProps {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const ContainerContext = createContext<ContainerContextProps | undefined>(
  undefined
);

export const ContainerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [_open, _setOpen] = useState<boolean>(() => {
    const savedState = localStorage.getItem('drawer_open');
    return savedState ? JSON.parse(savedState) : true;
  });

  useEffect(() => {
    localStorage.setItem('drawer_open', JSON.stringify(_open));
  }, [_open]);

  const setOpen = (open: boolean) => {
    localStorage.setItem('drawer_open', JSON.stringify(open ?? false));
    _setOpen(open);
  };

  return (
    <ContainerContext.Provider value={{ open: _open, setOpen }}>
      {children}
    </ContainerContext.Provider>
  );
};

export const useContainer = () => {
  const context = useContext(ContainerContext);
  if (!context) {
    throw new Error('useContainer must be used within a ContainerProvider');
  }
  return context;
};
