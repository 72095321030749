import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import { AssetType, ChainInfo, Node } from '../../entity';
import { AutocompleteChain } from '../controls/autocomplete/AutocompleteChain';
import { Select } from '../controls/Select';
import { Input } from '../controls/Input';
import { IconButton } from '../controls/IconButton';
import { FlexBox } from '../containers/FlexBox';
import { Card } from '../cards/Card';

interface ChainInfoFormProps {
  ci: ChainInfo;
  ciIdx: number;
  isMobile?: boolean;
  chains: Node[];
  onInfoChange: (
    infoIndex: number,
    field: string,
    value: string | number
  ) => void;
  onDeleteInfo: (chainInfoIndex: number) => void;
  onChainChange: (ciIdx: number, chain: string) => void;
  onAssetTypeChange: (ciIdx: number, value: string) => void;
}

export const ChainInfoForm: React.FC<ChainInfoFormProps> = ({
  ci,
  ciIdx,
  chains,
  onInfoChange,
  onDeleteInfo,
  onChainChange,
  onAssetTypeChange,
  isMobile,
}) => (
  <Card
    key={ciIdx}
    mt={isMobile ? 1 : 2}
    mb={isMobile ? 1 : 2}
    pl={isMobile ? 1 : 2}
    w="100%"
    p={isMobile ? 1 : 2}
  >
    <FlexBox display="flex" flexDirection="column" w="100%">
      <AutocompleteChain
        items={chains}
        size="small"
        data={(ci.chain as Node) ?? null}
        mb={isMobile ? '6px' : 2}
        mt={0}
        onChange={(n) => onChainChange(ciIdx, n as string)}
      />
      {ci.chain && (
        <Select
          fullWidth
          label="Asset Type"
          mb={isMobile ? '4px' : 1}
          mt={0}
          options={[
            { value: 'token', label: 'Token' },
            { value: 'coin', label: 'Coin' },
          ]}
          value={ci.type === AssetType.COIN ? 'coin' : 'token'}
          onChange={(e) => onAssetTypeChange(ciIdx, e.target.value as string)}
        />
      )}
      {ci.type !== AssetType.COIN && ci.type && (
        <Input
          fullWidth
          label="Token Type"
          value={ci.type.toUpperCase()}
          disabled
          mb={isMobile ? 1 : 2}
          mt={0}
        />
      )}
      <Input
        fullWidth
        label="Address"
        required
        value={ci.address}
        mb={isMobile ? 1 : 2}
        mt={0}
        onChange={(e) => onInfoChange(ciIdx, 'address', e.target.value)}
      />
      <Input
        fullWidth
        required
        label="Decimals"
        type="number"
        value={ci.decimals || 0}
        mb={isMobile ? 0 : 2}
        mt={0}
        onChange={(e) =>
          onInfoChange(ciIdx, 'decimals', parseInt(e.target.value))
        }
      />
    </FlexBox>
    <IconButton
      onClick={() => onDeleteInfo(ciIdx)}
      ml="auto"
      sx={{ display: 'block', height: isMobile ? '36px' : '48px' }}
    >
      <DeleteIcon fontSize={isMobile ? 'small' : 'medium'} />
    </IconButton>
  </Card>
);
