import { Entity } from './entity';

export class Fundraising extends Entity<Fundraising> {
  projectCode: string = '';
  projectname: string = '';
  logo: string = '';
  categorylist: FundraisingCategory[] = [];
  fundcode: string = '';
  fundstagecode: string = '';
  fundstagename: string = '';
  fundamount: number = 0;
  valulation: number = 0;
  funddate: number = 0;
  investorcodes: string = '';
  investornames: string = '';
  investorlogos: string = '';
  investorcount: number = 0;
  investorlist: Investor[] = [];

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'categorylist':
          if (typeof (val as any) === 'string') {
            const json = JSON.parse(val);
            this.categorylist = json.map((item: any) =>
              FundraisingCategory.from(item)
            );
            break;
          }
          this.categorylist = val.map((item: any) =>
            FundraisingCategory.from(item)
          );
          break;
        case 'investorlist':
          if (typeof (val as any) === 'string') {
            const json = JSON.parse(val);
            this.investorlist = json.map((item: any) => Investor.from(item));
            break;
          }
          this.investorlist = val.map((item: any) => Investor.from(item));
          break;
        case 'funddate':
          this.funddate = val < 1000000000000 ? val * 1000 : val;
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  icon() {
    return this.logo ? `https://s1.coincarp.com${this.logo}` : undefined;
  }

  url() {
    return `https://www.coincarp.com/fundraising/${this.fundcode}/`;
  }

  clone(patch?: Partial<Fundraising>): Fundraising {
    return new Fundraising({ ...this, ...patch });
  }

  req(): Fundraising {
    const req = this.clone({});
    req.funddate = Math.round(this.funddate / 1000);
    if (req.categorylist)
      req.categorylist = JSON.stringify(req.categorylist) as any;
    if (req.investorlist)
      req.investorlist = JSON.stringify(req.investorlist) as any;
    return req;
  }
}

export class FundraisingList extends Entity<FundraisingList> {
  data: Fundraising[] = [];
  total = 0;

  constructor(obj: any) {
    super();
    this.data = obj.data?.map((f: any) => Fundraising.from(f)) ?? [];
    this.total = obj.total;
  }

  clone(patch?: Partial<FundraisingList>): FundraisingList {
    return new FundraisingList({ ...this, ...patch });
  }

  req(): FundraisingList {
    const data = this.data.map((f) => Fundraising.from(f)) ?? [];
    return { ...this, data };
  }
}

export class FundraisingCategory extends Entity<FundraisingCategory> {
  code: string = '';
  name: string = '';

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      (this as any)[key] = obj[key];
    });
  }

  clone(patch?: Partial<FundraisingCategory>): FundraisingCategory {
    return new FundraisingCategory({ ...this, ...patch });
  }

  req(): FundraisingCategory {
    return this;
  }
}

export class Investor extends Entity<Investor> {
  investorlogo: string = '';
  investorcode: string = '';
  investorname: string = '';

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      (this as any)[key] = obj[key];
    });
  }

  icon() {
    return this.investorlogo
      ? `https://s1.coincarp.com${this.investorlogo}`
      : undefined;
  }

  url() {
    return `https://www.coincarp.com/investor/${this.investorcode}/`;
  }

  clone(patch?: Partial<Investor>): Investor {
    return new Investor({ ...this, ...patch });
  }

  req(): Investor {
    return this;
  }
}
