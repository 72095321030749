import { Entity } from './entity';
import { Node } from './constants';
import { cloneDeep } from 'lodash';
import { Price } from './market';
import { BalanceQuery, HoldingType } from './query';
import { Wallet } from './wallet';
import { Earn } from './earn';
import { Balance, FinalBalance } from './balance';

export enum AssetType {
  COIN = 'coin',
  ERC20 = 'erc20',
  BEP20 = 'bep20',
  TRC20 = 'trc20',
  XDR = 'xdr',
  SPL = 'spl',
  JETTON = 'jetton',
}

export const AssetTypeMap: { [key in Node]?: AssetType } = {
  [Node.ETHEREUM]: AssetType.ERC20,
  [Node.BINANCE]: AssetType.BEP20,
  [Node.ARBITRUM]: AssetType.ERC20,
  [Node.POLYGON]: AssetType.ERC20,
  [Node.OPTIMISM]: AssetType.ERC20,
  [Node.BASE]: AssetType.ERC20,
  [Node.BLAST]: AssetType.ERC20,
  [Node.TRON]: AssetType.TRC20,
  [Node.COSMOS]: AssetType.XDR,
  [Node.SOLANA]: AssetType.SPL,
  [Node.TON]: AssetType.JETTON,
};

export class ChainInfo extends Entity<ChainInfo> {
  address?: string;
  chain?: string;
  decimals?: number;
  type?: AssetType;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => ((this as any)[key] = obj[key]));
  }

  clone(patch?: Partial<ChainInfo>): ChainInfo {
    return new ChainInfo({ ...this, ...patch });
  }

  req(): ChainInfo {
    return this;
  }
}

export type AssetBalance = {
  asset: Asset;
  balance: Balance;
};

export class Asset extends Entity<Asset> {
  id = '';
  name = '';
  symbol = '';
  balanceTrackable: boolean = false;
  url?: string;
  priceId?: string;
  iconUrl?: string;
  chainInfo?: ChainInfo[];

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'chainInfo':
          this.chainInfo =
            obj.chainInfo?.map((ci: any) => ChainInfo.from(ci)) || undefined;
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Asset>): Asset {
    return new Asset({ ...this, ...patch });
  }

  req(): Asset {
    return this;
  }

  desc(): string {
    return `${this?.name ?? this?.symbol ?? ''} ${this?.symbol ? `(${this.symbol})` : ''}`;
  }

  type() {
    return this.chainInfo && this.chainInfo[0]
      ? this.chainInfo[0].type
      : undefined;
  }

  chain() {
    return this.chainInfo && this.chainInfo[0]
      ? this.chainInfo[0].chain
      : undefined;
  }

  address(chain?: Node) {
    if (chain)
      return this.chainInfo?.find((ci) => ci?.chain === chain)?.address;

    return this.chainInfo && this.chainInfo[0].address
      ? this.chainInfo[0].address
      : undefined;
  }

  findPrice(prices: Price[]): Price | null {
    if (this.priceId) return prices.find((p) => p.id === this.priceId) ?? null;
    return prices.find((p) => p.symbol === this.symbol) ?? null;
  }
}

export class AssetWithPrice extends Asset {
  price?: number;

  constructor(base: Asset, price?: number) {
    super(base);
    this.price = price;
  }

  static sort(items: AssetWithPrice[]): AssetWithPrice[] {
    return items.sort((a, b) => (b?.price ?? 0) - (a?.price ?? 0));
  }
}

export class AssetWithAccountId extends Asset {
  accountId: string;

  constructor(base: Asset, accountId: string) {
    super(base);
    this.accountId = accountId;
  }
}

export type AssetWithAccountIdMap = { [key: string]: AssetWithAccountId };

export class ScamToken extends Entity<ScamToken> {
  address: string = '';
  chain: string = '';
  checked: boolean = false;
  createdAt: number = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'createdAt':
          this.createdAt = val < 1000000000000 ? val * 1000 : val;
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<ScamToken>): ScamToken {
    return new ScamToken({ ...this, ...patch });
  }

  req(): ScamToken {
    const res = cloneDeep(this);
    res.createdAt = Math.round(this.createdAt / 1000);
    return res;
  }
}

export type PortfolioCoin = {
  symbol: string;
  url?: string;
  iconUrl?: string;
  price: number;
  percent: number;
  amount: number;
  value: number;
};

export const assetsFromWallets = (wallets: Wallet[]) => {
  return wallets.reduce((acc, w) => {
    return [...acc, ...w.assets()];
  }, [] as Asset[]);
};

export const assetsFromEarns = (earns: Earn[]) => {
  return earns.reduce((acc, e) => {
    return [...acc, e.asset()];
  }, [] as Asset[]);
};

export const assetsFromQuery = (
  wallets: Wallet[],
  earns: Earn[],
  q: BalanceQuery
): Asset[] => {
  if (q.holding === HoldingType.WALLETS) {
    if (q.walletId) {
      if (q.accountId) {
        if (q.assetId) {
          const asset = wallets
            .find((w) => w.id === q.walletId)
            ?.accounts.find((acc) => acc.id === q.accountId)
            ?.assets.find((asset) => asset.id === q.assetId);
          return asset ? [asset] : [];
        }

        return (
          wallets
            .find((w) => w.id === q.walletId)
            ?.accounts.find((acc) => acc.id === q.accountId)?.assets ?? []
        );
      }

      return wallets.find((w) => w.id === q.walletId)?.assets() ?? [];
    }

    return assetsFromWallets(wallets);
  }

  if (q.holding === HoldingType.EARNS) {
    if (q.earnId) {
      if (q.assetId) {
        const asset = earns
          .find((e) => e.id === q.earnId)
          ?.account?.assets?.find((asset) => asset.id === q.assetId);
        return asset ? [asset] : [];
      }

      const asset = earns.find((e) => e.id === q.earnId)?.account?.assets?.[0];
      return asset ? [asset] : [];
    }

    return assetsFromEarns(earns);
  }

  const unfiltered = [...assetsFromWallets(wallets), ...assetsFromEarns(earns)];
  return removeAssetDuplicates(unfiltered);
};

export const assetsBalancesFromQuery = (
  q: BalanceQuery,
  unitBalance: FinalBalance,
  wallets: Wallet[],
  earns: Earn[]
): AssetBalance[] => {
  const assets = assetsFromQuery(wallets, earns, q);
  const res: AssetBalance[] = [];

  for (const w of wallets) {
    for (const acc of w.accounts) {
      for (const asset of acc.assets) {
        const assetInQuery = !!assets.find((a) => a.id === asset.id);
        if (!assetInQuery) continue;

        const balance = unitBalance?.data?.[acc.id]?.[asset.id];
        if (!balance) continue;

        res.push({ asset, balance });
      }
    }
  }

  for (const e of earns) {
    const assetInQuery = !!assets.find((a) => a.id === e.account.assets[0].id);
    if (!assetInQuery) continue;

    const balance = unitBalance?.data?.[e.account.id]?.[e.account.assets[0].id];
    if (!balance) continue;

    res.push({ asset: e.account.assets[0], balance });
  }

  return mergeAssetBalance(res);
};

export const mergeAssetBalance = (data: AssetBalance[]) => {
  const map: { [key: string]: AssetBalance } = {};
  for (const ab of data) {
    if (!map[ab.asset.id]) {
      map[ab.asset.id] = ab;
      continue;
    }

    map[ab.asset.id].balance.amount += ab.balance.amount;
    map[ab.asset.id].balance.value += ab.balance.value;
  }
  return Object.values(map);
};

export const assetsCountFromWallets = (wallets: Wallet[]) => {
  return wallets.reduce((acc, w) => {
    return acc + w.assetCount();
  }, 0);
};

export const assetsCountFromEarns = (earns: Earn[]) => {
  return earns.reduce((acc, e) => {
    return acc + e.assetCount();
  }, 0);
};

export const assetsCountFromQuery = (
  wallets: Wallet[],
  earns: Earn[],
  q: BalanceQuery
) => {
  if (q.assetId) return 1;

  if (q.holding === HoldingType.ALL) {
    return assetsCountFromWallets(wallets) + assetsCountFromEarns(earns);
  }

  if (q.holding === HoldingType.WALLETS) {
    if (q.walletId) {
      if (q.accountId) {
        return (
          wallets
            .find((w) => w.id === q.walletId)
            ?.accounts.find((acc) => acc.id === q.accountId)?.assets.length ?? 0
        );
      }
      return wallets.find((w) => w.id === q.walletId)?.assetCount() ?? 0;
    }
    return assetsCountFromWallets(wallets);
  }

  if (q.holding === HoldingType.EARNS) {
    if (q.earnId) {
      return earns.find((e) => e.id === q.earnId)?.assetCount() ?? 0;
    }
    return assetsCountFromEarns(earns);
  }
};

export const removeAssetDuplicates = (assets: Asset[]) => {
  const map = new Map<string, Asset>();
  for (const asset of assets) {
    if (!map.get(asset.id)) map.set(asset.id, asset);
  }

  const res: Asset[] = [];
  for (const asset of map.values()) {
    res.push(asset);
  }
  return res;
};
