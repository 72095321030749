import React, { useMemo } from 'react';

import { Asset, AssetType, AssetTypeMap, Node } from '../../entity';
import { Input } from '../controls/Input';
import { Button } from '../controls/Button';
import { Modal } from '../modals/Modal';
import { ChainInfoForm } from './ChainInfoForm';
import { FlexBox } from '../containers/FlexBox';
import { useLayout } from '../../contexts/LayoutContext';

type AssetFormProps = {
  data: Asset;
  setData: (asset: Asset) => void;
  open: boolean;
  onInfoChange: (
    infoIndex: number,
    field: string,
    value: string | number
  ) => void;
  onAddInfo: () => void;
  onDeleteInfo: (chainInfoIndex: number) => void;
  onClose: () => void;
  onSave: () => void;
};

export const AssetForm: React.FC<AssetFormProps> = (props) => {
  const { isMobile } = useLayout();
  const { data, open, setData, onInfoChange } = props;
  const { onAddInfo, onDeleteInfo, onClose, onSave } = props;
  const chains = useMemo(() => Object.values(Node), []);

  const handleAssetTypeChange = (ciIdx: number, value: string) => {
    const updatedType =
      value === 'coin'
        ? AssetType.COIN
        : AssetTypeMap[data.chainInfo![ciIdx].chain as Node];
    onInfoChange(ciIdx, 'type', updatedType!);
  };

  const handleInfoChainChange = (ciIdx: number, chain: string) => {
    data.chainInfo![ciIdx].chain = chain;
    data.chainInfo![ciIdx].type = AssetType.COIN;
    setData(Asset.from(data));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={data.id ? 'Edit Asset' : 'Add New Asset'}
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSave} variant="contained">
            Save
          </Button>
        </>
      }
    >
      <FlexBox column mt={2} mb={2} pl={isMobile ? 0 : 2} w="100%">
        <FlexBox display="flex" flexDirection="column" w="100%">
          <Input
            fullWidth
            required
            label="Name"
            value={data.name}
            mb={isMobile ? 1 : 2}
            mt={0}
            onChange={(e) => setData(data.clone({ name: e.target.value }))}
          />
          <Input
            fullWidth
            required
            label="Symbol"
            value={data.symbol}
            mb={isMobile ? 1 : 2}
            mt={0}
            onChange={(e) => setData(data.clone({ symbol: e.target.value }))}
          />
          <Input
            fullWidth
            required
            label="CoinGecko Price ID"
            value={data.priceId}
            mb={isMobile ? 1 : 2}
            mt={0}
            onChange={(e) => setData(data.clone({ priceId: e.target.value }))}
          />
          <Input
            fullWidth
            label="URL"
            value={data.url}
            mb={isMobile ? 1 : 2}
            mt={0}
            onChange={(e) => setData(data.clone({ url: e.target.value }))}
          />
          <Input
            fullWidth
            label="Icon URL"
            value={data.iconUrl}
            mb={isMobile ? 1 : 2}
            mt={0}
            onChange={(e) => setData(data.clone({ iconUrl: e.target.value }))}
          />
        </FlexBox>
        {data.chainInfo?.map((ci, ciIdx) => (
          <ChainInfoForm
            key={`${ci.chain}:${ci.address}`}
            ci={ci}
            ciIdx={ciIdx}
            chains={chains}
            onInfoChange={onInfoChange}
            isMobile={isMobile}
            onDeleteInfo={onDeleteInfo}
            onChainChange={handleInfoChainChange}
            onAssetTypeChange={handleAssetTypeChange}
          />
        ))}
        <Button onClick={onAddInfo} mt={isMobile ? 1 : 2}>
          Add Chain Info
        </Button>
      </FlexBox>
    </Modal>
  );
};
