import React from 'react';

import { Media } from '../../entity';
import { Modal } from '../modals/Modal';
import { Input } from '../controls/Input';
import { Button } from '../controls/Button';

export type AddMediaFormProps = {
  media: Media;
  open: boolean;
  isMobile?: boolean;
  set: (media: Media) => void;
  onClose: () => void;
  onSave: () => void;
};

export const AddMediaForm: React.FC<AddMediaFormProps> = ({
  media,
  open,
  isMobile,
  set,
  onClose,
  onSave,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    title="Add New Media"
    actions={
      <>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSave} variant="contained">
          Create
        </Button>
      </>
    }
  >
    <Input
      autoFocus
      required
      label="Name"
      fullWidth
      value={media.name}
      mb={isMobile ? 1 : 2}
      mt={0}
      onChange={(e) => set(media.clone({ name: e.target.value }))}
    />
    <Input
      required
      label="URL"
      type="url"
      fullWidth
      value={media.url}
      mb={isMobile ? 1 : 2}
      mt={0}
      onChange={(e) => set(media.clone({ url: e.target.value }))}
    />
    <Input
      label="Category"
      fullWidth
      value={media.category}
      mb={isMobile ? 1 : 2}
      mt={0}
      onChange={(e) => set(media.clone({ category: e.target.value }))}
    />
    <Input
      label="Language"
      fullWidth
      value={media.language}
      mb={isMobile ? 1 : 2}
      mt={0}
      onChange={(e) => set(media.clone({ language: e.target.value }))}
    />
  </Modal>
);

export type DeleteMediaFormProps = {
  media: Media[];
  open: boolean;
  isMobile?: boolean;
  onDelete: (id: string) => void;
  onClose: () => void;
};

export const DeleteMediaForm: React.FC<DeleteMediaFormProps> = ({
  media,
  open,
  isMobile,
  onDelete,
  onClose,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    title="Delete Media"
    actions={<Button onClick={onClose}>Cancel</Button>}
  >
    {media.map((m) => (
      <Button
        key={m.id}
        fullWidth
        mb={1}
        variant="outlined"
        onClick={() =>
          window.confirm(`Are you sure you want to delete ${m.name}?`) &&
          onDelete(m.id)
        }
      >
        {m.name}
      </Button>
    ))}
  </Modal>
);
