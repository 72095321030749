import React, { useMemo } from 'react';

import {
  Account,
  AssetWithAccountId,
  AssetWithAccountIdMap,
} from '../../entity';
import { ElementProps } from '../../entity/components';
import NotificationBanner from '../display/NotificationBanner';
import { FlexBox } from './FlexBox';
import { markAssetAsBalanceTrackable } from '../../services/assets';
import { usePage } from '../../contexts/PageContext';
import { markAssetAsIgnored } from '../../services/accounts';
import { ImportAssetsModal } from '../modals/ImportAssetsModal';

export type ImportAssetsProps = ElementProps & {
  accounts: Account[];
  onChange: () => void;
};

export const ImportAssets: React.FC<ImportAssetsProps> = ({
  accounts,
  onChange,
  ...props
}) => {
  const { showToast } = usePage();
  const [modalOpen, setModalOpen] = React.useState(false);

  const getAssetWithAccIdMap = (account: Account) =>
    account.assets.reduce((assetsAcc, asset) => {
      const assetWithAccId = new AssetWithAccountId(asset, account.id);
      return { ...assetsAcc, [asset.id]: assetWithAccId };
    }, {} as AssetWithAccountIdMap);

  const assets = useMemo(() => {
    const assetsMap = accounts.reduce((acc, account) => {
      return { ...acc, ...getAssetWithAccIdMap(account) };
    }, {} as AssetWithAccountIdMap);
    return Object.values(assetsMap).filter((asset) => !asset.balanceTrackable);
  }, [accounts]);

  const assetsCount = useMemo(() => assets.length, [assets]);
  if (!assetsCount) return null;

  const handleClick = () => setModalOpen(true);
  const handleImport = (assetId: string) =>
    markAssetAsBalanceTrackable(assetId)
      .then(onChange)
      .catch((err: any) =>
        showToast(`Failed to import asset: ${err.message}`, 'error')
      );
  const handleIgnore = (accId: string, assetId: string) =>
    markAssetAsIgnored(accId, assetId)
      .then(onChange)
      .catch((err: any) =>
        showToast(`Failed to ignore asset: ${err.message}`, 'error')
      );
  const handleModalClose = () => setModalOpen(false);

  const tokenDesc = assetsCount > 1 ? 'tokens' : 'token';
  const description = `${assetsCount} new ${tokenDesc} found in the wallet`;

  return (
    <FlexBox>
      <NotificationBanner
        pointer
        onClick={handleClick}
        description={description}
      />
      <ImportAssetsModal
        open={modalOpen}
        data={assets}
        onImport={handleImport}
        onIgnore={handleIgnore}
        onClose={handleModalClose}
      />
    </FlexBox>
  );
};
