import React from 'react';
import { Tab, TabProps, Tabs as MUITabs, TabsProps } from '@mui/material';

import { ElementProps, withProps } from '../../entity/components';
import { ColorString, getColor } from '../../theme/theme';

export type TabItem = {
  label: React.ReactNode;
  value: any;
  tabProps?: TabProps;
};

export type CustomTabsProps = Omit<TabsProps, 'onChange'> &
  ElementProps & {
    tabs: TabItem[];
    fontColor?: ColorString;
    selectedFontColor?: ColorString;
    value: any;
    onChange: (event: React.SyntheticEvent, newValue: any) => void;
    tabsContainerStyle?: React.CSSProperties;
    tabStyle?: React.CSSProperties;
  };

export const Tabs: React.FC<CustomTabsProps> = ({
  tabs,
  value,
  onChange,
  tabsContainerStyle,
  selectedFontColor,
  tabStyle,
  fontColor,
  ...tabsProps
}) => {
  const sx = {
    overflowX: 'scroll !important',
    ...tabsContainerStyle,
    '.Mui-selected': {
      color: getColor(selectedFontColor ?? 'primary.dark'),
    },
    '.MuiTabs-scroller': {
      overflowX: 'scroll !important',
    },
  };

  tabStyle = { ...tabStyle } as any;

  return (
    <MUITabs
      br={0}
      value={value}
      onChange={onChange}
      {...withProps({ ...tabsProps, sx })}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={tab.label}
          value={tab.value}
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
            color: getColor(fontColor ?? 'text.secondary'),
            ...tabStyle,
          }}
          {...tab.tabProps}
        />
      ))}
    </MUITabs>
  );
};
