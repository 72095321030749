import { TxList } from '../entity';
import { get } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/txs`;

export const listTxs = (query: string) =>
  get<TxList>(`${apiUrl}?${query}`, TxList);
export const listWalletTxs = (query: string, walletId: string) =>
  get<TxList>(`${apiUrl}/wallets/${walletId}?${query}`, TxList);
