import React from 'react';

import { ElementProps, withProps } from '../../entity/components';
import { FlexBox } from '../containers/FlexBox';
import { Text } from './Text';
import { QuestionCircleIcon } from '../icons';
import { Tooltip } from './Tooltip';
import { ProgressBar, ProgressSector } from './ProgressBar';
import { Card } from '../cards/Card';
import { Skeleton } from './Skeleton';

export type ProgressInfoProps = ElementProps & {
  title: string;
  value: number;
  minLabel: string;
  maxLabel: string;
  sectors: ProgressSector[];
  tooltip?: string;
  min?: number;
  max?: number;
  card?: boolean;
};

export const ProgressInfo: React.FC<ProgressInfoProps> = ({
  card,
  min = 0,
  max = 100,
  value,
  tooltip,
  minLabel,
  maxLabel,
  sectors,
  title,
  ...props
}) => {
  const Container = card ? Card : FlexBox;

  return (
    <Container flex justifyContent="space-between" column {...withProps(props)}>
      <FlexBox alignItems="center" mb={1}>
        <Text bold variant="subtitle2" mr={1}>
          {title}
        </Text>
        {tooltip && (
          <Tooltip title={tooltip}>
            <QuestionCircleIcon imgSize="xs" containerSize={16} color="info" />
          </Tooltip>
        )}
      </FlexBox>
      <Skeleton delay={300} wait={!!value && !!max} h="100%">
        <Text variant="h1" bold mt="auto">
          {value} / {max}
        </Text>
        <FlexBox mb={1} alignItems="center" justifyContent="space-between">
          <Text variant="caption">{minLabel}</Text>
          <Text variant="caption">{maxLabel}</Text>
        </FlexBox>
        <ProgressBar value={value} sectors={sectors} />
      </Skeleton>
    </Container>
  );
};
