import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useLoader } from './LoaderContext';
import {
  Activity,
  Asset,
  Earn,
  MarketState,
  Price,
  PricePin,
  Ticker,
  TimeRangeType,
  UnitBalance,
  Wallet,
} from '../entity';
import {
  getMarketState,
  getProfit,
  listActivities,
  listEarns,
  listPricePins,
  listPrices,
  listTickers,
  listWallets,
} from '../services';
import { useToast } from './ToastContext';
import { TotalProfit } from '../entity/profit';
import { listAssets } from '../services/assets';
import { getAllEarnBalances, getAllWalletBalances } from '../services/balances';

type DataContextType = {
  wallets: Wallet[];
  setWallets: (data: Wallet[]) => void;

  assets: Asset[];
  setAssets: (data: Asset[]) => void;

  earns: Earn[];
  setEarns: (data: Earn[]) => void;

  activities: Activity[];
  setActivities: (data: Activity[]) => void;

  marketState: MarketState | null;
  prices: Price[];
  setPrices: (data: Price[]) => void;

  pricePins: PricePin[];
  setPricePins: (data: PricePin[]) => void;
  tickers: Ticker[];

  profit24h: TotalProfit | null;
  walletBalances24h: UnitBalance | null;
  earnBalances24h: UnitBalance | null;

  sync: () => void;
};

const DataContext = createContext<DataContextType | undefined>(undefined);

const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();
  const { showToast } = useToast();

  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [earns, setEarns] = useState<Earn[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [prices, setPrices] = useState<Price[]>([]);
  const [pricePins, setPricePins] = useState<PricePin[]>([]);
  const [profit24h, setProfit24h] = useState<TotalProfit | null>(null);
  const [walletBalances24h, setWalletBalances24h] =
    useState<UnitBalance | null>(null);
  const [earnBalances24h, setEarnBalances24h] = useState<UnitBalance | null>(
    null
  );
  const [tickers, setTickers] = useState<Ticker[]>([]);
  const [marketState, setMarketState] = useState<MarketState | null>(null);

  const load = (loader: boolean = false) => {
    if (loader) showLoader();

    Promise.all([
      listWallets(),
      listAssets(),
      listEarns(),
      listActivities(),
      listPrices(),
      listPricePins(),
      getProfit(TimeRangeType.DAY),
      getAllWalletBalances(TimeRangeType.DAY),
      getAllEarnBalances(TimeRangeType.DAY),
      listTickers(),
      getMarketState(),
    ])
      .then((res) => {
        setWallets(res[0]);
        setAssets(res[1]);
        setEarns(res[2]);
        setActivities(res[3]);
        setPrices(res[4]);
        setPricePins(res[5]);
        setProfit24h(res[6]);
        setWalletBalances24h(res[7]);
        setEarnBalances24h(res[8]);
        setTickers(res[9]);
        setMarketState(res[10]);
      })
      .catch((err) => showToast(`Failed to load data: ${err.message}`, 'error'))
      .finally(hideLoader);
  };

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (!token) return;

    load(true);
    setInterval(load, 300000);
  }, []);

  return (
    <DataContext.Provider
      value={{
        wallets,
        setWallets,
        assets,
        setAssets,
        earns,
        setEarns,
        activities,
        setActivities,
        marketState,
        prices,
        setPrices,
        pricePins,
        setPricePins,
        tickers,
        profit24h,
        walletBalances24h,
        earnBalances24h,
        sync: load,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

const useData = (): DataContextType => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};

export { DataProvider, useData };
