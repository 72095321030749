interface IEntity<T> {
  req(): T;
}

export abstract class Entity<T extends {}> implements IEntity<T> {
  static from<U extends {}>(this: new ({}: any) => U, obj: any): U {
    const instance = new this(obj);
    return (instance as any).clone(obj);
  }

  abstract clone(patch?: Partial<T>): T;

  abstract req(): T;
}
