import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { Text } from '../display/Text';
import { Button } from '../controls/Button';

export type DeletePromptDialogProps = {
  open: boolean;
  entityName: string;
  title?: string;
  description?: string;
  deleteButton?: string;
  onClose: () => void;
  onDelete: () => void;
};

export const DeletePromptDialog: React.FC<DeletePromptDialogProps> = ({
  open,
  entityName,
  title,
  description,
  deleteButton,
  onClose,
  onDelete,
}) => (
  <Dialog open={open} onClose={onClose as any}>
    <DialogTitle id="delete-dialog-title">
      {title ?? 'Confirm Delete'}
    </DialogTitle>
    <DialogContent>
      <Text id="delete-dialog-description">
        {description ?? `Are you sure you want to delete ${entityName}?`}
      </Text>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose as any}>Cancel</Button>
      <Button onClick={onDelete as any} autoFocus>
        {deleteButton ?? 'Delete'}
      </Button>
    </DialogActions>
  </Dialog>
);
