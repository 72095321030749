import React from 'react';

import { NotesPage } from '../pages/Notes/Notes';
import { WalletsPage } from '../pages/Wallets/Wallets';
import { MarketsPage } from '../pages/Markets/Markets';
import { DashboardPage } from '../pages/Dashboard/Dashboard';
import { ActivitiesPage } from '../pages/Activities/Activities';
import { EarnsPage } from '../pages/Earns/Earns';
import { DeFiTrackerPage } from '../pages/DefiTracker/DeFiTracker';
import { AlertsPage } from '../pages/Alerts/Alerts';
import { MediaPage } from '../pages/Media/Media';
import {
  AlertIcon,
  CoinMarketCapIcon,
  DashboardIcon,
  EarnIcon,
  NewsIcon,
  NotionIcon,
  RocketIcon,
  SettingsIcon,
  SignInIcon,
  SvgIconProps,
  UnicornIcon,
  WalletIcon,
} from '../components/icons';
import { SettingsPage } from '../pages/Settings/Settings';
import { SignInPage } from '../pages/SignIn/SignIn';

interface Route {
  name: string;
  path: string;
  component: any;
  Icon: React.FC<SvgIconProps>;
}

export const routes: Route[] = [
  { name: 'SignIn', path: '/login', component: SignInPage, Icon: SignInIcon },
  {
    name: 'Dashboard',
    path: '/',
    component: DashboardPage,
    Icon: DashboardIcon,
  },
  {
    name: 'Markets',
    path: '/markets',
    component: MarketsPage,
    Icon: CoinMarketCapIcon,
  },
  {
    name: 'Wallets',
    path: '/wallets',
    component: WalletsPage,
    Icon: WalletIcon,
  },
  {
    name: 'Activities',
    path: '/activities',
    component: ActivitiesPage,
    Icon: RocketIcon,
  },
  { name: 'Earns', path: '/earns', component: EarnsPage, Icon: EarnIcon },
  { name: 'Alerts', path: '/alerts', component: AlertsPage, Icon: AlertIcon },
  {
    name: 'DefiTracker',
    path: '/defitracker',
    component: DeFiTrackerPage,
    Icon: UnicornIcon,
  },
  { name: 'Media', path: '/media', component: MediaPage, Icon: NewsIcon },
  { name: 'Notes', path: '/notes', component: NotesPage, Icon: NotionIcon },
  {
    name: 'Settings',
    path: '/settings',
    component: SettingsPage,
    Icon: SettingsIcon,
  },
];

export const getRouteByPath = (path: string): Route | undefined => {
  return routes.find((route) => route.path === path);
};
