import React, { useState } from 'react';

import { iso8601ToTimestamp, timestampToIso8601 } from '../../utils/date';
import { Asset, Earn, Node, Price } from '../../entity';
import { AutocompleteChain } from '../controls/autocomplete/AutocompleteChain';
import { AutocompleteAsset } from '../controls/autocomplete/AutocompleteAsset';
import { Modal } from '../modals/Modal';
import { Input } from '../controls/Input';
import { Button } from '../controls/Button';
import { CheckBox } from '../controls/CheckBox';
import { useLayout } from '../../contexts/LayoutContext';
import { usePage } from '../../contexts/PageContext';

type EarnFormProps = {
  data: Earn;
  assets: Asset[];
  prices: Price[];
  open: boolean;
  edit: boolean;
  setData: (earn: Earn) => void;
  onClose: () => void;
  onSave: (earn: Earn) => void;
};

export const EarnForm: React.FC<EarnFormProps> = (props: EarnFormProps) => {
  const { showToast } = usePage();
  const { isMobile } = useLayout();
  const {
    data,
    open,
    edit,
    setData,
    onClose,
    onSave,
    assets: allAssets,
    prices,
  } = props;

  const [balance, setBalance] = useState('');
  const [apr, setApr] = useState('');

  const validateForm = (): boolean => {
    const requiredFields = ['name', 'platformName', 'platformUrl'];

    for (const field of requiredFields) {
      if (!data[field as keyof typeof data]) {
        showToast(`Please fill in the ${field} field`, 'warning');
        return false;
      }
    }

    if (Number.isNaN(Number(balance))) {
      showToast(`Wrong balance value`, 'warning');
      return false;
    }
    if (Number.isNaN(Number(apr))) {
      showToast(`Wrong APR value`, 'warning');
      return false;
    }
    if (!data.account.assets || !data.account.assets.length) {
      showToast(`Please fill in the asset`, 'warning');
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!validateForm()) return;

    data.account.name = `${data.platformName} ${data.name}`;
    data.account.chain = data.chain;
    data.account.address = data.blockchainAddress;

    const updates = Earn.from({
      ...data,
      apr: parseFloat(apr),
      balance: parseFloat(balance),
    });

    setBalance('');
    setApr('');
    onSave(updates);
  };

  const handleClose = () => {
    setBalance('');
    setApr('');
    onClose();
  };

  const chains = Object.values(Node);
  const mb = isMobile ? 1 : 2;

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      title={edit ? 'Edit Earns' : 'Add New Earns'}
      actions={
        <>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} mr={2}>
            {edit ? 'Save' : 'Create'}
          </Button>
        </>
      }
    >
      <Input
        autoFocus
        required
        label="Name"
        fullWidth
        value={data.name || ''}
        mb={mb}
        mt={0}
        onChange={(e) => setData(data.clone({ name: e.target.value }))}
      />
      <Input
        required
        label="Platform Name"
        fullWidth
        value={data.platformName || ''}
        mb={mb}
        mt={0}
        onChange={(e) => setData(data.clone({ platformName: e.target.value }))}
      />
      <Input
        required
        label="Platform URL"
        fullWidth
        value={data.platformUrl || ''}
        mb={1}
        mt={0}
        onChange={(e) => setData(data.clone({ platformUrl: e.target.value }))}
      />
      <AutocompleteChain
        items={chains}
        size="small"
        data={(data.chain as Node) ?? null}
        mt={0}
        mb={isMobile ? '4px' : 2}
        onChange={(n) => setData(data.clone({ chain: n as Node }))}
      />
      <Input
        label="Blockchain Address"
        fullWidth
        value={data.blockchainAddress || ''}
        mb={mb}
        mt={0}
        onChange={(e) =>
          setData(data.clone({ blockchainAddress: e.target.value }))
        }
      />
      <Input
        label="Balance"
        type="text"
        fullWidth
        value={balance}
        mb={mb}
        mt={0}
        onChange={(e) => setBalance(e.target.value)}
      />
      <Input
        label="APR"
        type="text"
        fullWidth
        value={apr}
        mb={mb}
        mt={0}
        onChange={(e) => setApr(e.target.value)}
      />
      <AutocompleteAsset
        items={allAssets}
        label="Asset"
        size="small"
        data={data.account.assets[0] ?? null}
        mb={mb}
        mt={0}
        onChange={(a) =>
          setData(
            data.clone(
              data.setAsset(
                Asset.from(
                  allAssets.find((asset) => asset.id === (a as Asset).id)
                )
              )
            )
          )
        }
        prices={prices}
      />
      <Input
        label="Start Date"
        type="datetime-local"
        fullWidth
        mb={mb}
        mt={0}
        value={
          data.createdAt
            ? timestampToIso8601(data.createdAt)
            : timestampToIso8601(Date.now())
        }
        onChange={(e) =>
          setData(data.clone({ createdAt: iso8601ToTimestamp(e.target.value) }))
        }
      />
      <CheckBox
        label="Locked"
        checked={data.locked || false}
        size={isMobile ? 'small' : 'medium'}
        mt={0}
        onChange={(e) => setData(data.clone({ locked: e.target.checked }))}
      />
      {data.locked && (
        <Input
          label="Unlock Date"
          type="datetime-local"
          fullWidth
          mb={mb}
          mt={0}
          value={
            data.unlockDate
              ? timestampToIso8601(data.unlockDate)
              : timestampToIso8601(Date.now())
          }
          onChange={(e) =>
            setData(
              data.clone({ unlockDate: iso8601ToTimestamp(e.target.value) })
            )
          }
        />
      )}
    </Modal>
  );
};
