import { Asset } from './asset';
import { Entity } from './entity';
import { Account } from './account';
import { daysLeft } from '../utils/date';

export class Earn extends Entity<Earn> {
  id = '';
  name = '';
  chain = '';
  account = Account.from({});
  platformName = '';
  platformUrl = '';
  blockchainAddress = '';
  balance = 0;
  balanceChanges: { change: number; time: number }[] = [];
  apr = 0;
  duration = 0;
  locked = false;
  completed = false;
  unlockDate = 0;
  createdAt = 0;
  updatedAt = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'createdAt':
          this.createdAt = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'updatedAt':
          this.updatedAt = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'unlockDate':
          this.unlockDate = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'account':
          this.account = Account.from(val);
          break;
        case 'balanceChanges':
          this.balanceChanges = val.map(({ time, change }: any) => {
            if (time < 1000000000000) {
              time = time * 1000;
            }
            return { time, change };
          });
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Earn>): Earn {
    return new Earn({ ...this, ...patch });
  }

  req(): Earn {
    this.createdAt = Math.round(this.createdAt / 1000);
    this.updatedAt = Math.round(this.updatedAt / 1000);
    if (this.unlockDate) this.unlockDate = Math.round(this.unlockDate / 1000);
    if (this.duration) this.duration = Math.round(this.duration / 1000);
    this.balanceChanges = this.balanceChanges.map((v) => ({
      ...v,
      time: v.time / 1000,
    }));
    return this;
  }

  currentBalance(): number {
    let currentBalance = this.balance;
    for (const { change } of this.balanceChanges) {
      currentBalance += change;
    }
    return currentBalance;
  }

  asset(): Asset {
    return this.account.assets[0];
  }

  assetCount() {
    return this.account.assets.length;
  }

  setAsset(asset: Asset): Earn {
    if (this.account.assets.length > 0) {
      this.account.assets[0] = Asset.from(asset);
    } else {
      this.account.assets = [Asset.from(asset)];
    }
    return this;
  }

  daysBeforeUnlock() {
    if (!this.locked || this.unlockDate <= 0) {
      return null;
    }
    return daysLeft(this.unlockDate);
  }
}
