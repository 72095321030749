import React, { useMemo } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemText } from '@mui/material';

import { plusSign, valueColor } from '../../utils/amount';
import { Text } from '../display/Text';
import { ElementProps, withProps } from '../../entity/components';
import { useLayout } from '../../contexts/LayoutContext';

export type InfoListEntry = {
  key: string;
  value: string | React.ReactNode | JSX.Element;
  color?: boolean;
};

type InfoListProps = {
  data: InfoListEntry[];
  valueColor?: boolean;
};

export const InfoList: React.FC<InfoListProps & ElementProps> = ({
  data,
  valueColor: _valueColor = true,
  ...props
}) => {
  const { isMobile } = useLayout();

  const keysWidth = useMemo(() => {
    if (isMobile) return '45vw';

    const maxKeyLen = data.reduce(
      (acc, cur) => (acc > cur.key.length ? acc : cur.key.length),
      0
    );
    return maxKeyLen * 10;
  }, [data]);

  const keyItemSx = { width: `${keysWidth}px`, maxWidth: `${keysWidth}px` };
  const valueItemSx = {
    marginLeft: isMobile ? 4 : 0,
    display: 'flex',
    justifyContent: 'end',
  };

  const cssColor = (value: any, color?: boolean) =>
    _valueColor && (color === true || color === undefined)
      ? valueColor(value)
      : 'inherit';

  const plus = (value: any, color?: boolean) =>
    _valueColor && (color === true || color === undefined)
      ? plusSign(value)
      : '';

  const renderValue = (value: any, color?: boolean) =>
    typeof value !== 'string' ? (
      value
    ) : (
      <Text variant="body2" color={cssColor(value, color)} ellipsis>
        {plus(value, color)}
        {value}
      </Text>
    );

  return (
    <List column {...withProps({ ...props, w: '100%' })}>
      {data.map(({ key, value, color }) => (
        <ListItem key={key} disablePadding>
          <ListItemText sx={keyItemSx}>
            <Text
              className="class"
              variant="body2"
              fontWeight={600}
              textTransform="uppercase"
              ellipsis
            >
              {key}
            </Text>
          </ListItemText>
          <ListItemText sx={valueItemSx}>
            {renderValue(value, color)}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};
