import React, { useEffect, useMemo, useState } from 'react';

import { Node, TimeRange, timeRanges, UnitBalance, Wallet } from '../../entity';
import { InfoList, InfoListEntry } from '../containers/InfoList';
import { capitalize, ellipsis } from '../../utils/text';
import { walletIcon } from '../../utils/icons';
import {
  MultiSelectTransformer,
  MultiSelectTransformerProps,
  Option,
} from '../controls/MultiSelectTransformer';
import { Select, SelectOption } from '../controls/Select';
import { ActionButtons } from '../controls/ActionButtons';
import { Text } from '../display/Text';
import { Card } from './Card';
import { FlexBox } from '../containers/FlexBox';
import { ElementProps, withProps } from '../../entity/components';
import {
  getAccountBalances,
  getAssetBalances,
  getWalletBalances,
} from '../../services/balances';
import { normalizeValue } from '../../utils/amount';
import { nodeIcon } from '../icons/chains';
import { HoldingCharts } from '../charts/HoldingCharts';
import { getDeFiTrackerProfits, getProfit, getWallet } from '../../services';
import { AccountsProfit } from '../../entity/profit';
import { useLayout } from '../../contexts/LayoutContext';
import { ImportAssets } from '../containers/ImportAssets';
import { OptionalImg } from '../display/OptionalImg';
import { cloneDeep } from 'lodash';

export type WalletCardProps = ElementProps & {
  walletId: string;
  defiTracker?: boolean;
  card?: boolean;
  title?: string;
  icon?: React.ReactNode;
  sx?: any;
  onEdit?: (data: Wallet) => void;
  onDelete?: (data: Wallet) => void;
  onBalance?: (id: string, balance: UnitBalance) => void;
};

export const WalletCard: React.FC<WalletCardProps> = ({
  walletId,
  title,
  icon,
  defiTracker,
  onEdit,
  onDelete,
  onBalance,
  card = true,
  ...props
}) => {
  const { isMobile } = useLayout();
  const [wallet, setWallet] = useState<Wallet>(Wallet.from({}));
  const [range, setRange] = useState<TimeRange>(timeRanges[0]);
  const [balance, setBalance] = useState<UnitBalance>(UnitBalance.from({}));
  const [profit, setProfit] = useState<AccountsProfit>(AccountsProfit.from({}));
  const [selectedAccount, setSelectedAccount] = useState<string>('all');
  const [selectedAsset, setSelectedAsset] = useState<string>('all-assets');

  const fetchWallet = () => {
    if (!walletId) return;

    getWallet(walletId).then(setWallet).catch(console.error);
  };

  const fetchOwnProfit = () =>
    getProfit(range.label)
      .then((res) => {
        setProfit(
          res.wallets.wallets.get(wallet.id) ?? AccountsProfit.from({})
        );
      })
      .catch((err) => console.error(`failed to fetch profit: ${err.message}`));

  const fetchDeFiTrackerProfit = () =>
    getDeFiTrackerProfits(range.label)
      .then((res) => {
        setProfit(res.accounts ?? AccountsProfit.from({}));
      })
      .catch((err) => console.error(`failed to fetch profit: ${err.message}`));

  const fetchBalanceProfit = () => {
    if (!walletId) return;

    let balancePromise: Promise<UnitBalance>;
    if (selectedAsset !== 'all-assets' && selectedAccount !== 'all') {
      balancePromise = getAssetBalances(
        range.label,
        selectedAccount,
        selectedAsset
      );
    } else if (selectedAccount !== 'all') {
      balancePromise = getAccountBalances(range.label, selectedAccount);
    } else {
      balancePromise = getWalletBalances(range.label, walletId);
    }

    balancePromise
      .then((res) => {
        setBalance(cloneDeep(res)); // Use cloneDeep to avoid mutating the state
      })
      .catch((err) => console.error(`failed to fetch balance: ${err.message}`));

    if (defiTracker) {
      return fetchDeFiTrackerProfit();
    }
    fetchOwnProfit();
  };

  useEffect(() => {
    fetchWallet();
  }, [walletId]);

  useEffect(() => {
    fetchBalanceProfit();
  }, [range, wallet, selectedAccount, selectedAsset]);

  useEffect(() => {
    if (!!onBalance && !!wallet.id) {
      onBalance(wallet.id, balance);
    }
  }, [balance]);

  const handleSetSelectedAccount = (newValue: any) => {
    setSelectedAccount(newValue);
    setSelectedAsset('all-assets');
  };

  const handleSetSelectedAsset = (newValue: any) => {
    setSelectedAsset(newValue);
  };

  const accountFilter: MultiSelectTransformerProps = useMemo(() => {
    const accountOptions: Option[] = wallet.accounts.map((acc) => ({
      value: acc.id,
      label: `${acc.name} ${acc.chain ? `(${acc.chain})` : ''}`,
    }));

    const filter: MultiSelectTransformerProps = {
      value: selectedAccount,
      onChange: handleSetSelectedAccount,
      renderItem: (opt: SelectOption) => {
        if (opt.value === 'all') return opt.label;
        const account = wallet.accounts?.find((acc) => acc.id === opt.value);
        return (
          <FlexBox>
            {account?.chain ? nodeIcon(account.chain as Node, 'sm', 1) : null}
            <Text variant="body2">{account?.name}</Text>
          </FlexBox>
        );
      },
      options: [{ value: 'all', label: 'All Accounts' }, ...accountOptions],
      inner: [],
    };

    if (selectedAccount !== 'all') {
      const selectedAccountObject = wallet.accounts.find(
        (acc) => acc.id === selectedAccount
      );
      const assetOptions =
        selectedAccountObject?.assets
          .filter((a) => a.balanceTrackable)
          .map((asset) => ({
            value: asset.id,
            label: `${asset.name} (${asset.symbol})`,
          })) || [];

      const assetFilter: MultiSelectTransformerProps = {
        value: selectedAsset,
        onChange: handleSetSelectedAsset,
        renderItem: (opt: SelectOption) => {
          if (opt.value === 'all-assets') return opt.label;
          const asset = selectedAccountObject?.assets?.find(
            (asset) => asset.id === opt.value
          );
          return (
            <FlexBox>
              <OptionalImg imgSize="xs" src={asset?.iconUrl} round mr={1} />
              <Text variant="body2">{asset?.name}</Text>
            </FlexBox>
          );
        },
        options: [
          { value: 'all-assets', label: 'All Assets' },
          ...assetOptions,
        ],
      };

      filter.inner = [assetFilter];
    }

    return filter;
  }, [wallet.accounts, selectedAccount, selectedAsset]);

  const WalletIcon = useMemo(() => {
    return walletIcon(wallet.platform);
  }, [wallet.platform]);

  const profitHistory = useMemo(() => {
    if (!profit) return [];
    if (selectedAccount === 'all') return profit.profit().profits;
    const selectedAccountProfit = profit.accounts?.get(
      selectedAccount as string
    );
    return selectedAccountProfit?.profit().profits ?? [];
  }, [profit, selectedAccount]);

  const infoList = useMemo(() => {
    const acc = wallet.accounts.find((acc) => acc.id === selectedAccount);

    const finalAmount = balance.final.amount
      ? `${normalizeValue(balance.final.amount, 2)}`
      : '--';
    const finalBalance = balance.final.value
      ? `${normalizeValue(balance.final.value, 2)} $`
      : '--';

    const finalProfit =
      profitHistory?.length > 0
        ? profitHistory[profitHistory.length - 1]
        : undefined;

    const profitValue = finalProfit?.profit
      ? normalizeValue(finalProfit?.profit)
      : '--';
    const pnl =
      finalProfit?.dailyProfit !== undefined
        ? normalizeValue(finalProfit?.dailyProfit)
        : '--';

    const platformCmp = !wallet.platform ? null : (
      <FlexBox alignItems="center">
        <Text variant="body2" mr={1} mb="-4px">
          {capitalize(wallet.platform)}
        </Text>
        <WalletIcon imgSize="sm" />
      </FlexBox>
    );

    const res: InfoListEntry[] = [
      { key: 'Platform', value: platformCmp },
      { key: 'Balance', value: finalBalance, color: false },
      { key: 'Profit', value: `${profitValue} $` },
      { key: 'PNL', value: `${pnl} $` },
      { key: 'Tokens Count', value: finalAmount },
    ];

    if (selectedAccount === 'all' || !acc) {
      return res;
    }
    if (acc.address) {
      res.unshift({ key: 'Address', value: ellipsis(acc.address) });
    }
    if (acc.chain) {
      const chainCmp = nodeIcon(acc.chain as Node, 'sm');
      res.unshift({ key: 'Chain', value: chainCmp });
    }
    return res;
  }, [balance, profitHistory, selectedAccount]);

  const handleRangeChange = (e: any) =>
    setRange(timeRanges.find((tr) => tr.value === +e.target.value)!);

  const minHeight = isMobile ? 'auto' : 1200;
  const sx = { ...props.sx, minHeight };
  props = { ...props, sx };

  return (
    <FlexBox component={card ? Card : FlexBox} column {...withProps(props)}>
      <FlexBox alignItems="flex-start" justifyContent="space-between" mb={2}>
        <FlexBox center mb={0}>
          <FlexBox mr={2}>{icon ? icon : <WalletIcon imgSize="md" />}</FlexBox>
          <Text variant="subtitle1">{title ?? wallet.name}</Text>
        </FlexBox>
        <MultiSelectTransformer {...accountFilter} />
      </FlexBox>

      <InfoList data={infoList} />

      <FlexBox mt={1}>
        <ImportAssets accounts={wallet.accounts} onChange={fetchWallet} />
        <Select
          ml="auto"
          size="small"
          options={timeRanges}
          value={`${range.value}`}
          w={100}
          onChange={handleRangeChange}
        />
      </FlexBox>

      <HoldingCharts range={range.label} balance={balance} profit={profit} />

      <ActionButtons
        mt={isMobile ? 2 : 'auto'}
        onEdit={onEdit}
        onDelete={onDelete}
        data={wallet}
      />
    </FlexBox>
  );
};
