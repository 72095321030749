import { normalizeValue } from '../../utils/amount';
import { capitalize } from '../../utils/text';

export const earnTooltip = (params: any): string => {
  const { datum } = params;

  const name = params.xValue || datum.name || ''; // Adjust keys as needed
  const percent = normalizeValue(datum.percent);
  const count = normalizeValue(datum.count);
  const price =
    datum.price !== undefined ? normalizeValue(datum.price) : undefined;
  const value = normalizeValue(datum.value);
  const symbol = datum.symbol;
  const platform = datum.platform;

  let content = `
    <div style="background-color: white; padding: 10px; border-radius: 5px;">
      <div style="font-size: small;">${name}: ${percent} %</div>
      <div style="font-size: small;">Platform: ${capitalize(platform)}</div>
      <div style="font-size: small;">Token: ${symbol}</div>
      <div style="font-size: small;">Tokens Count: ${count}</div>
  `;

  if (price !== undefined) {
    content += `<div style="font-size: small;">Token Price: ${price} $</div>`;
  }

  content += `<div style="font-size: small;">Total Value: ${value} $</div></div>`;
  return content;
};

export const distributionTooltip = (params: any) => {
  const [name, value]: any = Object.values(params.datum);
  const normalizedValue = normalizeValue(value);

  return `<div class="custom-tooltip" style="background-color: white; padding: 10px;">
        <div style="font-size: small;">Name: ${name}</div>
        <div style="font-size: small;">Value: ${normalizedValue} $</div>
      </div>`;
};
