import React, { useMemo } from 'react';

import { timestampToDateStr, timestampToDateTimeStr } from '../../utils/date';
import { Editor } from '../forms/Editor';
import { Activity, ActivityType, Node } from '../../entity';
import { InfoList, InfoListEntry } from '../containers/InfoList';
import { capitalize } from '../../utils/text';
import { tokenIcon } from '../icons';
import { nodeIcon } from '../icons/chains';
import { ActionButtons } from '../controls/ActionButtons';
import { Card } from './Card';
import { Text } from '../display/Text';
import { Link } from '../display/Link';
import { OptionalImg } from '../display/OptionalImg';
import { FlexBox } from '../containers/FlexBox';
import NotificationBanner from '../display/NotificationBanner';
import { normalizeValue } from '../../utils/amount';

const minDaysBeforeFinishBanner = 3;

interface ActivityCardProps {
  data: Activity;
  type: string;
  archived: boolean;
  onDelete: (id: string) => void;
  onEdit: (activity: Activity) => void;
  onComplete: (id: string) => void;
  onUnarchive: (id: string) => void;
  onListChange: (id: string, data: any) => void;
}

export const ActivityCard: React.FC<ActivityCardProps> = (
  props: ActivityCardProps
) => {
  const {
    data,
    type,
    archived,
    onDelete,
    onEdit,
    onComplete,
    onUnarchive,
    onListChange,
  } = props;

  const renderChain = (n: string) => (
    <FlexBox center>
      {nodeIcon(n as Node)}
      <Text variant="body2" sx={{ ml: 1 }}>
        {capitalize(n)}
      </Text>
    </FlexBox>
  );

  const renderAsset = (symbol: string) => (
    <FlexBox alignItems="center" justifyContent="end">
      {tokenIcon(symbol)}
      <Text variant="caption" ml={1}>
        {symbol}
      </Text>
    </FlexBox>
  );

  const list: InfoListEntry[] = [];
  list.push({ key: 'Chain', value: renderChain(data.chain) });
  list.push({ key: 'Platform', value: capitalize(data.platform) });

  if (data.startDate) {
    list.push({ key: 'Start Date', value: timestampToDateStr(data.startDate) });
  }
  if (data.endDate) {
    list.push({ key: 'End Date', value: timestampToDateStr(data.endDate) });
  }
  if (type === ActivityType.DROP) {
    list.push({ key: 'Token Amount', value: `${data.tokenAmount}` });
    if (data.tokenName) {
      list.push({ key: 'Token Name', value: renderAsset(data.tokenName) });
    }
  }
  list.push({
    key: 'Claim Status',
    value: data.claimStatus ? 'Claimed' : 'Not Claimed',
  });
  if (data.blockchainAddress) {
    list.push({ key: 'Blockchain Address', value: data.blockchainAddress });
  }
  if (data.usdSpent > 0) {
    list.push({
      key: 'Spent',
      value: `-${normalizeValue(data.usdSpent)} $`,
    });
  }
  if (data.usdGained > 0) {
    list.push({
      key: 'Gained',
      value: `${normalizeValue(data.usdGained)} $`,
    });
  }
  if (data.usdSpent > 0 && data.usdGained > 0) {
    list.push({
      key: 'Profit',
      value: `${normalizeValue(data.usdGained - data.usdSpent)} $`,
    });
  }

  const daysBeforeFinish = useMemo(() => data.daysBeforeFinish(), [data]);
  console.log(daysBeforeFinish);

  return (
    <Card p={0} column flex sx={{ position: 'relative' }}>
      <FlexBox column>
        <FlexBox mb={2} justifyContent="start" alignItems="start">
          <OptionalImg src={data.iconUrl} color="info" mr={2} imgSize="md" />
          <Text variant="subtitle1" bold>
            {data.url ? (
              <Link variant="subtitle1" bold href={data.url}>
                {data.name}
              </Link>
            ) : (
              data.name
            )}
          </Text>
        </FlexBox>
        <InfoList p={0} mb={1} data={list} />
      </FlexBox>
      <FlexBox
        p={2}
        minHeight={400}
        mt={2}
        mb={2}
        sx={{ backgroundColor: '#eee' }}
      >
        <Text variant="subtitle1" mb={2} fontWeight={600}>
          Tasks
        </Text>
        <Editor id={data.id} onChange={onListChange} value={data.todoList} />
      </FlexBox>

      <FlexBox justifyContent="space-between">
        {!!daysBeforeFinish &&
          daysBeforeFinish <= minDaysBeforeFinishBanner && (
            <NotificationBanner
              mxw={400}
              type="warning"
              description={`Will be unlocked soon: ${timestampToDateTimeStr(data.endDate!)}`}
            />
          )}
        <ActionButtons
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          onArchive={(data: Activity) => onUnarchive(data.id)}
          onComplete={(data: Activity) => onComplete(data.id)}
        />
      </FlexBox>
    </Card>
  );
};
