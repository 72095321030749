import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete as MUIAutocomplete, InputAdornment } from '@mui/material';

import { ListBox, RenderItemParams } from './ListBox';
import { Input } from '../Input';
import { ElementProps, withProps } from '../../../entity/components';
import {
  deleteObjectByKey,
  deletePrimitiveByValue,
} from '../../../utils/array';

export type AutocompleteProps<T> = ElementProps & {
  items: T[];
  data: T | T[] | null;
  onChange: (val: T | T[] | null) => void;
  size?: any;
  label?: any;
  multiselect?: boolean;
  renderSelected: (item: T) => JSX.Element;
  renderItem: (item: T, params: RenderItemParams<T>) => JSX.Element;
  itemKeyName: string;
  filterItems?: (inputValue: string, item: T) => boolean;
  clear?: boolean;
};

export const Autocomplete = React.memo(
  <T extends unknown>({
    items,
    data,
    onChange,
    multiselect = false,
    renderSelected,
    clear,
    renderItem,
    itemKeyName,
    filterItems,
    size,
    label = '',
    ...props
  }: AutocompleteProps<T>) => {
    const [inputValue, setInputValue] = useState<string>('');
    const lastValidData = useRef<T | T[] | null>([] as any); // Start with static data to test
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
      if (data && (!Array.isArray(data) || data.length > 0)) {
        lastValidData.current = data;
      }
    }, [data]);

    const effectiveData = useMemo(() => {
      return data && (!Array.isArray(data) || data.length > 0)
        ? data
        : lastValidData.current;
    }, [data]);

    const filteredItems = useMemo(() => {
      const res = filterItems
        ? items.filter((item) => filterItems(inputValue, item))
        : items;

      if (!Array.isArray(data) || !data.length) return res;

      return res.sort((a: any, b: any) => {
        const aChecked = data.find(
          (d: any) => d[itemKeyName] === a[itemKeyName]
        );
        const bChecked = data.find(
          (d: any) => d[itemKeyName] === b[itemKeyName]
        );

        return aChecked ? -1 : 1;
      });
    }, [items, inputValue, filterItems]);

    const selectedValues = useMemo(() => {
      return effectiveData
        ? Array.isArray(effectiveData)
          ? effectiveData
          : [effectiveData]
        : [];
    }, [effectiveData]);

    const getMUIValue = (val: any) => val?.[itemKeyName] ?? val;

    const muiValue = useMemo(() => {
      return multiselect
        ? selectedValues.map((v) => getMUIValue(v))
        : (getMUIValue(selectedValues[0]) ?? null);
    }, [selectedValues, multiselect]);

    const handleOptChange = useCallback(
      (checked: boolean, option: any) => {
        const isObject = option[itemKeyName];

        const item = isObject
          ? items.find((i: any) => i[itemKeyName] === option[itemKeyName])!
          : items.find((i: any) => i === option)!;

        if (!multiselect) {
          onChange(item!);
          return;
        }

        let selected = Array.isArray(data) ? [...data] : [];
        const elementInSelected = isObject
          ? selected.find((s: any) => s[itemKeyName] === option[itemKeyName])
          : selected.find((s: any) => s === option);

        if (!checked && elementInSelected) {
          selected = isObject
            ? deleteObjectByKey(selected, itemKeyName, option[itemKeyName])
            : deletePrimitiveByValue(selected, option);
        } else {
          selected = [...selected, option];
        }

        onChange(selected);
      },
      [multiselect, onChange]
    );

    const handleOptionClick = useCallback(
      (event: React.MouseEvent, option: T) => {
        event.stopPropagation();
        event.preventDefault();

        if (!option) return;

        if (multiselect) {
          const currentSelection = Array.isArray(data) ? data : [];
          const newSelection = currentSelection.includes(option)
            ? currentSelection.filter((item) => item !== option)
            : [...currentSelection, option];
          onChange(newSelection);
        } else {
          onChange(option);
          setOpen(false);
        }

        setInputValue('');
      },
      [multiselect, data, onChange]
    );

    const inputSelectedComponents = useMemo(
      () =>
        !!selectedValues && selectedValues?.length ? (
          <InputAdornment position="start">
            {selectedValues.map((v) => renderSelected(v))}
          </InputAdornment>
        ) : null,
      [selectedValues]
    );

    return (
      <MUIAutocomplete
        multiple={multiselect}
        options={filteredItems}
        fullWidth
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={(option: any) => option?.[itemKeyName] ?? option}
        disableClearable={!clear}
        disableCloseOnSelect={multiselect}
        value={muiValue}
        inputValue={inputValue}
        size={size}
        renderInput={(params) => (
          <Input
            label={label}
            sx={{ paddingRight: 0 }}
            {...params}
            onClick={() => setOpen(true)}
            InputProps={{
              ...params.InputProps,
              startAdornment: inputSelectedComponents,
            }}
          />
        )}
        onInputChange={(event, newInputValue, reason) => {
          if (reason !== 'reset') setInputValue(newInputValue);
        }}
        ListboxComponent={(props) => (
          <ListBox
            {...props}
            items={filteredItems}
            itemKeyName={itemKeyName}
            multiselect={multiselect}
            renderItem={renderItem}
            selectedValues={selectedValues}
            handleOptionClick={handleOptionClick}
            handleOptionChange={handleOptChange}
          />
        )}
        {...withProps(props)}
      />
    );
  }
) as <T>(props: AutocompleteProps<T>) => JSX.Element;
