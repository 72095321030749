import React from 'react';

import { OptionalImg } from '../../display/OptionalImg';
import { Link } from '../../display/Link';
import { FlexBox } from '../../containers/FlexBox';

export const InvestorsCell = ({ value }: { value: any[] }) => (
  <FlexBox gap={2}>
    {value.map((investor) => (
      <FlexBox key={investor.name} center>
        <OptionalImg imgSize="sm" mr={2} src={investor.iconUrl} />
        <Link variant="cell" href={investor.url}>
          {investor.name}
        </Link>
      </FlexBox>
    ))}
  </FlexBox>
);
