import React from 'react';

import {
  ElementProps,
  imgContainerSizeMap,
  withProps,
} from '../../entity/components';
import { FlexBox } from '../containers/FlexBox';
import { getColor } from '../../theme/theme';

export type RoundButtonProps = ElementProps & {
  Icon: React.ElementType;
  onClick: () => void;
  sx?: any;
};

export const RoundButton: React.FC<RoundButtonProps> = ({
  Icon,
  onClick,
  imgSize,
  ...props
}) => {
  const size = imgContainerSizeMap[imgSize ?? 'md'];
  const sx = {
    backgroundColor: getColor('accent.main'),
    '&:hover': {
      backgroundColor: getColor('accent.dark'),
    },
  };
  const updatedProps = withProps({
    ...props,
    sx,
    round: true,
    w: size,
    h: size,
  });

  return (
    <FlexBox center onClick={onClick} {...updatedProps}>
      <Icon color={'text.white'} imgSize={imgSize ?? 'md'} />
    </FlexBox>
  );
};
