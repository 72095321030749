import React, { useMemo, useState } from 'react';

import { Container } from '../../components/nav/Container';
import { ActionButton } from '../../components/controls/ActionButton';
import { DeletePromptDialog } from '../../components/modals/DeletePromptDialog';
import { defaultEarn } from '../../domain/default';
import { useData } from '../../contexts/DataContext';
import {
  changeEarnBalance,
  completeEarn,
  deleteEarn,
  storeEarn,
  updateEarn,
} from '../../services';
import { Earn, UnitBalance } from '../../entity';
import { EarnCard } from '../../components/cards/EarnCard';
import { DonutChart } from '../../components/charts/DonutChart';
import { usePage } from '../../contexts/PageContext';
import { EarnForm } from '../../components/forms/EarnForm';
import { Card } from '../../components/cards/Card';
import { earnTooltip } from '../../components/charts/tooltips';
import Tiles from '../../components/containers/Tiles';
import { Skeleton } from '../../components/display/Skeleton';
import { Text } from '../../components/display/Text';
import { normalizeValue } from '../../utils/amount';
import { CompletePromptDialog } from '../../components/modals/CompletePromptDialog';
import { ChangeEarnBalanceDialog } from '../../components/modals/ChangeEarnBalanceDialog';

export const EarnsPage: React.FC<void> = () => {
  const { navigate, showToast, isMobile } = usePage();
  const {
    earns: data,
    setEarns: setData,
    assets,
    prices,
    profit24h,
    earnBalances24h,
    sync,
  } = useData();

  const [balances, setBalances] = useState<{ [key: string]: UnitBalance }>({});
  const [active, setActive] = useState<Earn | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openChangeBalanceModal, setOpenChangeBalanceModal] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [openCompleteModal, setOpenCompleteModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [search, __] = useState('');
  const [selected, setSelected] = useState<Earn>(defaultEarn);

  const handleSearch = (data: Earn[]) => {
    const unsorted = data.filter((item) => {
      return item.platformName.toLowerCase().includes(search.toLowerCase());
    });
    return unsorted.sort((e1, e2) => {
      const e1Balance = balances[e1.id];
      const e2Balance = balances[e2.id];
      return (e2Balance?.final?.value ?? 0) - (e1Balance?.final?.value ?? 0);
    });
  };

  const handleSave = (earn: Earn) => {
    if (edit) {
      const updated = earn.clone({ id: active!.id });
      updateEarn(updated.id, updated)
        .then(() => {
          setData(
            data.map((earn) => (earn.id === updated.id ? updated : earn))
          );
          showToast(
            `Earns ${selected.platformName} ${selected.name} successfully updated`,
            'info'
          );
          sync();
        })
        .catch((err: any) =>
          showToast(`Failed to update earn: ${err.message}`, 'error')
        )
        .finally(() => {
          setEdit(false);
          setActive(null);
          setOpenModal(false);
          handleClose();
        });
      return;
    }

    storeEarn(earn)
      .then(() => {
        setData([...data, earn]);
        showToast(
          `Earns ${earn.platformName} ${earn.name} successfully created`,
          'info'
        );
        sync();
      })
      .catch((err: any) =>
        showToast(`Failed to create earn: ${err.message}`, 'error')
      )
      .finally(() => {
        setEdit(false);
        setActive(null);
        setOpenModal(false);
        handleClose();
      });
  };

  const handleDelete = () => {
    const id = active!.id;
    setOpenDelModal(false);

    const delItem = data.find((earn) => earn.id === id)!;
    deleteEarn(id)
      .then(() => {
        setData(data.filter((item) => item.id !== id));
        showToast(
          `Earn ${delItem.platformName} ${delItem.name} successfully deleted`,
          'info'
        );
        sync();
      })
      .catch((err: any) =>
        showToast(`Failed to delete earn: ${err.message}`, 'error')
      )
      .finally(() => setActive(null));
  };

  const handleComplete = () => {
    const id = active!.id;
    setOpenCompleteModal(false);

    const item = data.find((earn) => earn.id === id)!;
    completeEarn(item.id)
      .then(() => {
        showToast(
          `Earn ${item.platformName} ${item.name} successfully completed`,
          'info'
        );
        sync();
      })
      .catch((err: any) =>
        showToast(`Failed to complete earn: ${err.message}`, 'error')
      )
      .finally(() => setActive(null));
  };

  const handleChangeBalance = (change: number) => {
    const id = active!.id;
    setOpenChangeBalanceModal(false);

    const item = data.find((earn) => earn.id === id)!;
    changeEarnBalance(item.id, change)
      .then(() => {
        showToast(
          `Balance for earn ${item.platformName} ${item.name} successfully updated`,
          'info'
        );
        sync();
      })
      .catch((err: any) =>
        showToast(`Failed to update earn balance: ${err.message}`, 'error')
      )
      .finally(() => setActive(null));
  };

  const handleClickOpen = () => {
    setEdit(false);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setEdit(false);
    setSelected(defaultEarn);
  };

  const handleOpenDelModal = (earn: Earn) => {
    setActive(earn);
    setOpenDelModal(true);
  };

  const handleOpenCompleteModal = (earn: Earn) => {
    setActive(earn);
    setOpenCompleteModal(true);
  };

  const handleOpenChangeBalanceModal = (earn: Earn) => {
    setActive(earn);
    setOpenChangeBalanceModal(true);
  };

  const handleCloseDelModal = () => {
    setActive(null);
    setOpenDelModal(false);
  };

  const handleCloseChangeBalanceModal = () => {
    setActive(null);
    setOpenChangeBalanceModal(false);
  };

  const handleEdit = (earn: Earn) => {
    setActive(earn);
    setSelected(earn);
    setEdit(true);
    setOpenModal(true);
  };

  const prepareChart = (data: Earn[], isProfit: boolean = false) => {
    const totalValue = isProfit
      ? (profit24h?.earns?.total() ?? 1)
      : (earnBalances24h?.final.value ?? 1);

    return data.map((item) => {
      const name = item.name;
      const platform = item.platformName;
      const asset = item.account.assets.length ? item.account.assets[0] : null;
      const symbol = asset?.symbol;

      let price;
      let count;
      let value;
      if (isProfit) {
        const arr = profit24h?.earns?.earns?.get(item.id)?.profit().profits;
        price = arr?.[arr?.length - 1]?.endPrice ?? 0;
        count = arr?.[arr?.length - 1]?.endAmount ?? 0;
        value = arr?.[arr?.length - 1]?.endValue ?? 0;
      } else {
        price = balances?.[item.id]?.final.price ?? 0;
        count = balances?.[item.id]?.final.amount ?? 0;
        value = balances?.[item.id]?.final.value ?? 0;
      }

      const percent = (value * 100) / totalValue;

      return { name, platform, symbol, value, price, count, percent };
    });
  };

  const handleEarnBalance = (id: string, balance: UnitBalance) => {
    setBalances((state) => ({ ...state, [id]: balance }));
  };

  const assetsDistribution = useMemo(
    () => prepareChart(data).sort((a1, a2) => a2.value - a1.value),
    [data, balances, earnBalances24h]
  );
  const profitDistribution = useMemo(
    () => prepareChart(data, true).sort((a1, a2) => a2.value - a1.value),
    [data, profit24h]
  );

  const pieChartSize = isMobile ? 350 : 600;
  const renderCustomLabel = ({ symbol, percent }: any) =>
    `${symbol}: ${percent.toFixed(1)}%`;
  const Action = <ActionButton entityName="Earns" onClick={handleClickOpen} />;

  return (
    <Container navigate={navigate} actionComponent={Action}>
      <Tiles>
        <Card
          tileLG={6}
          sx={{ justifyContent: 'space-around' }}
          column
          flex
          mr={4}
          pb={2}
          fullWidth
        >
          <Skeleton fullHeight delay={300} wait={assetsDistribution} h={500}>
            {earnBalances24h && (
              <Text variant="body1" bold>
                Total Balance: {normalizeValue(earnBalances24h.final.value)}$
              </Text>
            )}
            <DonutChart
              mxw={isMobile ? 'auto' : 600}
              h={pieChartSize}
              w={pieChartSize}
              valuePrefix="$"
              data={assetsDistribution}
              customLabel={renderCustomLabel}
              customTooltip={earnTooltip}
              sx={{ margin: isMobile ? 'none' : 'auto' }}
              columnLegend={isMobile}
            />
          </Skeleton>
        </Card>
        <Card tileLG={6} column flex mr={4} pb={2} fullWidth>
          <Skeleton fullHeight delay={300} wait={profitDistribution} h={500}>
            {profit24h && (
              <Text variant="body1" bold>
                Total Profit: {normalizeValue(profit24h.totalEarns())}$
              </Text>
            )}
            <DonutChart
              mxw={isMobile ? 'auto' : 600}
              h={pieChartSize}
              w={pieChartSize}
              valuePrefix="$"
              data={profitDistribution}
              customLabel={renderCustomLabel}
              customTooltip={earnTooltip}
              sx={{ margin: isMobile ? 'none' : 'auto' }}
              columnLegend={isMobile}
            />
          </Skeleton>
        </Card>

        {handleSearch(data).map((item) => (
          <EarnCard
            tileLG={6}
            data={item}
            onChangeBalance={handleOpenChangeBalanceModal}
            onBalance={handleEarnBalance}
            onEdit={handleEdit}
            onDelete={handleOpenDelModal}
            onComplete={handleOpenCompleteModal}
          />
        ))}
      </Tiles>
      {selected && (
        <EarnForm
          data={selected}
          prices={prices}
          assets={assets}
          open={openModal}
          edit={edit}
          setData={setSelected}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
      {selected && (
        <>
          <DeletePromptDialog
            open={openDelModal}
            onClose={handleCloseDelModal}
            onDelete={handleDelete}
            entityName={`${active?.name} (${active?.platformName})`}
          />
          <CompletePromptDialog
            open={openCompleteModal}
            onClose={handleCloseDelModal}
            onComplete={handleComplete}
            entityName={`${selected.name} (${selected.platformName!})`}
          />
          <ChangeEarnBalanceDialog
            open={openChangeBalanceModal}
            onClose={handleCloseChangeBalanceModal}
            onChange={handleChangeBalance}
          />
        </>
      )}
    </Container>
  );
};
