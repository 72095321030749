import { Earn } from '../entity';
import { axiosInstance, del, get, list, post, put } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/earns`;

export const storeEarn = (data: Earn) => post<Earn>(apiUrl, data);
export const listEarns = () => list<Earn>(apiUrl, Earn);
export const updateEarn = (id: string, data: Earn) =>
  put<Earn>(`${apiUrl}/${id}`, data);
export const getEarn = (id: string) => get<Earn>(`${apiUrl}/${id}`, Earn);
export const deleteEarn = (id: string) => del(`${apiUrl}/${id}`);
export const completeEarn = (id: string) =>
  axiosInstance.patch(`${apiUrl}/${id}/complete`);
export const undoCompleteEarn = (id: string) =>
  axiosInstance.patch(`${apiUrl}/${id}/uncomplete`);
export const changeEarnBalance = (id: string, change: number) =>
  axiosInstance.patch(`${apiUrl}/${id}/balance`, { change });
