import React, { useMemo } from 'react';
import { AgCharts } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-enterprise';
import 'ag-charts-enterprise';

import { CHART_PALETTE } from '../../theme/theme';
import { useLayout } from '../../contexts/LayoutContext';
import { ElementProps, withProps } from '../../entity/components';
import { FlexBox } from '../containers/FlexBox';
import { DonutChartLegend } from './DonutChartLegend';

export type DonutChartProps = ElementProps & {
  data: any[];
  valueColor?: string;
  customLabel?: ({ name, percent }: any) => string;
  customTooltip?: (params: any) => string;
  h?: number;
  w?: number;
  valuePrefix?: string;
  hideCenter?: boolean;
  fills?: string[];
  compact?: boolean;
  columnLegend?: boolean;
  totalValueFormatter?: (val: number) => string;
};

export const DonutChart: React.FC<DonutChartProps> = ({
  data,
  hideCenter,
  fills,
  customLabel,
  totalValueFormatter,
  compact,
  customTooltip,
  h,
  w,
  valueColor,
  valuePrefix,
  columnLegend,
  ...props
}) => {
  const { isMobile } = useLayout();

  if (!data || !data.map) return null;
  w = w ?? (isMobile ? 300 : 500);
  h = h ?? (isMobile ? 200 : 500);

  fills = fills?.length
    ? fills
    : data.map((_: any, index: number) => CHART_PALETTE[index]);

  const options: AgChartOptions = useMemo(() => {
    const totalValue = data.reduce((sum, entry) => sum + entry.value, 0);

    const seriesOptions = {
      type: 'donut' as any,
      angleKey: 'value', // Key for slice values
      innerRadiusRatio: 0.6, // Inner radius as a ratio of the outer radius
      fills,
      innerLabels: [
        {
          text: totalValueFormatter
            ? totalValueFormatter(totalValue)
            : `${totalValue}`,
          fontSize: 16,
          fontWeight: 'bold',
        },
      ],
      // Configure tooltip
      tooltip: customTooltip
        ? {
            enabled: true,
            renderer: (params: any) => ({
              content: customTooltip(params),
            }),
          }
        : undefined,
    };

    const options: AgChartOptions = {
      data: data,
      series: [seriesOptions],
      height: 250,
      width: 246,
    };

    return options;
  }, [data, customLabel, customTooltip, h, w]);

  const sx = {
    width: compact ? 'auto' : '100%',
    justifyContent: compact ? 'space-around' : 'space-between',
    ...(props.sx ?? {}),
  };

  return (
    <FlexBox
      center={columnLegend}
      column={columnLegend}
      {...withProps({ ...props, sx })}
    >
      <DonutChartLegend
        mt={4}
        columnLegend={columnLegend}
        prefix={valuePrefix}
        data={data}
        fills={fills!}
      />
      <FlexBox w={250}>
        <AgCharts options={options as any} />
      </FlexBox>
    </FlexBox>
  );
};
