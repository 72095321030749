import { get } from './request';
import { TotalProfit } from '../entity/profit';
import { cfg } from '../config/config';
import { TimeRangeType } from '../entity';

const apiUrl = `${cfg.apiUrl}/api/profits`;

const cache: { [key in TimeRangeType]?: TotalProfit } = {};

export const getProfit = async (range: TimeRangeType) => {
  let res = cache[range];
  if (res) return res;

  res = await get<TotalProfit>(`${apiUrl}?range=${range}`, TotalProfit);
  cache[range] = res;
  return res;
};
