import React from 'react';

import ChainPortLogo from '../../assets/logo/chainport.png';
import LayerZeroLogo from '../../assets/logo/layerzero.png';
import WormholeLogo from '../../assets/logo/wormhole.png';
import SuperBridgeLogo from '../../assets/logo/superbridge.png';
import StargateLogo from '../../assets/logo/stargate.png';
import { ImgProps } from '../display/Img';
import { RoundImg } from '../display/RoundImg';
import { Svg, SvgIconProps } from './common';

export const ChainPortIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={ChainPortLogo} />
);

export const LayerZeroIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={LayerZeroLogo} />
);

export const SuperBridgeIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={SuperBridgeLogo} />
);

export const WormholeIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={WormholeLogo} />
);

export const StargateIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={StargateLogo} />
);

export const OmniBridgeIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 40 26" {...props}>
    <div
      id="in-page-channel-node-id"
      data-channel-name="in_page_channel_V1BSyn"
    />
    <path
      d="M0 1.94197C0 0.170512 2.25019 -0.67249 3.47988 0.638283L18.5201 16.6703C19.3136 17.5161 20.6864 17.5161 21.4799 16.6703L36.5201 0.638285C37.7498 -0.672488 40 0.170512 40 1.94197V22.1239C40 24.2646 38.2091 26 36 26H4C1.79086 26 0 24.2646 0 22.1239V1.94197Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="20"
        y1="0"
        x2="20"
        y2="26"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5C8BE8" />
        <stop offset="1" stopColor="#5CC6E8" />
      </linearGradient>
    </defs>
  </Svg>
);

export const SynapseIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 169 36" {...props}>
    <path
      opacity="0.7"
      d="M20.1456 5L5 18.5H35L20.1456 32"
      stroke="url(#paint0_radial_559_2988)"
      strokeWidth="3"
    ></path>
    <path
      d="M20 10C22.7614 10 25 7.76142 25 5C25 2.23858 22.7614 0 20 0C17.2386 0 15 2.23858 15 5C15 7.76142 17.2386 10 20 10Z"
      fill="black"
    ></path>
    <path
      d="M20 10C22.7614 10 25 7.76142 25 5C25 2.23858 22.7614 0 20 0C17.2386 0 15 2.23858 15 5C15 7.76142 17.2386 10 20 10Z"
      fill="url(#paint1_radial_559_2988)"
    ></path>
    <path
      d="M5 23C7.76142 23 10 20.7614 10 18C10 15.2386 7.76142 13 5 13C2.23858 13 0 15.2386 0 18C0 20.7614 2.23858 23 5 23Z"
      fill="black"
    ></path>
    <path
      d="M5 23C7.76142 23 10 20.7614 10 18C10 15.2386 7.76142 13 5 13C2.23858 13 0 15.2386 0 18C0 20.7614 2.23858 23 5 23Z"
      fill="url(#paint2_radial_559_2988)"
    ></path>
    <path
      d="M20 36C22.7614 36 25 33.7614 25 31C25 28.2386 22.7614 26 20 26C17.2386 26 15 28.2386 15 31C15 33.7614 17.2386 36 20 36Z"
      fill="black"
    ></path>
    <path
      d="M20 36C22.7614 36 25 33.7614 25 31C25 28.2386 22.7614 26 20 26C17.2386 26 15 28.2386 15 31C15 33.7614 17.2386 36 20 36Z"
      fill="url(#paint3_radial_559_2988)"
    ></path>
    <path
      d="M34 23C36.7614 23 39 20.7614 39 18C39 15.2386 36.7614 13 34 13C31.2386 13 29 15.2386 29 18C29 20.7614 31.2386 23 34 23Z"
      fill="black"
    ></path>
    <path
      d="M34 23C36.7614 23 39 20.7614 39 18C39 15.2386 36.7614 13 34 13C31.2386 13 29 15.2386 29 18C29 20.7614 31.2386 23 34 23Z"
      fill="url(#paint4_radial_559_2988)"
    ></path>
  </Svg>
);
