import React from 'react';

import { Select, SelectOption } from './Select';
import { FlexBox } from '../containers/FlexBox';
import { ElementProps, withProps } from '../../entity/components';
import { useLayout } from '../../contexts/LayoutContext';

export type Option = {
  value: string | number;
  label: string;
};

export type MultiSelectTransformerProps = ElementProps & {
  label?: string;
  value: string | number;
  options: Option[];
  onChange: (value: string | number) => void;
  renderValue?: (
    value: string | number | (string | number)[]
  ) => React.ReactNode;
  renderItem?: (option: SelectOption, checked: boolean) => React.ReactNode;
  disabledOptions?: (string | number)[];
  inner?: MultiSelectTransformerProps[];
};

export const MultiSelectTransformer: React.FC<MultiSelectTransformerProps> = ({
  label,
  value,
  options,
  onChange,
  inner,
  renderValue,
  renderItem,
  disabledOptions = [],
  ...props
}) => {
  const { isMobile } = useLayout();

  const handleChangeMain = (event: any) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  const hasInner = (inner?.length ?? 0) > 0;
  const formStyles = {
    mr: isMobile || !hasInner ? 0 : 2,
    mb: isMobile && hasInner ? 1 : 0,
  };

  return (
    <FlexBox column={isMobile} center={!isMobile} {...withProps(props)}>
      <Select
        label={label}
        value={value}
        onChange={handleChangeMain}
        options={options.map((option) => ({
          value: option.value,
          label: option.label,
        }))}
        renderItem={renderItem}
        renderValue={
          renderValue ||
          ((selected) => {
            const selectedMainOption = options.find(
              (option) => option.value === selected
            );
            return selectedMainOption ? selectedMainOption.label : 'Select';
          })
        }
        disabledOptions={disabledOptions}
        styles={{ form: formStyles }}
        size="small"
      />
      {hasInner && inner?.map((io) => <MultiSelectTransformer {...io} />)}
    </FlexBox>
  );
};
