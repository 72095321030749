import { Entity } from './entity';
import { daysLeft } from '../utils/date';

export enum ActivityType {
  DROP = 'drop',
  SELL = 'sell',
}

export class Activity extends Entity<Activity> {
  id = '';
  name = '';
  chain = '';
  platform = '';
  type = ActivityType.DROP;
  todoList?: any;
  startDate = 0;
  endDate? = 0;
  tokenAmount? = 0;
  tokenName? = '';
  claimStatus = false;
  blockchainAddress? = '';
  url? = '';
  iconUrl? = '';
  isNew = false;
  hidden = false;
  completed = false;
  usdSpent = 0;
  usdGained = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'startDate':
          this.startDate = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'endDate':
          this.endDate = val < 1000000000000 ? val * 1000 : val;
          break;
        default:
          (this as any)[key] = val;
      }
    });

    if (!!obj.todoList && typeof obj.todoList === 'string') {
      this.todoList = JSON.parse(obj.todoList);
    }
  }

  clone(patch?: Partial<Activity>): Activity {
    return new Activity({ ...this, ...patch });
  }

  req(): Activity {
    this.startDate = Math.round(this.startDate / 1000);
    if (this.endDate) this.endDate = Math.round(this.endDate / 1000);
    if (this.todoList) this.todoList = JSON.stringify(this.todoList);
    return this;
  }

  daysBeforeFinish() {
    if (this.completed || (this.endDate ?? 0) <= 0) {
      return null;
    }
    return daysLeft(this.endDate ?? 0);
  }
}
