import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import { Container } from '../../components/nav/Container';
import { ActionButton } from '../../components/controls/ActionButton';
import { Media } from '../../entity';
import { defaultMedia } from '../../domain/default';
import { deleteMedia, listMedia, storeMedia } from '../../services';
import { XCheckButton } from '../../components/controls/XCheckButton';
import { usePage } from '../../contexts/PageContext';
import {
  AddMediaForm,
  DeleteMediaForm,
} from '../../components/forms/MediaForms';
import { FlexBox } from '../../components/containers/FlexBox';
import { Tabs } from '../../components/controls/Tabs';

type ToolbarButtonsProps = {
  isMobile: boolean;
  onClickAdd: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickDelete: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const ToolbarButtons: React.FC<ToolbarButtonsProps> = ({
  onClickAdd,
  onClickDelete,
  isMobile,
}) => (
  <FlexBox alignItems="center">
    <ActionButton
      mr={isMobile ? 1 : 2}
      text={isMobile ? 'Add' : 'Add Media'}
      onClick={onClickAdd}
    />
    <ActionButton
      text={isMobile ? 'Delete' : 'Delete Media'}
      icon={<DeleteIcon />}
      onClick={onClickDelete}
      mr={isMobile ? 1 : 2}
    />
    <XCheckButton isMobile={isMobile} />
  </FlexBox>
);

export const MediaPage: React.FC<void> = () => {
  const { navigate, showLoader, hideLoader, showToast, isMobile } = usePage();
  const [data, setData] = useState<Media[]>([]);

  const [selected, setSelected] = useState<Media | null>(data[0]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [newItem, setNewItem] = useState<Media>(defaultMedia);

  const load = () => {
    showLoader();
    listMedia()
      .then((res) => {
        setData(res);
        setSelected(res[0]);
      })
      .catch((err) =>
        showToast(`Failed to load media: ${err.message}`, 'error')
      )
      .finally(hideLoader);
  };
  useEffect(() => load(), []);

  const handleAdd = async () =>
    storeMedia(newItem)
      .then(() => {
        setData([...data, newItem.clone()]);
        setNewItem(Media.from({}));
        setOpenAdd(false);
        showToast(`Media ${newItem.name} successfully created`, 'info');
        load();
      })
      .catch((err: any) =>
        showToast(`Failed to create media: ${err.message}`, 'error')
      );

  const handleDel = async (id: string) =>
    deleteMedia(id)
      .then(() => {
        setData(data.filter((m) => m.id !== id));
        setOpenDel(false);
        showToast(`Media ${newItem.name} successfully deleted`, 'info');
        load();
      })
      .catch((err: any) =>
        showToast(`Failed to delete media: ${err.message}`, 'error')
      );

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    const source = data.find((m) => m.id === newValue);
    if (source) setSelected(source);
  };

  const tabs = data.map((d) => ({ label: d.name, value: d.id }));

  return (
    <Container
      navigate={navigate}
      actionComponent={
        <ToolbarButtons
          isMobile={isMobile ?? false}
          onClickAdd={() => setOpenAdd(true)}
          onClickDelete={() => setOpenDel(true)}
        />
      }
    >
      <Tabs
        tabs={tabs}
        value={selected?.id || false}
        onChange={handleTabChange}
      />
      {selected && (
        <iframe
          src={selected.url}
          width="100%"
          height="10000px"
          allowFullScreen
          style={{ border: 'none', marginTop: '16px' }}
        />
      )}

      <AddMediaForm
        media={newItem}
        open={openAdd}
        set={setNewItem}
        onClose={() => setOpenAdd(false)}
        onSave={handleAdd}
        isMobile={isMobile}
      />
      <DeleteMediaForm
        isMobile={isMobile}
        media={data}
        open={openDel}
        onDelete={handleDel}
        onClose={() => setOpenDel(false)}
      />
    </Container>
  );
};
