import React, { useEffect, useMemo, useState } from 'react';

import BaseTable, { BaseTableProps } from './BaseTable';
import { listOrders } from '../../services';
import { Order } from '../../entity';
import { AmountCell } from './cells/AmountCell';
import { timestampToDateStr } from '../../utils/date';
import { tokenIcon } from '../icons';

export type OrderTableProps = BaseTableProps;

export const OrderTable: React.FC<OrderTableProps> = (props) => {
  const [data, setData] = useState<Order[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams();
      params.append('size', '100');
      params.append('page', '1');
      const orderList = await listOrders(params.toString());
      setData(orderList.orders);
    };

    fetchData();
  }, []);

  const rows = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        timestamp: timestampToDateStr(item.timestamp),
        tokenIconUrl: tokenIcon(item.symbol),
        statusColor: item.status === 'success' ? '#5da78b' : '#b00b1e',
      })),
    [data]
  );

  const columnDefs = [
    { headerName: 'ID', field: 'id' },
    { headerName: 'CEX', field: 'cex' },
    { headerName: 'Type', field: 'type' },
    {
      headerName: 'Symbol',
      field: 'symbol',
      cellRenderer: 'imageText',
      cellRendererParams: { srcFieldName: 'tokenIcon' },
    },
    { headerName: 'Price', field: 'price', cellRenderer: 'amount' },
    { headerName: 'Size', field: 'size', cellRenderer: 'amount' },
    { headerName: 'Side', field: 'side' },
    { headerName: 'State', field: 'state' },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'status',
      cellRendererParams: { colorFieldName: 'statusColor' },
    },
    { headerName: 'Timestamp', field: 'timestamp' },
  ];

  return (
    <BaseTable
      rowData={rows}
      columnDefs={columnDefs}
      title={props.title ?? 'Orders'}
      components={{ amount: AmountCell }}
      searchFields={[
        'id',
        'cex',
        'type',
        'symbol',
        'side',
        'state',
        'status',
        'timestamp',
      ]}
      {...props}
    />
  );
};

export default OrderTable;
