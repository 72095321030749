import React, { useMemo } from 'react';

import { withProps } from '../../entity/components';
import { Card } from './Card';
import { Text } from '../display/Text';
import { ActiveChart, ActiveChartProps } from '../charts/ActiveChart';
import { Skeleton } from '../display/Skeleton';

export type ActiveChartCardProps = ActiveChartProps & {
  title?: string;
};

export const ActiveChartCard: React.FC<ActiveChartCardProps> = ({
  active,
  total,
  title,
  customTooltip,
  h = 300,
  sx: propsSx,
  ...props
}) => {
  const sx = useMemo(
    () => ({
      overflow: 'hidden',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...propsSx,
      height: h,
    }),
    [propsSx]
  );

  return (
    <Card column flex {...withProps({ ...props, sx })} fullWidth>
      {title && (
        <Text variant="subtitle2" bold mr={1}>
          {title}
        </Text>
      )}
      <Skeleton delay={300} wait={!!active && !!total} h="100%">
        <ActiveChart
          customTooltip={customTooltip}
          tileSize={6}
          active={active}
          total={total}
          h={h - 50}
        />
      </Skeleton>
    </Card>
  );
};
