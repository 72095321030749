import { Asset } from './asset';
import { Entity } from './entity';
import { timestampToDateStr } from '../utils/date';

export enum TxStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending',
  REVERT = 'revert',
}

export class Tx extends Entity<Tx> {
  id = '';
  chain = '';
  hash = '';
  blockHeight = 0;
  blockHash = '';
  blocktime = 0;
  from = '';
  to = '';
  asset = Asset.from({});
  amount = '';
  fee?: number;
  time?: number;
  memo? = '';
  status = TxStatus.SUCCESS;
  type = '';
  explorerUrl = '';

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'blocktime':
          this.blocktime = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'time':
          this.time = obj.time
            ? obj.time < 1000000000000
              ? obj.time * 1000
              : obj.time
            : 0;
          break;
        case 'asset':
          this.asset = Asset.from(val);
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Tx>): Tx {
    return new Tx({ ...this, ...patch });
  }

  req(): Tx {
    this.blocktime = Math.round(this.blocktime / 1000);
    if (this.time) this.time = Math.round(this.time / 1000);
    return this;
  }
}

export const getTxTime = (tx: Tx) =>
  tx.time ? timestampToDateStr(tx.time) : undefined;

export type DeFiTrackerTxType =
  | 'Bundle'
  | 'BundleSell'
  | 'ApproveSwap'
  | 'ApproveSell'
  | 'Swap'
  | 'Sell'
  | 'ApproveBridge'
  | 'Bridge'
  | 'Claim'
  | 'Transfer'
  | 'Window';

export class DeFiTrackerTx extends Entity<DeFiTrackerTx> {
  id: string = '';
  tradeId: string = '';
  iterId: string = '';
  txType: DeFiTrackerTxType = 'Swap';
  networkBuy: string = '';
  networkSell: string = '';
  sender: string = '';
  tokenName: string = '';
  tokenAddress: string = '';
  txHash: string = '';
  txVersion: number = 0;
  provider: string = '';
  nonce: number = 0;
  contractAddress?: string;
  inputData?: string;
  value?: string;
  dexName?: string;
  bridgeName?: string;
  bridgeAmount?: string;
  bridgeApproveTarget?: string;
  blockSubmitted: number = 0;
  blockSubmittedTime?: number;
  blockTarget: number = 0;
  blockTargetTime?: number;
  blockConfirmed?: number;
  blockConfirmedTime?: number;
  blockPos?: number;
  timeSent: number = 0;
  timeCreated: number = 0;
  timeStart?: number;
  srcTokenAmount?: string;
  dstTokenAmount?: string;
  expectedProfit?: string;
  gasPrice?: string;
  gasLimit?: string;
  maxFeePerGas?: string;
  maxPriorityFeePerGas?: string;
  success?: boolean;
  errMessage?: string;
  replaced?: boolean;
  isManual?: boolean;
  sendBack?: boolean;
  timeConfirmed?: number;
  fee?: string;
  effectiveGasPrice?: string;
  gasUsed?: string;
  nativePrice?: number;
  ethPrice?: number;
  windowIndex?: number;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        // Convert timestamps to milliseconds if necessary
        case 'timeConfirmed':
        case 'timeCreated':
        case 'timeSent':
        case 'blockSubmittedTime':
        case 'blockTargetTime':
        case 'blockConfirmedTime':
        case 'timeStart':
          if (val) {
            (this as any)[key] = val < 1000000000000 ? val * 1000 : val;
          }
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<DeFiTrackerTx>): DeFiTrackerTx {
    return new DeFiTrackerTx({ ...this, ...patch });
  }

  req(): DeFiTrackerTx {
    // Convert timestamps back to seconds before sending to the server
    this.timeCreated = Math.round(this.timeCreated / 1000);
    this.timeSent = Math.round(this.timeSent / 1000);
    if (this.timeConfirmed)
      this.timeConfirmed = Math.round(this.timeConfirmed / 1000);
    if (this.blockSubmittedTime)
      this.blockSubmittedTime = Math.round(this.blockSubmittedTime / 1000);
    if (this.blockTargetTime)
      this.blockTargetTime = Math.round(this.blockTargetTime / 1000);
    if (this.blockConfirmedTime)
      this.blockConfirmedTime = Math.round(this.blockConfirmedTime / 1000);
    if (this.timeStart) this.timeStart = Math.round(this.timeStart / 1000);
    return this;
  }
}

export class TxList extends Entity<TxList> {
  txs: Tx[] = [];
  total = 0;

  constructor(obj: any) {
    super();
    this.txs = obj.txs?.map((tx: any) => Tx.from(tx)) ?? [];
    this.total = obj.total;
  }

  clone(patch?: Partial<TxList>): TxList {
    return new TxList({ ...this, ...patch });
  }

  req(): TxList {
    return this;
  }
}

export class DeFiTrackerTxList extends Entity<DeFiTrackerTxList> {
  txs: DeFiTrackerTx[] = [];
  total = 0;

  constructor(obj: any) {
    super();
    this.txs = obj.txs?.map((tx: any) => new DeFiTrackerTx(tx)) ?? [];
    this.total = obj.total || 0;
  }

  clone(patch?: Partial<DeFiTrackerTxList>): DeFiTrackerTxList {
    return new DeFiTrackerTxList({ ...this, ...patch });
  }

  req(): DeFiTrackerTxList {
    return this;
  }
}
