import React from 'react';
import { Chip } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

import { Card } from './Card';
import { FlexBox } from '../containers/FlexBox';
import { ElementProps, withProps } from '../../entity/components';
import { Text } from '../display/Text';
import { amountWithSuffix } from '../../utils/amount';
import { Skeleton } from '../display/Skeleton';

export type ValueChangeProps = ElementProps & {
  title: string;
  prefix?: string;
  value: number;
  change: number;
  short?: boolean;
};

export const ValueChangeCard: React.FC<ValueChangeProps> = ({
  title,
  short,
  prefix = '',
  value,
  change,
  ...props
}) => {
  const height = props.h ?? (short ? 90 : 105);

  return (
    <Card {...withProps({ ...props })} h={height} flex column p={2}>
      <Text variant="subtitle2" bold mb={short ? 1 : 2}>
        {title}
      </Text>
      <Skeleton wait={value}>
        <FlexBox mt="auto" alignItems="center" justifyContent="space-between">
          <Text variant="h2" bold>
            ${amountWithSuffix(value, prefix)}
          </Text>
          <Chip
            size="small"
            label={`${change?.toFixed(2)}%`}
            color={change >= 0 ? 'success' : 'error'}
            icon={
              change >= 0 ? (
                <ArrowUpward fontSize="small" />
              ) : (
                <ArrowDownward fontSize="small" />
              )
            }
          />
        </FlexBox>
      </Skeleton>
    </Card>
  );
};
