import { get } from './request';
import { DeFiTrackerTxList, TimeRangeType, Wallet } from '../entity';
import { DeFiTrackerProfit } from '../entity/profit';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/dt`;

const cache: { [key in TimeRangeType]?: DeFiTrackerProfit } = {};

export const getDeFiTrackerWallet = () =>
  get<Wallet>(`${apiUrl}/wallets`, Wallet);
export const getDeFiTrackerTransactions = (query: string) =>
  get<DeFiTrackerTxList>(`${apiUrl}/txs?${query}`, DeFiTrackerTxList);
export const getDeFiTrackerProfits = async (range: TimeRangeType) => {
  let res = cache[range];
  if (res) return res;

  res = await get<DeFiTrackerProfit>(
    `${apiUrl}/profits?range=${range}`,
    DeFiTrackerProfit
  );
  cache[range] = res;
  return res;
};
