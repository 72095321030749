export const WEEK_DAYS = 7;
export const MONTH_DAYS = 30;
export const YEAR_DAYS = 365;

export const DAY_HOURS = 24;
export const WEEK_HOURS = DAY_HOURS * 7;
export const MONTH_HOURS = DAY_HOURS * 30;
export const YEAR_HOURS = DAY_HOURS * 365;

export const HOUR_MILLIS = 3600000;
export const DAY_MILLIS = HOUR_MILLIS * DAY_HOURS;
export const WEEK_MILLIS = HOUR_MILLIS * WEEK_HOURS;
export const MONTH_MILLIS = DAY_MILLIS * MONTH_HOURS;
export const YEAR_MILLIS = DAY_MILLIS * YEAR_HOURS;

export function timestampToDateTimeStr(timestamp: number): string {
  const date = new Date(timestamp);
  return dateToDateTimeStr(date);
}

export function timestampToShortDateStr(timestamp: number): string {
  const date = new Date(timestamp);

  const month = date.getMonth();
  const day = date.getDate();

  return `${month}-${day}`;
}

export function timestampToShortTimeStr(timestamp: number): string {
  return formatHourFromTimestamp(timestamp);
}

// Function to convert timestamp number to string date in format DD MMMM YYYY hh:mm
export function timestampToDateStr(timestamp: number): string {
  const date = new Date(timestamp);
  return dateToDateStr(date);
}

// Function to convert timestamp number to string date in format hh:mm:ss
export function timestampToTimeStr(timestamp: number): string {
  const date = new Date(timestamp);
  return dateToTimeStr(date);
}

// Function to convert Date object to string date in format DD MMMM YYYY
export function dateToDateStr(date: Date): string {
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('en-GB', { month: 'long' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
}

// Function to convert Date object to string time in format hh:mm:ss
export function dateToTimeStr(date: Date): string {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

// Function to convert Date object to string date in format DD MMMM YYYY hh:mm
export function dateToDateTimeStr(date: Date): string {
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('en-GB', { month: 'long' });
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${day} ${month} ${year} ${hours}:${minutes}`;
}

// Function to convert string date in format DD MMMM YYYY to Date object
export function dateStrToDate(dateStr: string): Date {
  const [day, month, year] = dateStr.split(' ');
  const monthIndex = new Date(`${month} 1, 2000`).getMonth(); // Get month index using a known year
  return new Date(parseInt(year), monthIndex, parseInt(day));
}

function formatHourFromTimestamp(timestamp: number): string {
  const date = new Date(timestamp);
  let hour = date.getHours();
  const period = hour >= 12 ? 'PM' : 'AM';

  // Convert 24-hour format to 12-hour format
  hour = hour % 12;
  hour = hour === 0 ? 12 : hour; // Convert hour "0" or "12" correctly to "12"

  return `${hour} ${period}`;
}

// Function to convert string date in format DD MMMM YYYY to timestamp number
export function dateStrToTimestamp(dateStr: string): number {
  const date = dateStrToDate(dateStr);
  return date.getTime();
}

// Function to convert timestamp number to ISO 8601 format yyyy-MM-ddThh:mm
export function timestampToIso8601(timestamp: number): string {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

// Function to convert ISO 8601 format yyyy-MM-ddThh:mm string to timestamp number
export function iso8601ToTimestamp(iso8601Str: string): number {
  const date = new Date(iso8601Str);
  return date.getTime();
}

export function profitDateStr(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function daysSince(timestamp: number): number {
  const givenDate = new Date(timestamp);
  const now = new Date();

  const currentDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );

  const daysDifference = Math.floor(
    (currentDate.getTime() - givenDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  return daysDifference > 0 ? daysDifference - 1 : 0;
}

export function daysLeft(timestamp: number): number {
  const targetDate = new Date(timestamp);
  const now = new Date();

  const currentDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );

  const daysDifference = Math.ceil(
    (targetDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  return daysDifference > 0 ? daysDifference : 0;
}
