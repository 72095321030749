import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Container } from '../../components/nav/Container';
import { getNotesUrl } from '../../services';
import { ActionLink } from '../../components/controls/ActionLink';
import { usePage } from '../../contexts/PageContext';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../../theme/theme';
import { EditIcon } from '../../components/icons';

export const NotesPage: React.FC<void> = () => {
  const { navigate, showLoader, hideLoader, showToast, isMobile } = usePage();

  const [url, setUrl] = useState<string>('');
  const [actionUrl, setActionUrl] = useState<string>('');

  const load = () => {
    showLoader();
    getNotesUrl()
      .then((res: AxiosResponse) => {
        setUrl(res.data.url);
        setActionUrl(res.data.actionUrl);
      })
      .catch((err: any) =>
        showToast(`Failed to load notes url: ${err.message}`, 'error')
      )
      .finally(hideLoader);
  };
  useEffect(() => load(), []);

  const headerHeight = isMobile
    ? `${HEADER_HEIGHT_MOBILE}px`
    : `${HEADER_HEIGHT}px`;

  return (
    <Container
      navigate={navigate}
      actionComponent={
        <ActionLink
          href={actionUrl}
          icon={
            <EditIcon imgSize={isMobile ? 'xs' : 'sm'} color="text.white" />
          }
        />
      }
      p={0}
      h="100vh"
      fullWidth
      pt={headerHeight}
    >
      <iframe
        src={url}
        width="100%"
        height="1600px"
        allowFullScreen
        style={{ border: 'none' }}
      />
    </Container>
  );
};
