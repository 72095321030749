import React from 'react';
import { Box } from '@mui/material';

export type ProgressSector = {
  value: number;
  color: string;
};

export type ProgressBarProps = {
  value: number;
  sectors: ProgressSector[];
  pointColor?: string;
  hidePoint?: boolean;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  sectors,
  hidePoint,
  pointColor = '#555',
}) => {
  const renderSectors = () => {
    return sectors.map((sector, index) => (
      <Box
        key={index}
        sx={{
          backgroundColor: sector.color,
          flex: sector.value,
          height: '100%',
        }}
      />
    ));
  };

  const renderPointer = () => {
    return (
      !hidePoint && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: `${value}%`,
            width: '24px',
            height: '24px',
            marginTop: '-8px',
            backgroundColor: pointColor,
            borderRadius: '50%',
            border: '5px solid #fff',
            transform: 'translateX(-50%)',
          }}
        />
      )
    );
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          borderRadius: '20px',
          overflow: 'hidden',
        }}
      >
        {renderSectors()}
      </Box>
      {renderPointer()}
    </Box>
  );
};
