import { Entity } from './entity';

export class Exchange extends Entity<Exchange> {
  exchangeId: string = '';
  name: string = '';
  rank: number = 0;
  percentTotalVolume: number = 0;
  volumeUsd: number = 0;
  tradingPairs: number = 0;
  socket: boolean = false;
  exchangeUrl: string = '';
  iconUrl: string = '';
  updated: number = 0;

  constructor(obj: Partial<Exchange> = {}) {
    super();
    Object.keys(obj).forEach((key) => {
      if (key in this) {
        (this as any)[key] = obj[key as keyof Exchange];
      }
    });
  }

  clone(patch?: Partial<Exchange>): Exchange {
    return new Exchange({ ...this, ...patch });
  }

  req(): Exchange {
    return this.clone({});
  }
}
