import React, { useEffect, useMemo, useState } from 'react';

import {
  AssetBalance,
  assetsBalancesFromQuery,
  BalanceQuery,
  Earn,
  FinalBalance,
  HoldingType,
  PortfolioCoin,
  Price,
  Wallet,
} from '../../entity';
import BaseTable, { BaseTableProps } from './BaseTable';
import { ImageTextCell } from './cells/ImageTextCell';
import { AmountCell } from './cells/AmountCell';
import { ElementProps } from '../../entity/components';
import { PortfolioSelect } from '../controls/PortfolioSelect';
import { getFinalBalances } from '../../services/balances';

export type PortfolioTableProps = BaseTableProps &
  ElementProps & {
    wallets: Wallet[];
    earns: Earn[];
  };

export const PortfolioTable: React.FC<PortfolioTableProps> = ({
  wallets,
  earns,
  ...restProps
}) => {
  const [query, setQuery] = React.useState<BalanceQuery>({
    holding: HoldingType.ALL,
  });
  const [balance, setBalance] = React.useState<FinalBalance>(
    FinalBalance.from({})
  );
  const [assets, setAssets] = useState<AssetBalance[]>([]);

  useEffect(() => {
    getFinalBalances()
      .then((res) => setBalance(res))
      .catch((err) => console.error(`failed to fetch balance: ${err.message}`));
  }, [query]);

  useEffect(() => {
    const assets = assetsBalancesFromQuery(query, balance, wallets, earns);
    setAssets(assets);
  }, [balance, wallets, earns]);

  const tokens = useMemo(() => {
    const total = assets.reduce(
      (acc: number, ab: AssetBalance) => acc + ab.balance.value,
      0
    );

    return assets
      .map(
        ({ asset: a, balance }: AssetBalance) =>
          ({
            symbol: a.symbol,
            url: a.url,
            iconUrl: a.iconUrl,
            price: balance.price,
            percent: total ? (balance.value / total) * 100 : 0,
            amount: balance.amount,
            value: balance.value,
          }) as PortfolioCoin
      )
      .sort((a: any, b: any) => b.percent - a.percent);
  }, [assets]);

  const rows = useMemo(
    () =>
      tokens.map((token: any) => ({
        ...token,
        coinImageSrc: token.iconUrl,
        coinLinkUrl: token.url,
      })),
    [tokens]
  );

  const columnDefs = [
    {
      headerName: 'Coin',
      field: 'symbol',
      cellRenderer: 'imageText',
      cellRendererParams: {
        srcFieldName: 'coinImageSrc',
        linkFieldName: 'coinLinkUrl',
      },
    },
    {
      headerName: 'Portfolio %',
      field: 'percent',
      cellRenderer: 'amount',
      cellRendererParams: { suffix: '%' },
      minWidth: 150,
    },
    {
      headerName: 'Value',
      field: 'value',
      cellRenderer: 'amount',
      cellRendererParams: { suffix: '$' },
    },
    { headerName: 'Amount', field: 'amount', cellRenderer: 'amount' },
    {
      headerName: 'Price',
      field: 'price',
      cellRenderer: 'amount',
      cellRendererParams: { suffix: '$' },
    },
  ].map((col) => ({ ...col, minWidth: col.minWidth ?? 130 }));

  const externalComponents = [
    <PortfolioSelect wallets={wallets} earns={earns} onChange={setQuery} />,
  ];

  return (
    <BaseTable
      rowData={rows}
      columnDefs={columnDefs}
      components={{ imageText: ImageTextCell, amount: AmountCell }}
      gridOptions={{ autoSizeStrategy: { type: 'fitGridWidth' } }}
      externalFilterComponents={externalComponents}
      {...restProps}
      title={restProps.title ?? 'Portfolio'}
    />
  );
};

export default PortfolioTable;
