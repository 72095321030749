import React, { useMemo } from 'react';

import { usePage } from '../../contexts/PageContext';
import Tiles from '../../components/containers/Tiles';
import { distributionTooltip } from '../charts/tooltips';
import { DonutChartCard } from '../cards/DonutChartCard';
import { BarChartCard } from '../cards/BarChartCard';
import { ElementProps, withProps } from '../../entity/components';
import { useData } from '../../contexts/DataContext';

type PortfolioDistributionProps = ElementProps;

export const PortfolioDistribution: React.FC<PortfolioDistributionProps> = (
  props
) => {
  const { isWidescreen, isMacbook16 } = usePage();
  const { walletBalances24h, earnBalances24h, profit24h } = useData();

  const assetDistribution = useMemo(() => {
    if (!walletBalances24h?.final.value || !earnBalances24h?.final.value) {
      return [
        { name: 'Wallets', value: 0 },
        { name: 'Earns', value: 0 },
      ];
    }

    const walletValue = walletBalances24h?.final.value;
    const earnValue = earnBalances24h?.final.value;
    return [
      { name: 'Wallets', value: walletValue },
      { name: 'Earns', value: earnValue },
    ];
  }, [walletBalances24h, earnBalances24h]);

  const profitDistribution = useMemo(() => {
    if (!profit24h?.wallets || !profit24h?.earns) {
      return [
        { name: 'Wallets', value: 0 },
        { name: 'Earns', value: 0 },
      ];
    }

    return [
      {
        name: 'Wallets',
        value: profit24h?.totalWallets(),
      },
      {
        name: 'Earns',
        value: profit24h?.totalEarns(),
      },
    ];
  }, [profit24h]);

  return (
    <Tiles {...withProps(props)}>
      <DonutChartCard
        h={358}
        title="Asset Distribution"
        customTooltip={distributionTooltip}
        data={assetDistribution}
        tileSM={6}
        tileLG={12}
        compact={isMacbook16 || isWidescreen}
        customLabel={({ name, percent }: any) =>
          `${name}: ${(percent * 100).toFixed(1)} %`
        }
      />
      <BarChartCard
        h={358}
        title="Profit Distribution"
        data={profitDistribution}
        dataKey="value"
        xAxisKey="name"
        barColor={(entry: any) => (entry.value >= 0 ? 'green' : 'red')}
        tileSM={6}
        tileLG={12}
      />
    </Tiles>
  );
};
