import React, { useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';

import { Container } from '../../components/nav/Container';
import { ActionButton } from '../../components/controls/ActionButton';
import { useData } from '../../contexts/DataContext';
import { DeletePromptDialog } from '../../components/modals/DeletePromptDialog';
import { UnitBalance, Wallet, WalletPurpose } from '../../entity';
import { deleteWallet, storeWallet, updateWallet } from '../../services';
import { defaultAccount, defaultWallet } from '../../domain/default';
import { WalletCard } from '../../components/cards/WalletCard';
import { usePage } from '../../contexts/PageContext';
import { TxTable } from '../../components/tables/TxTable';
import { OrderTable } from '../../components/tables/OrderTable';
import { TradeTable } from '../../components/tables/TradeTable';
import { WalletForm } from '../../components/forms/WalletForm';
import { Tabs } from '../../components/controls/Tabs';
import { FlexBox } from '../../components/containers/FlexBox';
import Tiles from '../../components/containers/Tiles';

export const WalletsPage: React.FC<void> = () => {
  const { navigate, showToast } = usePage();
  const { wallets, setWallets: setData, assets, prices, sync } = useData(); // Fetch assets from useData

  const [balances, setBalances] = useState<{ [key: string]: UnitBalance }>({});
  const [open, setOpen] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [delData, setDelData] = useState<Wallet | null>(null);
  const [selected, setSelected] = useState<Wallet>(
    defaultWallet.clone({
      createdAt: Date.now(),
      updatedAt: Date.now(),
    })
  );

  const allPurposes = Object.values(WalletPurpose);

  const data = useMemo(() => {
    return wallets.sort((a, b) => {
      return (
        (balances[b.id]?.final.value ?? 0) - (balances[a.id]?.final.value ?? 0)
      );
    });
  }, [wallets, balances]);

  const [tab, setTab] = useState<number>(() => {
    const savedTab = localStorage.getItem('wallets_tab');
    return savedTab ? parseInt(savedTab, 10) : 0;
  });

  useEffect(() => {
    localStorage.setItem('wallets_tab', tab.toString());
  }, [tab]);

  const handleSave = () => {
    if (selected.id) {
      updateWallet(selected.id, selected)
        .then(() => {
          setData(data.map((w) => (w.id === selected.id ? selected : w)));
          showToast(`Wallet ${selected.name} successfully updated`, 'info');
          sync();
        })
        .catch((err: any) =>
          showToast(`Failed to update wallet: ${err.message}`, 'error')
        )
        .finally(() => {
          handleClose();
        });
      return;
    }

    storeWallet(selected)
      .then(() => {
        setData([selected, ...data]);
        showToast(`Wallet ${selected.name} successfully created`, 'info');
        sync();
      })
      .catch((err: any) =>
        showToast(`Failed to add wallet: ${err.message}`, 'error')
      )
      .finally(() => {
        handleClose();
      });
  };

  const handleEdit = (wallet: Wallet) => {
    setSelected(wallet);
    setOpen(true);
  };

  const handleDel = () => {
    setOpenDelModal(false);

    deleteWallet(delData!.id)
      .then(() => {
        setData(data.filter((w) => w.id !== delData!.id));
        showToast(`Wallet ${delData!.name} successfully deleted`, 'info');
        sync();
      })
      .catch((err: any) =>
        showToast(`Failed to delete wallet: ${err.message}`, 'error')
      )
      .finally(() => {
        setDelData(null);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelected(
      defaultWallet.clone({ createdAt: Date.now(), updatedAt: Date.now() })
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  // Account Handlers
  const handleAccChange = (index: number, field: string, value: string) => {
    const newAccs = [...selected.accounts];
    newAccs[index] = newAccs[index].clone({ [field]: value });
    setSelected(selected.clone({ accounts: newAccs }));
  };

  const handleAddAcc = () => {
    const newAccounts = [...selected.accounts, defaultAccount.clone()];
    setSelected(selected.clone({ accounts: newAccounts }));
  };

  const handleDeleteAcc = (index: number) => {
    const newAccs = [...selected.accounts];
    newAccs.splice(index, 1);
    setSelected(selected.clone({ accounts: newAccs }));
  };

  const handleOpenDelModal = (wallet: Wallet) => {
    setDelData(wallet);
    setOpenDelModal(true);
  };

  const handleCloseDelModal = () => {
    setDelData(null);
    setOpenDelModal(false);
  };

  const tabs = [
    { label: 'Balances', value: 0 },
    { label: 'Transactions', value: 1 },
    { label: 'CEX', value: 2 },
  ];

  const handleWalletBalance = (id: string, updates: UnitBalance) => {
    const balanceClone = cloneDeep(updates);
    if (balances[id]?.final?.value >= balanceClone?.final?.value) return;
    setBalances((state) => {
      return { ...state, [id]: balanceClone };
    });
  };

  return (
    <Container
      navigate={navigate}
      mh="100vh"
      actionComponent={
        <ActionButton entityName="Wallet" onClick={handleClickOpen} />
      }
    >
      <Tiles>
        <Tabs tabs={tabs} value={tab} onChange={handleTabChange} />
        {tab === 0 &&
          data.map((item) => (
            <WalletCard
              key={item.id}
              tileLG={6}
              walletId={item.id}
              onBalance={handleWalletBalance}
              onEdit={handleEdit}
              onDelete={handleOpenDelModal}
            />
          ))}
        {tab === 1 && (
          <TxTable p={0} withPagination wallets={data} card={false} hideTitle />
        )}
        {tab === 2 && (
          <FlexBox column>
            <OrderTable mb={4} card={false} titleInFilters />
            <TradeTable card={false} titleInFilters />
          </FlexBox>
        )}
      </Tiles>

      <WalletForm
        data={selected}
        prices={prices}
        open={open}
        purposes={allPurposes}
        setData={setSelected}
        onAccChange={handleAccChange}
        onAddAcc={handleAddAcc}
        onDeleteAcc={handleDeleteAcc}
        allAssets={assets}
        onClose={handleClose}
        onSave={handleSave}
      />
      <DeletePromptDialog
        open={openDelModal}
        entityName={delData?.name || ''}
        onClose={handleCloseDelModal}
        onDelete={handleDel}
      />
    </Container>
  );
};
