import { Entity } from './entity';

export class NFT extends Entity<NFT> {
  rank: number = 0;
  iconUrl: string = '';
  contractName: string = '';
  productPath: string = '';
  baseCurrency: string = '';
  valueUSD: number = 0;
  platformName: string = '';
  buyers: number = 0;
  sellers: number = 0;
  owners: number = 0;
  transactions: number = 0;
  changeInValueUSD: number = 0;
  previousValueUSD: number = 0;
  changeInBuyers: number = 0;
  changeInSellers: number = 0;
  changeInTransactions: number = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      (this as any)[key] = obj[key];
    });
  }

  clone(patch?: Partial<NFT>): NFT {
    return new NFT({ ...this, ...patch });
  }

  req(): NFT {
    return this.clone({});
  }
}

export class NFTList extends Entity<NFTList> {
  data: NFT[] = [];
  total = 0;

  constructor(obj: any) {
    super();
    this.data = obj.data?.map((f: any) => NFT.from(f)) ?? [];
    this.total = obj.total;
  }

  clone(patch?: Partial<NFTList>): NFTList {
    return new NFTList({ ...this, ...patch });
  }

  req(): NFTList {
    const data = this.data.map((f) => NFT.from(f)) ?? [];
    return { ...this, data };
  }
}
