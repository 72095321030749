import React from 'react';
import { IconButton as MUIIconButton, IconButtonProps } from '@mui/material';

import { ElementProps, withProps } from '../../entity/components';

export const IconButton = (props: IconButtonProps & ElementProps) => {
  const sx = {
    transition: 'none',
    boxShadow: 'none',
    background: 'transparent',
    backgroundColor: 'transparent',
    '&:hover': {
      transition: 'none',
      boxShadow: 'none',
      background: 'transparent',
      backgroundColor: 'transparent',
    },
    ...props.sx,
  };

  return (
    <MUIIconButton {...withProps({ ...props, sx })}>
      {props.children}
    </MUIIconButton>
  );
};
