import React from 'react';

import { BaseCellProps } from './Base';
import { IconButton } from '../../controls/IconButton';

export type IconProps = BaseCellProps & {
  iconFieldName: string;
  colorFieldName: string;
};

export const IconCell = ({
  data,
  iconFieldName,
  colorFieldName,
  style = {},
  onClick,
}: IconProps) => {
  const Icon = data[iconFieldName];
  const color = data[colorFieldName];
  if (!Icon || !color) return null;

  return (
    <IconButton onClick={() => onClick?.(data)} color={color} sx={style}>
      <Icon />
    </IconButton>
  );
};
