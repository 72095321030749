import { Entity } from './entity';

export class Meme extends Entity<Meme> {
  id: string = '';
  name: string = '';
  primary_project_id: string = '';
  primary_project_slug: string = '';
  slug: string = '';
  symbol: string = '';
  price: number = 0;
  price_change_24h: number = 0;
  price_change_7d: number = 0;
  price_change_30d: number = 0;
  circulating_marketcap: number = 0;
  real_volume_24h: number = 0;
  sector: string = '';
  icon_url: string = '';

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = key === 'symbol' ? obj[key].toUpperCase() : obj[key];
      (this as any)[key] = val;
    });
  }

  clone(patch?: Partial<Meme>): Meme {
    return new Meme({ ...this, ...patch });
  }

  req(): Meme {
    return this.clone({});
  }
}

export class MemeList extends Entity<MemeList> {
  data: Meme[] = [];
  total = 0;

  constructor(obj: any) {
    super();
    this.data = obj.data?.map((f: any) => Meme.from(f)) ?? [];
    this.total = obj.total;
  }

  clone(patch?: Partial<MemeList>): MemeList {
    return new MemeList({ ...this, ...patch });
  }

  req(): MemeList {
    const data = this.data.map((f) => Meme.from(f)) ?? [];
    return { ...this, data };
  }
}
