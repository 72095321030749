import { Entity } from './entity';
import { profitDateStr } from '../utils/date';
import { BalanceQuery, HoldingType } from './query';
import { filterProfitsByRange, TimeRange } from './range';

export class Profit extends Entity<Profit> {
  time: number = 0;
  assetId: string = '';
  accountId: string = '';
  startAmount: number = 0;
  startPrice: number = 0;
  startValue: number = 0;
  endAmount: number = 0;
  endPrice: number = 0;
  endValue: number = 0;
  dailyProfit: number = 0;
  profit: number = 0;

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'date':
          this.time = new Date(val).getTime();
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Profit>): Profit {
    return new Profit({ ...this, ...patch });
  }

  req(): Profit {
    (this as any).date = profitDateStr(new Date((this as any).time)) as any;
    return this;
  }
}

export class AssetProfit extends Entity<AssetProfit> {
  profits: Profit[] = [];

  constructor(profits: Profit[] = []) {
    super();
    this.profits = profits?.map((p) => Profit.from(p)) ?? [];
  }

  profit(): AssetProfit {
    return this;
  }

  filter(range: TimeRange) {
    return filterProfitsByRange(range, this.profits);
  }

  sort() {
    this.profits.sort((a, b) => a.time - b.time);
  }

  clone(patch?: Partial<AssetProfit>): AssetProfit {
    return new AssetProfit([...this.profits, ...(patch?.profits || [])]);
  }

  req(): AssetProfit {
    return this;
  }
}

export class AccountProfit extends Entity<AccountProfit> {
  assets: Map<string, AssetProfit>;

  constructor(profits: { [key: string]: Profit[] } = {}) {
    super();
    this.assets = new Map(
      Object.entries(profits).map(([key, value]) => [
        key,
        AssetProfit.from(value),
      ])
    );
  }

  profit() {
    const profitMap: { [key: number]: Profit } = {};
    this.assets.forEach((asset) => {
      asset.profits.forEach((profit) => upsertProfitMap(profit, profitMap));
    });
    return assetProfitFromMap(profitMap);
  }

  sort() {
    this.assets.forEach((assetProfit) => assetProfit.sort());
  }

  clone(patch?: Partial<AccountProfit>): AccountProfit {
    const newProfit = new AccountProfit({});
    this.assets.forEach((value, key) => {
      newProfit.assets.set(key, value.clone());
    });
    return newProfit;
  }

  req(): AccountProfit {
    return this;
  }
}

export class AccountsProfit extends Entity<AccountsProfit> {
  accounts: Map<string, AccountProfit>;

  constructor(profits: { [key: string]: { [key: string]: Profit[] } } = {}) {
    super();
    this.accounts = new Map(
      Object.entries(profits).map(([key, value]) => [
        key,
        AccountProfit.from(value),
      ])
    );
  }

  profit() {
    const profitMap: { [key: number]: Profit } = {};
    this.accounts.forEach((acc) => {
      acc
        .profit()
        .profits.forEach((profit) => upsertProfitMap(profit, profitMap));
    });
    return assetProfitFromMap(profitMap);
  }

  sort() {
    this.accounts.forEach((accountProfit) => accountProfit.sort());
  }

  clone(patch?: Partial<AccountsProfit>): AccountsProfit {
    const newProfit = new AccountsProfit({});
    this.accounts.forEach((value, key) => {
      newProfit.accounts.set(key, value.clone());
    });
    return newProfit;
  }

  req(): AccountsProfit {
    return this;
  }
}

export class WalletProfits extends Entity<WalletProfits> {
  wallets: Map<string, AccountsProfit>;

  constructor(
    profits: {
      [key: string]: { [key: string]: { [key: string]: Profit[] } };
    } = {}
  ) {
    super();
    this.wallets = new Map(
      Object.entries(profits).map(([key, value]) => [
        key,
        AccountsProfit.from(value),
      ])
    );
  }

  profit() {
    const profitMap: { [key: number]: Profit } = {};
    this.wallets.forEach((acc) => {
      acc
        .profit()
        .profits.forEach((profit) => upsertProfitMap(profit, profitMap));
    });
    return assetProfitFromMap(profitMap);
  }

  total(): number {
    let res = 0;
    for (const w of this.wallets.values()) {
      const profits = w.profit().profits;
      if (profits.length < 2) continue;
      res += profits[profits.length - 2].profit;
    }
    return res;
  }

  sort() {
    this.wallets.forEach((walletProfit) => walletProfit.sort());
  }

  clone(patch?: Partial<WalletProfits>): WalletProfits {
    const newProfits = new WalletProfits({});
    this.wallets.forEach((value, key) => {
      newProfits.wallets.set(key, value.clone());
    });
    return newProfits;
  }

  req(): WalletProfits {
    return this;
  }
}

export class EarnProfit extends Entity<EarnProfit> {
  assets: Map<string, AssetProfit>;

  constructor(profits: { [key: string]: Profit[] } = {}) {
    super();
    this.assets = new Map(
      Object.entries(profits).map(([key, value]) => [
        key,
        AssetProfit.from(value),
      ])
    );
  }

  profit() {
    const profitMap: { [key: number]: Profit } = {};
    this.assets.forEach((asset) => {
      asset.profits.forEach((profit) => upsertProfitMap(profit, profitMap));
    });
    return assetProfitFromMap(profitMap);
  }

  total() {
    let res = 0;
    for (const a of this.assets.values()) {
      const profits = a.profits;
      if (profits.length < 2) continue;
      res += profits[profits.length - 2].profit;
    }
    return res;
  }

  sort() {
    this.assets.forEach((assetProfit) => assetProfit.sort());
  }

  clone(patch?: Partial<EarnProfit>): EarnProfit {
    const newProfit = new EarnProfit({});
    this.assets.forEach((value, key) => {
      newProfit.assets.set(key, value.clone());
    });
    return newProfit;
  }

  req(): EarnProfit {
    return this;
  }
}

export class EarnProfits extends Entity<EarnProfits> {
  earns: Map<string, EarnProfit>;

  constructor(profits: { [key: string]: { [key: string]: Profit[] } } = {}) {
    super();
    this.earns = new Map(
      Object.entries(profits).map(([key, value]) => [
        key,
        EarnProfit.from(value),
      ])
    );
  }

  profit() {
    const profitMap: { [key: number]: Profit } = {};
    this.earns.forEach((earn) => {
      earn
        .profit()
        .profits.forEach((profit) => upsertProfitMap(profit, profitMap));
    });
    return assetProfitFromMap(profitMap);
  }

  total(): number {
    let res = 0;
    for (const e of this.earns.values()) {
      res += e.total();
    }
    return res;
  }

  sort() {
    this.earns.forEach((p) => p.sort());
  }

  clone(patch?: Partial<EarnProfits>): EarnProfits {
    const newProfits = new EarnProfits({});
    this.earns.forEach((value, key) => {
      newProfits.earns.set(key, value.clone());
    });
    return newProfits;
  }

  req(): EarnProfits {
    return this;
  }
}

export class TotalProfit extends Entity<TotalProfit> {
  total: AssetProfit;
  wallets: WalletProfits;
  earns: EarnProfits;

  constructor(obj: any) {
    super();
    this.total = AssetProfit.from(obj?.total ?? []);
    this.wallets = WalletProfits.from(obj?.wallets ?? {});
    this.earns = EarnProfits.from(obj?.earns ?? {});
  }

  current(): Profit | null {
    if (this.total?.profits?.length < 2) return null;
    return this.total?.profits?.[this.total?.profits.length - 2] || null;
  }

  totalWallets(): number {
    return this.wallets.total();
  }

  totalEarns(): number {
    return this.earns.total();
  }

  clone(patch?: Partial<TotalProfit>): TotalProfit {
    return new TotalProfit({ ...patch });
  }

  req(): TotalProfit {
    return this;
  }
}

export class DeFiTrackerProfit extends Entity<DeFiTrackerProfit> {
  total: Profit[];
  accounts: AccountsProfit;

  constructor(obj: any) {
    super();
    this.total = (obj.total || []).map((profit: any) => Profit.from(profit));
    this.accounts = AccountsProfit.from(obj.accounts || {});
  }

  clone(patch?: Partial<DeFiTrackerProfit>): DeFiTrackerProfit {
    return new DeFiTrackerProfit({ ...this, ...patch });
  }

  req(): DeFiTrackerProfit {
    return this;
  }
}

export const profitsFromQuery = (
  tp: TotalProfit,
  q: BalanceQuery
): { final: Profit; data: AssetProfit } => {
  let profits: Profit[] = [];

  if (q.holding === HoldingType.ALL) {
    profits = tp.total.profits ?? [];
  }

  if (q.holding === HoldingType.WALLETS) {
    if (q.walletId) {
      if (q.accountId) {
        if (q.assetId) {
          profits =
            tp.wallets.wallets
              .get(q.walletId)
              ?.accounts.get(q.accountId)
              ?.assets.get(q.assetId)?.profits ?? [];
        }

        profits =
          tp.wallets.wallets
            .get(q.walletId)
            ?.accounts.get(q.accountId)
            ?.profit()?.profits ?? [];
      }

      profits = tp.wallets.wallets.get(q.walletId)?.profit()?.profits ?? [];
    }

    profits = tp.wallets.profit().profits ?? [];
  }

  if (q.holding === HoldingType.EARNS) {
    if (q.earnId) {
      if (q.assetId) {
        profits =
          tp.earns.earns.get(q.earnId)?.assets.get(q.assetId)?.profits ?? [];
      }

      profits = tp.earns.earns.get(q.earnId)?.profit()?.profits ?? [];
    }

    profits = tp.earns.profit()?.profits ?? [];
  }

  const data = AssetProfit.from(profits);
  const final =
    profits.length > 0 ? profits[profits.length - 1] : Profit.from({});

  return { final, data };
};

export const upsertProfitMap = (
  profit: Profit,
  profitMap: { [key: number]: Profit }
) => {
  const date = profit.time;
  if (profitMap[date]) {
    profitMap[date].startValue += profit.startValue;
    profitMap[date].endValue += profit.endValue;
    profitMap[date].dailyProfit += profit.dailyProfit;
    profitMap[date].profit += profit.profit;
  } else {
    profitMap[date] = Profit.from({
      time: profit.time,
      assetId: profit.assetId,
      accountId: profit.accountId,
      startAmount: profit.startAmount,
      startPrice: profit.startPrice,
      startValue: profit.startValue,
      endAmount: profit.endAmount,
      endPrice: profit.endPrice,
      endValue: profit.endValue,
      dailyProfit: profit.dailyProfit,
      profit: profit.profit,
    });
  }
};

export const assetProfitFromMap = (profitMap: { [key: number]: Profit }) => {
  const total = Object.values(profitMap);
  const res = AssetProfit.from(total);
  res.sort();
  return res;
};
