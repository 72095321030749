export function deleteObjectByKey(array: any[], key: string, value: any) {
  const index = array.findIndex((obj) => obj[key] === value);

  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}

export function deletePrimitiveByValue(array: any, value: any) {
  const index = array.indexOf(value);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}
