import React from 'react';

import { withProps } from '../../entity/components';
import { Card } from './Card';
import { FearGreedIndexGauge } from '../charts/FearGreedIndexGauge';
import { Text } from '../display/Text';
import { GaugeProps } from '../charts/Gauge';
import { FlexBox } from '../containers/FlexBox';
import { Tooltip } from '../display/Tooltip';
import { QuestionCircleIcon } from '../icons';
import { Skeleton } from '../display/Skeleton';

const fearGreedDesc =
  'When the value is closer to 0, the market is in Extreme Fear, and investors have over-sold irrationally. When the value is closer to 100, the market is in Extreme Greed, indicating a likely market correction.\n' +
  '\n' +
  'CoinMarketCap uses the price and trading data of the most popular crypto coins, together with our unique user behaviour data to present a more accurate crypto market sentiment.';

export type FearGreedCardProps = Omit<
  GaugeProps,
  'segments' | 'colors' | 'labels'
>;

export const FearGreedCard: React.FC<FearGreedCardProps> = ({
  value,
  ...props
}) => {
  const sx = {
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    ...props.sx,
  };
  const chartHeight = (props.h ?? 300) - 50;

  return (
    <Card column flex {...withProps({ ...props, sx })} fullWidth>
      <FlexBox mb={1} alignItems="center">
        <Text variant="subtitle2" bold mr={1}>
          Fear & Greed Index
        </Text>
        <Tooltip title={fearGreedDesc}>
          <QuestionCircleIcon imgSize="xs" containerSize={16} color="info" />
        </Tooltip>
      </FlexBox>
      <Skeleton delay={300} wait={!!value} h={chartHeight}>
        <FearGreedIndexGauge
          {...props}
          h={chartHeight}
          hideLabels
          value={value}
        />
      </Skeleton>
    </Card>
  );
};
