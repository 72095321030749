import { OrderList, TradeList } from '../entity';
import { get } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api`;

export const listOrders = (query: string) =>
  get<OrderList>(`${apiUrl}/orders?${query}`, OrderList);
export const listTrades = (query: string) =>
  get<TradeList>(`${apiUrl}/trades?${query}`, TradeList);
