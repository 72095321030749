import { Activity } from '../entity';
import { axiosInstance, del, list, post, put } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/activities`;

export const storeActivity = (data: Activity) => post<Activity>(apiUrl, data);
export const updateActivity = (id: string, data: Activity) =>
  put<Activity>(`${apiUrl}/${id}`, data);
export const completeActivity = (
  id: string,
  usdSpent: number,
  usdGained: number
) => axiosInstance.patch(`${apiUrl}/${id}/complete`, { usdSpent, usdGained });
export const unarchiveActivity = (id: string) =>
  axiosInstance.patch(`${apiUrl}/${id}/unarchive`);
export const listActivities = (isNew?: boolean) =>
  list<Activity>(`${apiUrl}?new=${!!isNew}`, Activity);
export const deleteActivity = (id: string) => del(`${apiUrl}/${id}`);
