import React from 'react';

import {
  amountWithSuffix,
  normalizeValue,
  numberWithCommas,
} from '../../../utils/amount';
import { BaseCellProps, CellText } from './Base';

export type AmountCellProps = BaseCellProps & {
  prefix?: string | null;
  suffix?: string | null;
};

export const AmountCell = (props: AmountCellProps) => {
  const { value, suffix, prefix, style = {} } = props;

  let amountStr: string;
  if (suffix) {
    const amount = normalizeValue(+value ?? 0, 4, 2);
    amountStr = Math.abs(+amount) > 0.001 ? numberWithCommas(amount) : `<0.001`;
  } else {
    amountStr = amountWithSuffix(+value ?? 0, prefix ?? '');
  }

  return (
    <CellText sx={style}>
      {!!suffix && prefix ? ` ${prefix}` : ''}
      {amountStr}
      {suffix ? ` ${suffix}` : ''}
    </CellText>
  );
};
