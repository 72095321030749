import React from 'react';

import { BaseCellProps } from './Base';
import { Text } from '../../display/Text';

export type IconProps = BaseCellProps & {
  colorFieldName: string;
};

export const StatusCell = ({
  data,
  value,
  colorFieldName,
  style = {},
}: IconProps) => {
  return (
    <Text variant="cell" sx={{ ...style, color: data[colorFieldName] }}>
      {value}
    </Text>
  );
};
