import React from 'react';
import MUITooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

export type TooltipProps = {
  title: string;
  children: any;
};

export const Tooltip = ({ title, children }: TooltipProps) => {
  return (
    <MUITooltip enterDelay={800} TransitionComponent={Fade} title={title} arrow>
      <div style={{ display: 'inline-block' }}>{children}</div>
    </MUITooltip>
  );
};
