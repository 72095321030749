import { Entity } from './entity';

export class RWA extends Entity<RWA> {
  rank: number = 0;
  name: string = '';
  symbol: string = '';
  price: number = 0;
  change24h: number = 0;
  change7d: number = 0;
  change30d: number = 0;
  market_cap: number = 0;
  volume: number = 0;
  ath: number = 0;
  pct_ath: number = 0;
  icon_url: string = '';
  url: string = '';

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      (this as any)[key] = obj[key];
    });
  }

  clone(patch?: Partial<RWA>): RWA {
    return new RWA({ ...this, ...patch });
  }

  req(): RWA {
    return this.clone({});
  }
}

export class RWAList extends Entity<RWAList> {
  data: RWA[] = [];
  total = 0;

  constructor(obj: any) {
    super();
    this.data = obj.data?.map((f: any) => RWA.from(f)) ?? [];
    this.total = obj.total;
  }

  clone(patch?: Partial<RWAList>): RWAList {
    return new RWAList({ ...this, ...patch });
  }

  req(): RWAList {
    const data = this.data.map((f) => RWA.from(f)) ?? [];
    return { ...this, data };
  }
}
