import React, { useEffect, useMemo, useState } from 'react';

import { useData } from '../../../contexts/DataContext';
import { DeletePromptDialog } from '../../../components/modals/DeletePromptDialog';
import { Asset, ChainInfo, Price } from '../../../entity';
import {
  deleteAsset,
  listAssets,
  markAssetAsScam,
  storeAsset,
  updateAsset,
  updateAssetName,
  updateAssetPriceId,
} from '../../../services/assets';
import { AssetForm } from '../../../components/forms/AssetForm';
import AssetGrid from '../../../components/containers/AssetGrid';
import { AssetPriceIDForm } from '../../../components/forms/AssetPriceIDForm';
import { usePage } from '../../../contexts/PageContext';
import { FlexBox } from '../../../components/containers/FlexBox';
import { Text } from '../../../components/display/Text';
import { Switch } from '../../../components/controls/Switch';
import { useQuery } from '../../../utils/query';
import { hideNewToken, listNewTokens } from '../../../services';

export const noCoinGeckoMark = '(No CoinGecko)';

type AssetsProps = {
  openAdd: boolean;
  search: string;
  setOpenAdd: (open: boolean) => void;
};

export const Assets = ({ openAdd, search, setOpenAdd }: AssetsProps) => {
  const { showToast, isMobile, navigate } = usePage();
  const query = useQuery();
  const { assets: data, setAssets: setData, prices } = useData();

  const [openDelModal, setOpenDelModal] = useState(false);
  const [openScamModal, setOpenScamModal] = useState(false);
  const [openPriceIdModal, setOpenPriceIdModal] = useState(false);
  const [delData, setDelData] = useState<Asset | null>(null);
  const [scamData, setScamData] = useState<Asset | null>(null);
  const [newTokenId, setNewTokenId] = useState<string | null>(null);
  const [selected, setSelected] = useState<Asset>(
    Asset.from({
      createdAt: Date.now(),
      updatedAt: Date.now(),
    })
  );
  const [selectedForPriceId, setSelectedForPriceId] = useState<Asset>(
    Asset.from({
      createdAt: Date.now(),
      updatedAt: Date.now(),
    })
  );
  const [showWithoutPriceId, setShowWithoutPriceId] = useState(false);

  const addFromNewToken = async (id: string) => {
    const newTokens = await listNewTokens();
    const newToken = newTokens.find((nt) => nt.id === id)!;

    const allAssets = await listAssets();
    const existing = allAssets.find((a) => a.symbol === newToken.symbol);
    if (existing) {
      showToast(`Asset ${newToken.symbol} already exists`, 'warning');
      setOpenAdd(false);
      return;
    }

    setSelected(Asset.from({ ...selected, ...newToken }));
    setNewTokenId(id);
  };

  useEffect(() => {
    if (query.get('new') !== 'true') {
      return;
    }

    const newQuery = new URLSearchParams(query);
    newQuery.delete('new');

    const id = query.get('new-token-id');
    if (id) {
      newQuery.delete('new-token-id');
    }
    navigate({
      pathname: location.pathname,
      search: newQuery.toString(),
    });

    if (!id) return;
    addFromNewToken(id).catch((err: Error) =>
      console.error(`failed to add from new token: ${err.message}`)
    );
  }, []);

  const filteredAssets = useMemo(
    () =>
      data
        .filter(
          (asset) =>
            asset.name.toLowerCase().includes(search.toLowerCase()) ||
            asset.symbol.toLowerCase().includes(search.toLowerCase())
        )
        .filter((asset) => {
          if (showWithoutPriceId) {
            return !asset.priceId && !asset.name.includes(noCoinGeckoMark);
          }
          return true;
        }),
    [search, data, showWithoutPriceId]
  );

  const validateForm = (): boolean => {
    const assetFields = ['name', 'symbol', 'url', 'iconUrl'];
    for (const assetField of assetFields) {
      if (!selected[assetField as keyof Asset]) {
        showToast(`Please fill in the ${assetField} field`, 'warning');
        return false;
      }
    }
    for (const ci of selected.chainInfo || []) {
      const ciFields = ['chain', 'address', 'type'];
      for (const ciField of ciFields) {
        if (!ci[ciField as keyof ChainInfo]) {
          showToast(
            `Please fill in the ${ciField} field for all chain infos`,
            'warning'
          );
          return false;
        }
      }
    }
    return true;
  };

  const handleSave = () => {
    if (!validateForm()) return;

    if (selected.id) {
      updateAssetName(selected.id, selected.name).catch((err: any) =>
        showToast(`Failed to update asset name: ${err.message}`, 'error')
      );
      updateAsset(selected.id, selected)
        .then(() => {
          setData(data.map((w) => (w.id === selected.id ? selected : w)));
          showToast(`Asset ${selected.symbol} successfully updated`, 'info');
        })
        .catch((err: any) =>
          showToast(`Failed to update asset: ${err.message}`, 'error')
        )
        .finally(() => handleClose());
      return;
    }

    storeAsset(selected)
      .then(() => {
        setData([selected, ...data]);
        showToast(`Asset ${selected.symbol} successfully created`, 'info');

        if (newTokenId) {
          hideNewToken(newTokenId).catch((err: any) =>
            console.error(`failed to hide new token: ${err.message}`)
          );
        }
      })
      .catch((err: any) =>
        showToast(`Failed to add asset: ${err.message}`, 'error')
      )
      .finally(() => handleClose());
  };

  const handleSaveFromPrice = () => {
    if (!selectedForPriceId.priceId) {
      return;
    }
    updateAssetPriceId(selectedForPriceId.id, selectedForPriceId.priceId).catch(
      (err: any) =>
        showToast(`Failed to update asset from price: ${err.message}`, 'error')
    );
    updateAsset(selectedForPriceId.id, selectedForPriceId)
      .then(() => {
        setData(
          data.map((w) =>
            w.id === selectedForPriceId.id ? selectedForPriceId : w
          )
        );
        showToast(
          `Asset ${selectedForPriceId.symbol} successfully updated`,
          'info'
        );
      })
      .catch((err: any) =>
        showToast(`Failed to update asset from price: ${err.message}`, 'error')
      )
      .finally(() => handleClosePriceIdModal());
  };

  const handleMarkAsNoPriceId = () => {
    selectedForPriceId.name = `${selectedForPriceId.name} ${noCoinGeckoMark}`;

    updateAssetName(selectedForPriceId.id, selectedForPriceId.name)
      .then(() => {
        setData(
          data.map((w) =>
            w.id === selectedForPriceId.id ? selectedForPriceId : w
          )
        );
        showToast(
          `Asset ${selectedForPriceId.symbol} successfully updated`,
          'info'
        );
      })
      .catch((err: any) =>
        showToast(`Failed to mark as no price id: ${err.message}`, 'error')
      )
      .finally(() => handleClosePriceIdModal());
  };

  const handleEdit = (asset: Asset) => {
    setSelected(asset);
    setOpenAdd(true);
  };

  const handleDel = () => {
    setOpenDelModal(false);

    deleteAsset(delData!.id)
      .then(() => {
        setData(data.filter((a) => a.id !== delData!.id));
        showToast(`Asset ${delData!.symbol} successfully deleted`, 'info');
      })
      .catch((err: any) =>
        showToast(`Failed to delete asset: ${err.message}`, 'error')
      )
      .finally(() => setDelData(null));
  };

  const handleMarkAsScam = () => {
    setOpenScamModal(false);

    markAssetAsScam(scamData!.id)
      .then(() => {
        setData(data.filter((a) => a.id !== scamData!.id));
        showToast(`Asset ${scamData!.symbol} marked as scam`, 'warning');
      })
      .catch((err: any) =>
        showToast(`Failed to mark asset as scam: ${err.message}`, 'error')
      )
      .finally(() => setScamData(null));
  };

  const handleClose = () => {
    setOpenAdd(false);
    setNewTokenId(null);
    setSelected(Asset.from({ createdAt: Date.now(), updatedAt: Date.now() }));
  };

  const handleOpenDelModal = (asset: Asset) => {
    setDelData(asset);
    setOpenDelModal(true);
  };

  const handleOpenScamModal = (asset: Asset) => {
    setScamData(asset);
    setOpenScamModal(true);
  };

  const handleOpenPriceIdModal = (asset: Asset) => {
    setSelectedForPriceId(asset);
    setOpenPriceIdModal(true);
  };

  const handleCloseDelModal = () => {
    setDelData(null);
    setOpenDelModal(false);
  };

  const handleCloseScamModal = () => {
    setScamData(null);
    setOpenScamModal(false);
  };

  const handleClosePriceIdModal = () => {
    setSelectedForPriceId(
      Asset.from({ createdAt: Date.now(), updatedAt: Date.now() })
    );
    setOpenPriceIdModal(false);
  };

  const handleUpdateFromPrice = (price: Price | { id: string }) => {
    const newSelected = selectedForPriceId.clone();

    const updates = price as any;
    if (updates.iconUrl) {
      newSelected.name = updates.name ?? newSelected.name;
      newSelected.iconUrl = updates.iconUrl;
    }

    newSelected.url = `https://www.coingecko.com/en/coins/${price.id}`;
    newSelected.priceId = price.id;
    setSelectedForPriceId(newSelected);
  };

  // ChainInfo Handlers
  const handleInfoChange = (
    ciIdx: number,
    field: string,
    value: string | number
  ) => {
    const newInfo = [...(selected.chainInfo || [])];
    newInfo[ciIdx] = newInfo[ciIdx].clone({ [field]: value });
    setSelected(selected.clone({ chainInfo: newInfo }));
  };

  const handleAddInfo = () => {
    const newInfo = [...(selected.chainInfo || []), ChainInfo.from({})];
    setSelected(selected.clone({ chainInfo: newInfo }));
  };

  const handleDeleteInfo = (idx: number) => {
    const newInfo = selected.chainInfo!.filter((_, index) => index !== idx);
    setSelected(selected.clone({ chainInfo: newInfo }));
  };

  const selectedPrices = useMemo(
    () =>
      prices.filter(
        (p) =>
          selectedForPriceId.symbol.toUpperCase() === p.symbol.toUpperCase()
      ),
    [prices, selectedForPriceId]
  );

  return (
    <FlexBox column h="100vh">
      <FlexBox
        alignItems="center"
        justifyContent="space-between"
        mb={isMobile ? 1 : 6}
      >
        <Text variant="subtitle1">Count {filteredAssets.length}</Text>
        <Switch
          checked={showWithoutPriceId}
          color="accent.dark"
          label="Show only assets without Price ID"
          onChange={(e) => setShowWithoutPriceId(e.target.checked)}
        />
      </FlexBox>

      <AssetGrid
        assets={filteredAssets}
        handleEdit={handleEdit}
        handleEditFromPrice={handleOpenPriceIdModal}
        handleOpenDelModal={handleOpenDelModal}
        handleOpenScamModal={handleOpenScamModal}
      />

      <AssetForm
        data={selected}
        open={openAdd}
        setData={setSelected}
        onInfoChange={handleInfoChange}
        onAddInfo={handleAddInfo}
        onDeleteInfo={handleDeleteInfo}
        onClose={handleClose}
        onSave={handleSave}
      />
      <AssetPriceIDForm
        prices={selectedPrices}
        open={openPriceIdModal}
        updateFromPrice={(price) => handleUpdateFromPrice(price)}
        markAsNoPriceId={handleMarkAsNoPriceId}
        onClose={handleClosePriceIdModal}
        onSave={handleSaveFromPrice}
      />
      <DeletePromptDialog
        open={openDelModal}
        entityName={delData?.symbol ?? ''}
        onClose={handleCloseDelModal}
        onDelete={handleDel}
      />
      <DeletePromptDialog
        open={openScamModal}
        title="Confirm Marking as Scam"
        description={`Are you sure you want to mark ${scamData?.symbol ?? ''} as Scam?`}
        deleteButton="Scam"
        entityName={scamData?.symbol ?? ''}
        onClose={handleCloseScamModal}
        onDelete={handleMarkAsScam}
      />
    </FlexBox>
  );
};
