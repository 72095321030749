import React, { createContext, ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

import { useToast } from './ToastContext';
import { useLoader } from './LoaderContext';
import { useLayout } from './LayoutContext';

type PageContextType = {
  navigate: ReturnType<typeof useNavigate>;
  showToast: ReturnType<typeof useToast>['showToast'];
  theme: ReturnType<typeof useTheme>;
  showLoader: () => void;
  hideLoader: () => void;
  isMobile?: boolean;
  isTablet?: boolean;
  isMacbookAir?: boolean;
  isMacbook16?: boolean;
  isWidescreen?: boolean;
};

const PageContext = createContext<PageContextType | undefined>(undefined);

const PageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { showLoader, hideLoader } = useLoader();
  const theme = useTheme();
  const { isMobile, isTablet, isMacbookAir, isMacbook16, isWidescreen } =
    useLayout();

  return (
    <PageContext.Provider
      value={{
        navigate,
        showToast,
        theme,
        showLoader,
        hideLoader,
        isMobile,
        isTablet,
        isMacbookAir,
        isMacbook16,
        isWidescreen,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

const usePage = (): PageContextType => {
  const context = useContext(PageContext);
  if (context === undefined) {
    throw new Error('usePage must be used within a PageProvider');
  }
  return context;
};

export { PageProvider, usePage };
