import { CEX, Wallets } from '../entity';
import {
  AllbridgeIcon,
  BinanceIcon,
  CoinbaseIcon,
  HeliusIcon,
  LedgerIcon,
  MetamaskIcon,
  OKXIcon,
  PancakeSwapIcon,
  PhantomIcon,
  QuestionCircleIcon,
  RabbyIcon,
  SolflareIcon,
  TronLinkIcon,
  TrustWalletIcon,
} from '../components/icons';

export const walletIcon = (platform: string) => {
  platform = platform.toLowerCase();
  switch (platform) {
    case CEX.BINANCE.toLowerCase():
      return BinanceIcon;
    case CEX.OKX.toLowerCase():
      return OKXIcon;
    case Wallets.LEDGER.toLowerCase():
      return LedgerIcon;
    case Wallets.METAMASK.toLowerCase():
      return MetamaskIcon;
    case Wallets.PHANTOM.toLowerCase():
      return PhantomIcon;
    case Wallets.SOLFLARE.toLowerCase():
      return SolflareIcon;
    case Wallets.RABBY.toLowerCase():
      return RabbyIcon;
    case Wallets.TRONLINK.toLowerCase():
      return TronLinkIcon;
    case Wallets.COINBASE.toLowerCase():
      return CoinbaseIcon;
    case Wallets.TRUST.toLowerCase():
      return TrustWalletIcon;
  }
  return QuestionCircleIcon;
};

export const earnIcon = (platform: string) => {
  platform = platform.toLowerCase();
  switch (platform) {
    case CEX.BINANCE.toLowerCase():
      return BinanceIcon;
    case CEX.OKX.toLowerCase():
      return OKXIcon;
    case Wallets.SOLFLARE.toLowerCase():
      return SolflareIcon;
    case 'pancakeswap':
    case 'pancake swap':
    case 'pancake':
      return PancakeSwapIcon;
    case 'allbridge':
      return AllbridgeIcon;
    case 'helius':
      return HeliusIcon;
    case Wallets.PHANTOM.toLowerCase():
      return PhantomIcon;
  }
  return QuestionCircleIcon;
};
