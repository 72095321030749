import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { ContainerProvider } from './contexts/ContainerContext';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log(
          'Service Worker registered with scope:',
          registration.scope
        );
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <ContainerProvider>
    <App />
  </ContainerProvider>
);
