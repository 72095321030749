import React, { useMemo } from 'react';

import { Alert, AlertType, Price } from '../../entity';
import { timestampToIso8601 } from '../../utils/date';
import { AutocompletePrice } from '../controls/autocomplete/AutocompletePrice';
import { Select } from '../controls/Select';
import { Input } from '../controls/Input';
import { Button } from '../controls/Button';
import { Modal } from '../modals/Modal';
import { useLayout } from '../../contexts/LayoutContext';

type AlertFormProps = {
  open: boolean;
  data: Alert | null;
  min: string;
  max: string;
  edit: boolean;
  type: AlertType;
  prices: Price[];
  set: (alert: Alert) => void;
  setMin: (val: string) => void;
  setMax: (val: string) => void;
  setType: (type: AlertType) => void;
  onPriceChange: (newValue: Price | null) => void;
  onClose: () => void;
  onSave: () => void;
};

export const AlertForm: React.FC<AlertFormProps> = (props: AlertFormProps) => {
  const { isMobile } = useLayout();
  const { open, data, edit, type, prices } = props;
  const { set, setType, onPriceChange, onClose, onSave } = props;
  const price = useMemo(
    () => prices.find((p) => p.symbol === data?.symbol) ?? null,
    [prices, data]
  );
  const mb = isMobile ? 1 : 2;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={edit ? 'Edit Alert' : 'Add New Alert'}
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSave} variant="contained">
            {edit ? 'Save' : 'Create'}
          </Button>
        </>
      }
    >
      <Select
        value={type}
        required
        label="Alert Type"
        mb={isMobile ? '4px' : mb}
        mt={0}
        fullWidth
        options={[
          { value: 'price', label: 'Price' },
          { value: 'time', label: 'Time' },
        ]}
        onChange={(e) => setType(e.target.value as AlertType)}
      />
      <Input
        autoFocus
        required
        label="Name"
        fullWidth
        value={data?.name || ''}
        mb={isMobile ? 0 : mb}
        mt={0}
        onChange={(e) => set(Alert.from({ ...data!, name: e.target.value }))}
      />
      {type === AlertType.PRICE && (
        <>
          <AutocompletePrice
            size="small"
            mb={isMobile ? '4px' : mb}
            items={prices}
            data={price}
            showPrice
            onChange={onPriceChange as any}
          />
          <Input
            required
            label="Threshold Min"
            fullWidth
            mb={isMobile ? 0 : mb}
            mt={0}
            value={props.min}
            onChange={(e) => props.setMin(e.target.value)}
          />
          <Input
            required
            label="Threshold Max"
            fullWidth
            value={props.max}
            onChange={(e) => props.setMax(e.target.value)}
          />
        </>
      )}
      {type === AlertType.TIME && (
        <>
          <Input
            required
            type="datetime-local"
            fullWidth
            mb={mb}
            mt={0}
            value={data?.time ? timestampToIso8601(data.time) : ''}
            onChange={(e) =>
              set(Alert.from({ ...data!, time: Date.parse(e.target.value) }))
            }
          />
          <Input
            required
            label="Description"
            fullWidth
            mb={mb}
            mt={0}
            value={data?.description || ''}
            onChange={(e) =>
              set({ ...data, description: e.target.value } as any)
            }
          />
        </>
      )}
    </Modal>
  );
};
