import React from 'react';

import BitfinexLogo from '../../assets/logo/bitfinex.png';
import CoinbaseLogo from '../../assets/logo/coinbase.png';
import KrakenLogo from '../../assets/logo/kraken.png';
import MEXCLogo from '../../assets/logo/mexc.png';
import OKXLogo from '../../assets/logo/okx.png';
import { ImgProps } from '../display/Img';
import { RoundImg } from '../display/RoundImg';
import { Svg, SvgIconProps } from './common';

export const BitfinexIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={BitfinexLogo} />
);

export const ByBitIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg
    {...props}
    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODciIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCA4NyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYyLjAwODMgMjUuMzU3MlYzSDY2LjUwMjJWMjUuMzU3Mkg2Mi4wMDgzWiIgZmlsbD0iI0Y3QTYwMCIvPgo8cGF0aCBkPSJNOS42MzQwNyAzMS45OTgzSDBWOS42NDExMUg5LjI0NjY2QzEzLjc0MDYgOS42NDExMSAxNi4zNTkxIDEyLjA5MDMgMTYuMzU5MSAxNS45MjE0QzE2LjM1OTEgMTguNDAxMyAxNC42Nzc0IDIwLjAwMzkgMTMuNTEzNCAyMC41Mzc1QzE0LjkwMjggMjEuMTY1MiAxNi42ODEzIDIyLjU3NzkgMTYuNjgxMyAyNS41NjI0QzE2LjY4MTMgMjkuNzM3MyAxMy43NDA2IDMxLjk5ODMgOS42MzQwNyAzMS45OTgzWk04Ljg5MDk2IDEzLjUzNTVINC40OTM5VjE4LjY4NTJIOC44OTA5NkMxMC43OTgxIDE4LjY4NTIgMTEuODY1MiAxNy42NDg4IDExLjg2NTIgMTYuMTA5NUMxMS44NjUyIDE0LjU3MTkgMTAuNzk4MSAxMy41MzU1IDguODkwOTYgMTMuNTM1NVpNOS4xODE1MSAyMi42MTA0SDQuNDkzOVYyOC4xMDU2SDkuMTgxNTFDMTEuMjE4OSAyOC4xMDU2IDEyLjE4NzQgMjYuODUwMyAxMi4xODc0IDI1LjM0MThDMTIuMTg3NCAyMy44MzUgMTEuMjE3MSAyMi42MTA0IDkuMTgxNTEgMjIuNjEwNFoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMC4zODgyIDIyLjgyOTNWMzEuOTk4M0gyNS45MjZWMjIuODI5M0wxOS4wMDczIDkuNjQxMTFIMjMuODg4NkwyOC4xODg4IDE4LjY1MjdMMzIuNDIzOSA5LjY0MTExSDM3LjMwNTJMMzAuMzg4MiAyMi44MjkzWiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTUwLjA0NTcgMzEuOTk4M0g0MC40MTE2VjkuNjQxMTFINDkuNjU4M0M1NC4xNTIyIDkuNjQxMTEgNTYuNzcwNyAxMi4wOTAzIDU2Ljc3MDcgMTUuOTIxNEM1Ni43NzA3IDE4LjQwMTMgNTUuMDg5IDIwLjAwMzkgNTMuOTI1IDIwLjUzNzVDNTUuMzE0NCAyMS4xNjUyIDU3LjA5MyAyMi41Nzc5IDU3LjA5MyAyNS41NjI0QzU3LjA5MyAyOS43MzczIDU0LjE1MjIgMzEuOTk4MyA1MC4wNDU3IDMxLjk5ODNaTTQ5LjMwMjYgMTMuNTM1NUg0NC45MDU1VjE4LjY4NTJINDkuMzAyNkM1MS4yMDk3IDE4LjY4NTIgNTIuMjc2OCAxNy42NDg4IDUyLjI3NjggMTYuMTA5NUM1Mi4yNzY4IDE0LjU3MTkgNTEuMjA5NyAxMy41MzU1IDQ5LjMwMjYgMTMuNTM1NVpNNDkuNTkzMSAyMi42MTA0SDQ0LjkwNTVWMjguMTA1Nkg0OS41OTMxQzUxLjYzMDUgMjguMTA1NiA1Mi41OTkgMjYuODUwMyA1Mi41OTkgMjUuMzQxOEM1Mi41OTkgMjMuODM1IDUxLjYzMDUgMjIuNjEwNCA0OS41OTMxIDIyLjYxMDRaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNODAuOTg2IDEzLjUzNTVWMzJINzYuNDkyMVYxMy41MzU1SDcwLjQ3ODVWOS42NDExMUg4Ni45OTk2VjEzLjUzNTVIODAuOTg2WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg=="
  />
);

export const CoinbaseIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={CoinbaseLogo} />
);

export const KrakenIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={KrakenLogo} />
);

export const MEXCIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={MEXCLogo} />
);

export const OKXIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={OKXLogo} />
);

export const BinanceIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 126.61 126.61" {...props}>
    <g fill="#f3ba2f">
      <path d="m38.73 53.2 24.59-24.58 24.6 24.6 14.3-14.31-38.9-38.91-38.9 38.9z" />
      <path d="m0 63.31 14.3-14.31 14.31 14.31-14.31 14.3z" />
      <path d="m38.73 73.41 24.59 24.59 24.6-24.6 14.31 14.29-38.9 38.91-38.91-38.88z" />
      <path d="m98 63.31 14.3-14.31 14.31 14.3-14.31 14.32z" />
      <path d="m77.83 63.3-14.51-14.52-10.73 10.73-1.24 1.23-2.54 2.54 14.51 14.5 14.51-14.47z" />
    </g>
  </Svg>
);

export const HTXIcon: React.FC<SvgIconProps> = (props) => (
  <Svg enableBackground="new 0 0 88 135.9" viewBox="0 0 88 135.9" {...props}>
    <path
      d="m57.8 41.8c0-19.5-9.4-36.3-16.6-41.8 0 0-.5-.3-.5.5-.6 37.7-19.7 48-30.3 61.7-24.3 31.8-1.7 66.6 21.3 73 12.9 3.6-3-6.4-5-27.4-2.4-25.5 31.1-44.9 31.1-66"
      fill="#282e5b"
    />
    <path
      d="m70.4 54.7c-.1-.1-.3-.1-.5 0 0 0 0 0-.1.1-.4 4.7-5.6 14.9-12.1 24.2-22.1 31.6-9.5 46.8-2.4 55 4.1 4.8 0 0 10.3-4.9.8-.4 20-10.6 22.1-33.7 2.1-22.5-12.2-36.6-17.3-40.7"
      fill="#059bdc"
    />
  </Svg>
);

export const WhiteBitIcon: React.FC<SvgIconProps> = (props) => (
  <Svg
    viewBox="0 0 221.5 48.2"
    xmlSpace="preserve"
    x="0px"
    y="0px"
    id="Layer_1"
    {...props}
  >
    <path
      className="st0"
      d="M178.2,19.1l-6-0.6V11h-5.2v2.8c0,5.8,2.3,7.8,5.1,8.7h-5.1v6.9c0,6.7,3.3,9.4,9.7,9.4c6.4,0,9.7-2.7,9.7-9.4
	v-0.9C186.5,20.5,182.2,19.5,178.2,19.1z M181.3,34.1h-9.1V22.6c1,0.3,2.1,0.5,3.1,0.6l6,0.9V34.1z M193.6,11
	c-1.6,0-2.7,1.1-2.7,2.7c0,1.6,1.1,2.7,2.7,2.7c1.6,0,2.7-1.1,2.7-2.7C196.4,12.1,195.3,11,193.6,11z M206.1,19v-4.7H201
	c0,6.7,3.3,9.4,9.7,9.4h2.1V19H206.1z M206.1,23.1H201v6.3c0,6.7,3.3,9.4,9.7,9.4h2.1v-4.7h-6.7V23.1z M191.1,38.8h5.2V19h-5.2V38.8
	z"
    />
    <path
      className="st1"
      d="M104.6,19.1l-6-0.6V11h-5.2v2.8c0,5.8,2.3,7.8,5.1,8.7h-5.1v16.3h5.2V22.6c1,0.3,2.1,0.5,3.1,0.6l6,0.9v14.7
	h5.2V28.5C112.9,20.5,108.6,19.5,104.6,19.1z M83,34.1h-6.2V19h-5.2v10.4c0,6.7,3.3,9.4,9.7,9.4h6.8V19H83V34.1z M65.6,19h-5.2v10.4
	c0,6.7,3.3,9.4,9.7,9.4h2.1v-4.7h-6.7V19z M153.2,19h-9.7v2.5c0,6.7,3.3,9.4,9.7,9.4h9.7v-2.5C162.9,21.6,159.6,19,153.2,19z
	 M157.8,26.6h-4.6v0h-4.6v-3.2h9.1V26.6z M120.5,11c-1.6,0-2.7,1.1-2.7,2.7c0,1.6,1.1,2.7,2.7,2.7c1.6,0,2.7-1.1,2.7-2.7
	C123.2,12.1,122.1,11,120.5,11z M117.9,38.8h5.2V19h-5.2V38.8z M133,23.1h-5.2v6.3c0,6.7,3.3,9.4,9.7,9.4h2.1v-4.7H133V23.1z
	 M133,14.3h-5.2c0,6.7,3.3,9.4,9.7,9.4h2.1V19H133V14.3z M148.6,30.2h-5.2c0,6,3.3,8.6,9.7,8.6h8.7v-4.7h-13.3V30.2z M42.9,5.1
	c-4.8,0-8.7,3.9-8.7,8.7l-10.5,0l8.7-8.7l-10.6,0c-4.8,0-8.7,3.9-8.7,8.7v0h-0.1c-4.8,0-8.7,3.9-8.7,8.7h8.8V29
	c0,8.7,7.1,15.7,15.7,15.7h16l0-31l8.7-8.7L42.9,5.1z M33.8,28.1c-1.3-1.3-1.3-3.4,0-4.7l4.7,4.7C37.2,29.4,35.1,29.4,33.8,28.1z"
    />
  </Svg>
);

export const KuCoinIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 1024 1024" {...props}>
    <circle cx="512" cy="512" r="512" style={{ fill: '#23af91' }} />
    <path
      d="m426 512.1 151.6 151.7 95.7-95.7c17.1-15.4 43.3-14.7 59.6 1.6s17 42.5 1.6 59.6L608.2 755.5c-17 16.7-44.2 16.7-61.1 0L364.9 573.2v108.3c0 23.9-19.4 43.3-43.3 43.3s-43.3-19.4-43.3-43.3V342.4c0-23.9 19.4-43.3 43.3-43.3s43.3 19.4 43.3 43.3v108.3l182.2-182.2c17-16.7 44.2-16.7 61.1 0l126.4 126.3c15.4 17.1 14.7 43.3-1.6 59.6-16.3 16.3-42.5 17-59.6 1.6l-95.7-95.7L426 512.1zm151.8-43.4c-17.5 0-33.3 10.5-40.1 26.7-6.7 16.2-3 34.8 9.4 47.2s31 16.1 47.2 9.4c16.2-6.7 26.8-22.5 26.8-40 0-11.5-4.6-22.5-12.7-30.7-8.1-8-19.1-12.6-30.6-12.6z"
      style={{ fill: '#fff' }}
    />
  </Svg>
);
