import {
  BalanceQuery,
  FinalBalance,
  generateBalanceQueryString,
  getBalanceQueryKey,
  HoldingType,
  TimeRangeType,
  UnitBalance,
} from '../entity';
import { get } from './request';
import { cfg } from '../config/config';

const apiUrl = `${cfg.apiUrl}/api/balances`;

const cache: { [key: string]: UnitBalance } = {};

export const getAssetBalances = (
  timeRange: TimeRangeType,
  accountId: string,
  assetId: string
) => {
  const query = { timeRange, accountId, assetId };
  const key = getBalanceQueryKey(query);
  const url = apiUrl + generateBalanceQueryString(query);
  return sendReq(key, url);
};
export const getAccountBalances = (
  timeRange: TimeRangeType,
  accountId: string
) => {
  const query = { timeRange, accountId };
  const key = getBalanceQueryKey(query);
  const url = apiUrl + generateBalanceQueryString(query);
  return sendReq(key, url);
};
export const getWalletBalances = (
  timeRange: TimeRangeType,
  walletId: string
) => {
  const query = { timeRange, walletId, holding: HoldingType.WALLETS };
  const key = getBalanceQueryKey(query);
  const url = apiUrl + generateBalanceQueryString(query);
  return sendReq(key, url);
};
export const getEarnBalances = (timeRange: TimeRangeType, earnId: string) => {
  const query = { timeRange, earnId, holding: HoldingType.EARNS };
  const key = getBalanceQueryKey(query);
  const url = apiUrl + generateBalanceQueryString(query);
  return sendReq(key, url);
};
export const getAllWalletBalances = (timeRange: TimeRangeType) => {
  const query = { timeRange, holding: HoldingType.WALLETS };
  const key = getBalanceQueryKey(query);
  const url = apiUrl + generateBalanceQueryString(query);
  return sendReq(key, url);
};
export const getAllEarnBalances = (timeRange: TimeRangeType) => {
  const query = { timeRange, holding: HoldingType.EARNS };
  const key = getBalanceQueryKey(query);
  const url = apiUrl + generateBalanceQueryString(query);
  return sendReq(key, url);
};
export const queryBalances = (query: BalanceQuery) => {
  const key = getBalanceQueryKey(query);
  const url = apiUrl + generateBalanceQueryString(query);
  return sendReq(key, url);
};
export const getFinalBalances = () => {
  return get<FinalBalance>(`${apiUrl}/final`, FinalBalance);
};

const sendReq = async (key: string, url: string): Promise<UnitBalance> => {
  let res = cache[key];
  if (res) return res;

  res = await get<UnitBalance>(url, UnitBalance);
  cache[key] = res;
  return res;
};
