import React from 'react';

import { PlusIcon } from '../icons';
import { ElementProps, withProps } from '../../entity/components';
import { Button } from './Button';
import { ColorString } from '../../theme/theme';

export type PlusButtonProps = ElementProps & {
  iconColor?: ColorString;
};

export const PlusButton = (props: PlusButtonProps) => {
  return (
    <Button w={props.w ?? 36} h={props.h ?? 36} {...withProps(props)}>
      <PlusIcon color={props.iconColor ?? 'text.white'} />
    </Button>
  );
};
