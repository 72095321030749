import React from 'react';
import 'ag-charts-enterprise';

import { DonutChart, DonutChartProps } from './DonutChart';

export type ActiveChartProps = Omit<DonutChartProps, 'data'> & {
  active: number;
  total: number;
};

export const ActiveChart: React.FC<ActiveChartProps> = ({
  active,
  total,
  ...props
}) => {
  const data = [
    { name: 'Active', value: active },
    { name: 'Inactive', value: total - active },
  ];
  const valueColor = '#555';
  const fills = ['#f7931a', '#555'];
  return (
    <DonutChart valueColor={valueColor} fills={fills} data={data} {...props} />
  );
};
