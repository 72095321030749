import { normalizeValue, normalizeValueNum } from '../utils/amount';
import {
  DAY_HOURS,
  DAY_MILLIS,
  HOUR_MILLIS,
  MONTH_DAYS,
  timestampToShortDateStr,
  timestampToShortTimeStr,
  WEEK_DAYS,
  YEAR_DAYS,
} from '../utils/date';
import { Profit } from './profit';
import { Balance, UnitBalance } from './balance';
import { cloneDeep } from 'lodash';

export enum TimeRangeType {
  DAY = '24h',
  WEEK = '7d',
  MONTH = '30d',
  THREE_MONTHS = '3m',
  SIX_MONTHS = '6m',
  YEAR = 'year',
}

export enum TimeRangeValue {
  DAY = 86400000,
  WEEK = 604800000,
  MONTH = 2592000000,
  THREE_MONTHS = 7776000000,
  SIX_MONTHS = 15552000000,
  YEAR = 31536000000,
}

export const timeRangeTypeValueMap = {
  [TimeRangeType.DAY]: TimeRangeValue.DAY,
  [TimeRangeType.WEEK]: TimeRangeValue.WEEK,
  [TimeRangeType.MONTH]: TimeRangeValue.MONTH,
  [TimeRangeType.THREE_MONTHS]: TimeRangeValue.THREE_MONTHS,
  [TimeRangeType.SIX_MONTHS]: TimeRangeValue.SIX_MONTHS,
  [TimeRangeType.YEAR]: TimeRangeValue.YEAR,
};

export type TimeRange = {
  label: TimeRangeType;
  value: TimeRangeValue;
};

export const timeRanges: TimeRange[] = [
  { label: TimeRangeType.DAY, value: TimeRangeValue.DAY },
  { label: TimeRangeType.WEEK, value: TimeRangeValue.WEEK },
  { label: TimeRangeType.MONTH, value: TimeRangeValue.MONTH },
  { label: TimeRangeType.THREE_MONTHS, value: TimeRangeValue.THREE_MONTHS },
  { label: TimeRangeType.SIX_MONTHS, value: TimeRangeValue.SIX_MONTHS },
  { label: TimeRangeType.YEAR, value: TimeRangeValue.YEAR },
];

export function formatProfitDataForRange(
  profit: Profit[],
  range: TimeRangeType
): Profit[] {
  return formatDataForRange(
    profit,
    range,
    profit.length,
    (item) => item.time,
    (time) => Profit.from({ time }),
    (existingEntry, newEntry) => {
      existingEntry.startAmount += newEntry.startAmount;
      existingEntry.startValue += newEntry.startValue;
      existingEntry.startPrice += newEntry.startPrice;
      existingEntry.endAmount += newEntry.endAmount;
      existingEntry.endValue += newEntry.endValue;
      existingEntry.endPrice += newEntry.endPrice;
      existingEntry.profit += newEntry.profit;
      existingEntry.dailyProfit += newEntry.dailyProfit;
    },
    getProfitTotalElements,
    getProfitGroupInterval
  );
}

export function formatBalanceDataForRange(
  data: UnitBalance,
  range: TimeRangeType
): UnitBalance {
  const balance = cloneDeep(data);
  if (!balance?.data?.length) return balance;

  const dataLength = getBalanceDataLength(balance, range);

  balance.data = formatDataForRange(
    balance.data,
    range,
    dataLength,
    (item) => item.time,
    (time) => Balance.from({ time }),
    (existingEntry, newEntry) => {
      existingEntry.value += newEntry.value;
      existingEntry.amount += newEntry.amount;
      existingEntry.price += newEntry.price;
    },
    getBalanceTotalElements,
    getBalanceGroupInterval
  );

  return balance;
}

// Generic function to format data for a given range
function formatDataForRange<T>(
  data: T[],
  range: TimeRangeType,
  dataLength: number,
  getTime: (item: T) => number,
  createZeroEntry: (time: number) => T,
  aggregateEntries: (existingEntry: T, newEntry: T) => void,
  getTotalElements: (range: TimeRangeType) => number,
  getGroupInterval: (range: TimeRangeType) => number
): T[] {
  if (!data?.length) return data;

  const firstTime = getTime(data[0]);
  const totalElements = getTotalElements(range);
  const groupInterval = getGroupInterval(range);

  const elementsToFill = totalElements - dataLength;

  // Group data if needed
  const groupedData = groupDataByInterval(
    data,
    groupInterval,
    getTime,
    aggregateEntries
  );

  // Prepare for filling missing intervals with zero-value entries
  const filledData: T[] = [];

  // Fill missing intervals with zero-value entries
  const minTime = firstTime - elementsToFill * groupInterval;
  let currentTime = firstTime - groupInterval;

  while (currentTime > minTime) {
    filledData.unshift(createZeroEntry(currentTime));
    currentTime -= groupInterval;
  }

  // Append grouped data after filled historical data
  filledData.push(
    ...Array.from(groupedData.values()).sort((a, b) => getTime(a) - getTime(b))
  );

  return filledData;
}

// Generic function to group data by interval
function groupDataByInterval<T>(
  data: T[],
  interval: number,
  getTime: (item: T) => number,
  aggregateEntries: (existingEntry: T, newEntry: T) => void
): Map<number, T> {
  const groupedData: Map<number, T> = new Map();

  data.forEach((entry) => {
    const time = getTime(entry);
    const groupStart = Math.floor(time / interval) * interval;

    if (!groupedData.has(groupStart)) {
      groupedData.set(groupStart, entry);
    } else {
      const existingEntry = groupedData.get(groupStart)!;
      aggregateEntries(existingEntry, entry);
    }
  });

  return groupedData;
}

// Helper functions specific to Profit data
function getProfitTotalElements(range: TimeRangeType): number {
  switch (range) {
    case TimeRangeType.YEAR:
      return YEAR_DAYS;
    case TimeRangeType.MONTH:
      return MONTH_DAYS;
    case TimeRangeType.THREE_MONTHS:
      return MONTH_DAYS * 3;
    case TimeRangeType.SIX_MONTHS:
      return MONTH_DAYS * 6;
    case TimeRangeType.WEEK:
      return WEEK_DAYS;
    default:
      return WEEK_DAYS; // Default to WEEK_DAYS for '24h'
  }
}

function getProfitGroupInterval(range: TimeRangeType): number {
  return DAY_MILLIS; // Always use DAY_MILLIS for Profit data
}

function getBalanceDataLength(
  balance: UnitBalance,
  range: TimeRangeType
): number {
  const balanceHours = balance.data.length;
  switch (range) {
    case TimeRangeType.YEAR:
    case TimeRangeType.MONTH:
    case TimeRangeType.THREE_MONTHS:
    case TimeRangeType.SIX_MONTHS:
    case TimeRangeType.WEEK:
      return Math.floor(balanceHours / DAY_HOURS); // Number of days
    default:
      return balanceHours; // Number of hours
  }
}

function getBalanceTotalElements(range: TimeRangeType): number {
  switch (range) {
    case TimeRangeType.YEAR:
      return YEAR_DAYS;
    case TimeRangeType.MONTH:
      return MONTH_DAYS;
    case TimeRangeType.THREE_MONTHS:
      return MONTH_DAYS * 3;
    case TimeRangeType.SIX_MONTHS:
      return MONTH_DAYS * 6;
    case TimeRangeType.WEEK:
      return WEEK_DAYS;
    default:
      return DAY_HOURS; // For '24h', use DAY_HOURS
  }
}

function getBalanceGroupInterval(range: TimeRangeType): number {
  switch (range) {
    case TimeRangeType.YEAR:
    case TimeRangeType.MONTH:
    case TimeRangeType.THREE_MONTHS:
    case TimeRangeType.SIX_MONTHS:
    case TimeRangeType.WEEK:
      return DAY_MILLIS;
    default:
      return HOUR_MILLIS;
  }
}

export const filterProfitsByRange = (range: TimeRange, items: Profit[]) => {
  const currentRange =
    range.label === '24h' ? timeRanges[1].value : range.value;
  const oldestDate = Date.now() - currentRange;
  const filtered = items.filter((item) => item.time > oldestDate);

  if (filtered.length === 0) return [];

  // Initialize the first profit item with dailyProfit
  filtered[0].profit = filtered[0].dailyProfit;

  // Update profit field for each item
  for (let i = 1; i < filtered.length; i++) {
    filtered[i].profit = filtered[i - 1].profit + filtered[i].dailyProfit;
  }

  return filtered
    .sort((a, b) => a.time - b.time)
    .map((p) => ({
      ...p,
      profit: normalizeValueNum(p.profit),
      dailyProfit: normalizeValueNum(p.dailyProfit),
    }));
};

export function getTimeRangeLabelFormatter(
  range: TimeRangeType
): (time: number) => string {
  switch (range) {
    case TimeRangeType.YEAR:
    case TimeRangeType.MONTH:
    case TimeRangeType.THREE_MONTHS:
    case TimeRangeType.SIX_MONTHS:
    case TimeRangeType.WEEK:
      return timestampToShortDateStr;
    case TimeRangeType.DAY:
      return timestampToShortTimeStr;
  }
}

export function valueLabelFormatter(value: number): string {
  return `${normalizeValue(value)}$`;
}
